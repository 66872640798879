// import { Stack } from '@mui/material';
// import React, {useCallback} from 'react'
// import {useDropzone} from 'react-dropzone'

// export const DropZone = () => {
//     const onDrop = useCallback(acceptedFiles => {
//         // Do something with the files
//         console.log(acceptedFiles, 'acceptedFiles');
//     }, [])

//     const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

//     return (
//         <div {...getRootProps()} style={{ width: '300px', minHeight: '50px'}}>
//             <input {...getInputProps()} />
//             <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} style={{ color: 'rgba(133,133,133,1)', border: '2px dashed #bdbdbd', borderRadius: '5px', padding: '10px'}}>
//                 {
//                     isDragActive ?
//                     <p className='text-center' style={{ width: '100%'}}>قم بإفلات الصور هنا ...</p> :
//                     <p className='text-center' style={{ width: '100%'}}>قم بسحب وإفلات بعض الصور هنا، أو انقر لتحديد الصورة</p>
//                 }
//             </Stack>
//         </div>
//     )
// }





// import React, {useEffect, useState} from 'react';
// import {useDropzone} from 'react-dropzone';

// const thumbsContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'wrap',
//   marginTop: 16
// };

// const thumb = {
//   display: 'inline-flex',
//   borderRadius: 2,
//   border: '1px solid #eaeaea',
//   marginBottom: 8,
//   marginRight: 8,
//   width: 100,
//   height: 100,
//   padding: 4,
//   boxSizing: 'border-box'
// };

// const thumbInner = {
//   display: 'flex',
//   minWidth: 0,
//   overflow: 'hidden'
// };

// const img = {
//   display: 'block',
//   width: 'auto',
//   height: '100%'
// };


// export const DropZone = (props) => {
//   const [files, setFiles] = useState([
//     "https://images.pexels.com/photos/45853/grey-crowned-crane-bird-crane-animal-45853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     "https://media.istockphoto.com/id/1419410282/photo/silent-forest-in-spring-with-beautiful-bright-sun-rays.jpg?s=2048x2048&w=is&k=20&c=t9_zg20wVbrBoGn0tw__1fFq4ykeKs15TQQ3x-ehVC0=",
//     "https://media.istockphoto.com/id/1297349747/photo/hot-air-balloons-flying-over-the-botan-canyon-in-turkey.jpg?s=2048x2048&w=is&k=20&c=N0ogUgFEFTpIcQLci1XeUrYbrEOx7m13EMhf13QYrA0=",
//     "https://media.istockphoto.com/id/1347784849/photo/scenic-view-of-a-beautiful-landscape-garden-with-a-green-mowed-lawn.jpg?s=2048x2048&w=is&k=20&c=vPeW-VYR_L-Dfw9DX8n8XN4eYoKoY8UG4LlhGoHfE48="
//     ]);
//   const {getRootProps, getInputProps} = useDropzone({
//     accept: {
//       'image/*': []
//     },
//     onDrop: acceptedFiles => {
//       setFiles(acceptedFiles.map(file => Object.assign(file, {
//         preview: URL.createObjectURL(file)
//       })));
//     },
//   });
  
//   const thumbs = files.map(file => (
//     <div style={thumb} key={file.name}>
//       <div style={thumbInner}>
//         <img
//           src={file.preview}
//           style={img}
//           alt={file.name}
//           // Revoke data uri after image is loaded
//           onLoad={() => { URL.revokeObjectURL(file.preview) }}
//         />
//       </div>
//     </div>
//   ));

//   useEffect(() => {
//     // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
//     return () => files.forEach(file => URL.revokeObjectURL(file.preview));
//   }, [files]);

//   return (
//     <section className="container">
//       <div {...getRootProps({className: 'dropzone'})}>
//         <input {...getInputProps()} />
//         <p>Drag 'n' drop some files here, or click to select files</p>
//       </div>
//       <aside style={thumbsContainer}>
//         {thumbs}
//       </aside>
//     </section>
//   );
// }



import React, { useState, useCallback } from "react";
import { DropzoneArea } from "mui-file-dropzone";
import { changeAlert } from "../store";
import { useDispatch } from 'react-redux';

import { Fab, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const acceptedFiles = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/svg+xml"]

export const AddDropZone = ({sizeInMB, fileslimit, onUploadChange}) => {

    // const [files, setFiles] = useState([
    //     "https://images.pexels.com/photos/45853/grey-crowned-crane-bird-crane-animal-45853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    //     // "https://media.istockphoto.com/id/1419410282/photo/silent-forest-in-spring-with-beautiful-bright-sun-rays.jpg?s=2048x2048&w=is&k=20&c=t9_zg20wVbrBoGn0tw__1fFq4ykeKs15TQQ3x-ehVC0=",
    //     // "https://media.istockphoto.com/id/1297349747/photo/hot-air-balloons-flying-over-the-botan-canyon-in-turkey.jpg?s=2048x2048&w=is&k=20&c=N0ogUgFEFTpIcQLci1XeUrYbrEOx7m13EMhf13QYrA0=",
    //     // "https://media.istockphoto.com/id/1347784849/photo/scenic-view-of-a-beautiful-landscape-garden-with-a-green-mowed-lawn.jpg?s=2048x2048&w=is&k=20&c=vPeW-VYR_L-Dfw9DX8n8XN4eYoKoY8UG4LlhGoHfE48="
    //   ]);

    // const onChange = e => {
    //   console.log(e, 'acceptedFiles');
    // }

    const dispatch = useDispatch()

    return (
        <div className="dropZone-container">
            <DropzoneArea
                acceptedFiles={acceptedFiles}
                maxFileSize={sizeInMB * 1024 * 1024}
                filesLimit={fileslimit}
                // showPreviews={true}
                // showPreviewsInDropzone={false}
                dropzoneText={'قم بسحب وإفلات بعض الصور هنا، أو انقر لتحديد الصورة'}
                showAlerts={false}
                onChange={e => onUploadChange(e)}
                onDropRejected={rejectedFiles => {
                  console.log(rejectedFiles, 'onDropRejected');

                  if(rejectedFiles.length > fileslimit){
                    
                  }

                  for (let i = 0; i < rejectedFiles.length; i++) {
                    const file = rejectedFiles[i];
                    if(file.size > sizeInMB * 1024 * 1024 || file.size === 0){
                        return dispatch(changeAlert({ open: true, type: 'error', msg: `قم برفع صورة مساحتها لا تتجاوز ${sizeInMB} ميجابايت` }))
                    } else if(!acceptedFiles.includes(file.type)){
                      return dispatch(changeAlert({ open: true, type: 'error', msg: 'قم برفع صورة بإمتداد (jpg, jpeg, png, gif, bmp, svg) فقط' }))
                    }
                  }

                }}
                getFileLimitExceedMessage={ filesNumberLimit => 
                    dispatch(changeAlert({ open: true, type: 'error', msg: `عدد الصور يجب أن لا يتجاوز ${filesNumberLimit} صورة` }))
                }
                // initialFiles={[
                //  "https://images.pexels.com/photos/45853/grey-crowned-crane-bird-crane-animal-45853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                //  ]}
            />
        </div>
    );
}

const acceptedFiles2 = ["text/csv"]

export const AddDropZoneCsvFile = ({sizeInMB, fileslimit, onUploadChange}) => {

    // const [files, setFiles] = useState([
    //     "https://images.pexels.com/photos/45853/grey-crowned-crane-bird-crane-animal-45853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    //     // "https://media.istockphoto.com/id/1419410282/photo/silent-forest-in-spring-with-beautiful-bright-sun-rays.jpg?s=2048x2048&w=is&k=20&c=t9_zg20wVbrBoGn0tw__1fFq4ykeKs15TQQ3x-ehVC0=",
    //     // "https://media.istockphoto.com/id/1297349747/photo/hot-air-balloons-flying-over-the-botan-canyon-in-turkey.jpg?s=2048x2048&w=is&k=20&c=N0ogUgFEFTpIcQLci1XeUrYbrEOx7m13EMhf13QYrA0=",
    //     // "https://media.istockphoto.com/id/1347784849/photo/scenic-view-of-a-beautiful-landscape-garden-with-a-green-mowed-lawn.jpg?s=2048x2048&w=is&k=20&c=vPeW-VYR_L-Dfw9DX8n8XN4eYoKoY8UG4LlhGoHfE48="
    //   ]);

    // const onChange = e => {
    //   console.log(e, 'acceptedFiles');
    // }

    const dispatch = useDispatch()

    return (
        <div className="dropZone-container">
            <DropzoneArea
                acceptedFiles={acceptedFiles2}
                maxFileSize={sizeInMB * 1024 * 1024}
                filesLimit={fileslimit}
                // showPreviews={true}
                // showPreviewsInDropzone={false}
                dropzoneText={'قم بسحب وإفلات بعض الملفات هنا، أو انقر لتحديد الملف'}
                showAlerts={false}
                onChange={e => onUploadChange(e)}
                onDropRejected={rejectedFiles => {
                  console.log(rejectedFiles, 'onDropRejected');

                  if(rejectedFiles.length > fileslimit){
                    
                  }

                  for (let i = 0; i < rejectedFiles.length; i++) {
                    const file = rejectedFiles[i];
                    if(file.size > sizeInMB * 1024 * 1024 || file.size === 0){
                        return dispatch(changeAlert({ open: true, type: 'error', msg: `قم برفع ملف مساحته لا تتجاوز ${sizeInMB} ميجابايت` }))
                    } else if(!acceptedFiles.includes(file.type)){
                      return dispatch(changeAlert({ open: true, type: 'error', msg: 'قم برفع ملف بإمتداد (csv) فقط' }))
                    }
                  }

                }}
                getFileLimitExceedMessage={ filesNumberLimit => 
                    dispatch(changeAlert({ open: true, type: 'error', msg: `عدد الملفات يجب أن لا يتجاوز ${filesNumberLimit} ملف` }))
                }
                // initialFiles={[
                //  "https://images.pexels.com/photos/45853/grey-crowned-crane-bird-crane-animal-45853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                //  ]}
            />
        </div>
    );
}


export const PreviewDropzone = ({ oldImgs, handleRemoveImg }) => {

    return (
        <Stack direction={'row'} justifyContent={'space-around'} alignItems={'center'} style={{ minHeight: '130px', width: '100%', flexWrap: 'wrap', border: '2px dashed #bdbdbd', padding: '10px 4px 6px'}}>
            {oldImgs.map((img, index) => {
                const alt = img.split('/').pop() 
                return (
                <div key={alt} style={{ position: 'relative', height: '100px', width: '140px', objectFit: 'contain', marginBottom: '4px' }}>
                    <Fab size="small" onClick={handleRemoveImg.bind(null, img)} style={{position: 'absolute'}}>
                        <DeleteIcon fontSize="small" />
                    </Fab>
                    <img src={`${process.env.REACT_APP_IMG_URL}${img}`} alt={alt} title={alt} loading='lazy' style={{ objectFit: 'contain', height: '100px', maxWidth: '100%' }}/>
                </div>)
            })}
        </Stack>
    )
}


// import * as React from "react";
// import { Dropzone, FileMosaic } from "@dropzone-ui/react";

// export const DropZone = () => {

//   const [files, setFiles] = React.useState([
//             "https://images.pexels.com/photos/45853/grey-crowned-crane-bird-crane-animal-45853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//             "https://media.istockphoto.com/id/1419410282/photo/silent-forest-in-spring-with-beautiful-bright-sun-rays.jpg?s=2048x2048&w=is&k=20&c=t9_zg20wVbrBoGn0tw__1fFq4ykeKs15TQQ3x-ehVC0=",
//             "https://media.istockphoto.com/id/1297349747/photo/hot-air-balloons-flying-over-the-botan-canyon-in-turkey.jpg?s=2048x2048&w=is&k=20&c=N0ogUgFEFTpIcQLci1XeUrYbrEOx7m13EMhf13QYrA0=",
//             "https://media.istockphoto.com/id/1347784849/photo/scenic-view-of-a-beautiful-landscape-garden-with-a-green-mowed-lawn.jpg?s=2048x2048&w=is&k=20&c=vPeW-VYR_L-Dfw9DX8n8XN4eYoKoY8UG4LlhGoHfE48="
//           ]);

//   const updateFiles = (incommingFiles) => {
//     console.log(incommingFiles, 'incommingFiles');
//     setFiles(incommingFiles);
//   };
//   return (
//     <Dropzone onChange={updateFiles} value={files}>
//       {files.map((file, index) => (
//         <FileMosaic key={index} {...file} preview />
//       ))}
//     </Dropzone>
//   );
// }





// import {
//     Dropzone,
//     FileMosaic,
//     FullScreen,
//     ImagePreview
//   } from "@files-ui/react";
//   import * as React from "react";
  
// //   const BASE_URL = "https://admin.nkaty.com/";
//   const BASE_URL = "http://localhost:5500/";
  


//   export const DropZone = () => {
//     const [extFiles, setExtFiles] = React.useState([
//         "https://images.pexels.com/photos/45853/grey-crowned-crane-bird-crane-animal-45853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//         "https://media.istockphoto.com/id/1419410282/photo/silent-forest-in-spring-with-beautiful-bright-sun-rays.jpg?s=2048x2048&w=is&k=20&c=t9_zg20wVbrBoGn0tw__1fFq4ykeKs15TQQ3x-ehVC0=",
//         "https://media.istockphoto.com/id/1297349747/photo/hot-air-balloons-flying-over-the-botan-canyon-in-turkey.jpg?s=2048x2048&w=is&k=20&c=N0ogUgFEFTpIcQLci1XeUrYbrEOx7m13EMhf13QYrA0=",
//         "https://media.istockphoto.com/id/1347784849/photo/scenic-view-of-a-beautiful-landscape-garden-with-a-green-mowed-lawn.jpg?s=2048x2048&w=is&k=20&c=vPeW-VYR_L-Dfw9DX8n8XN4eYoKoY8UG4LlhGoHfE48="
//       ]);
//     const [imageSrc, setImageSrc] = React.useState(undefined);
  
//     const updateFiles = (incommingFiles) => {
//       console.log("incomming files", incommingFiles);
//       setExtFiles(incommingFiles);
//     };
//     const onDelete = (id) => {
//       setExtFiles(extFiles.filter((x) => x.id !== id));
//     };
//     const handleSee = (imageSource) => {
//       setImageSrc(imageSource);
//     };
//     // const handleStart = (filesToUpload) => {
//     //   console.log("advanced demo start upload", filesToUpload);
//     // };
//     // const handleFinish = (uploadedFiles) => {
//     //   console.log("advanced demo finish upload", uploadedFiles);
//     // };
//     const handleAbort = (id) => {
//       setExtFiles(
//         extFiles.map((ef) => {
//           if (ef.id === id) {
//             return { ...ef, uploadStatus: "aborted" };
//           } else return { ...ef };
//         })
//       );
//     };
//     const handleCancel = (id) => {
//       setExtFiles(
//         extFiles.map((ef) => {
//           if (ef.id === id) {
//             return { ...ef, uploadStatus: undefined };
//           } else return { ...ef };
//         })
//       );
//     };
    
//     return (
//       <>
//         <Dropzone
//           onChange={updateFiles}
//           minHeight="195px"
//           value={extFiles}
//           accept="image/jpg, image/jpeg, image/png, image/gif, image/bmp, image/svg+xml"
//           maxFiles={3}
//           maxFileSize={2 * 1024 * 1024}
//           label={'قم بسحب وإفلات بعض الصور هنا، أو انقر لتحديد الصورة'}
//           uploadConfig={{
//             // autoUpload: true
//             // url: BASE_URL + "/file",
//             url: BASE_URL + "/file",
//             cleanOnUpload: true
//           }}
//         //   onUploadStart={handleStart}
//         //   onUploadFinish={handleFinish}
//         //   fakeUpload
//           actionButtons={{
//             position: "after",
//             abortButton: {},
//             deleteButton: {},
//             uploadButton: {}
//           }}
//         >
//           {extFiles.map((file, i) => (
//             <FileMosaic
//               {...file}
//               key={i}
//               onDelete={onDelete}
//               onSee={handleSee}
//               onAbort={handleAbort}
//               onCancel={handleCancel}
//               resultOnTooltip
//               alwaysActive
//               preview
//               info
//             />
//           ))}
//         </Dropzone>
//         <FullScreen
//           open={imageSrc !== undefined}
//           onClose={() => setImageSrc(undefined)}
//         >
//           <ImagePreview src={imageSrc} />
//         </FullScreen>
//       </>
//     );
//   }
  