import { useState } from 'react';
import Box from '@mui/material/Box';

import { Avatar, FormControlLabel, InputAdornment, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { PrivateApi, PublicApi } from '../../../api/api';
import { AdminAuthEditSchema } from '../../../validation/AdminAuth.validation';
import { useDispatch, useSelector } from 'react-redux';
import { adminTableThunk, changeAlert } from '../../../store';
import { AppModal } from '../../AppModal';
import { CustomInputLabel } from '../../CustomInputLabel';

import Checkbox from '@mui/material/Checkbox';

const BoxStyle = { display: 'flex', width: '100%', marginTop: 0 }

export const EditAdminTableModalBtn = ({ style, _id }) => {

  const dispatch = useDispatch()

  const { search: { AI_Id, email: sliceEmail, block: sliceBlock }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.adminTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);

  const [getDataLoading, setGetDataLoading] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  
  const [block, setBlock] = useState(false);
  
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');


  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [rePasswordErr, setRePasswordErr] = useState('');


  const [roles, setRoles] = useState([]);

  const [SelectAll, setSelectAll] = useState(false);

  // roles
  const [Admin_show, setAdmin_show] = useState(false);
  const [Admin_add, setAdmin_add] = useState(false);
  const [Admin_edit, setAdmin_edit] = useState(false);
  const [Admin_delete, setAdmin_delete] = useState(false);

  const [Student_show, setStudent_show] = useState(false);
  const [Student_edit, setStudent_edit] = useState(false);
  const [Student_delete, setStudent_delete] = useState(false);

  const [ExchangedPoints_show, setExchangedPoints_show] = useState(false);
  const [ExchangedPoints_edit, setExchangedPoints_edit] = useState(false);

  const [FacultyCategory_show, setFacultyCategory_show] = useState(false);
  const [FacultyCategory_add, setFacultyCategory_add] = useState(false);
  const [FacultyCategory_edit, setFacultyCategory_edit] = useState(false);

  const [Trainer_show, setTrainer_show] = useState(false);
  const [Trainer_add, setTrainer_add] = useState(false);
  const [Trainer_edit, setTrainer_edit] = useState(false);

  const [Category_show, setCategory_show] = useState(false);
  const [Category_add, setCategory_add] = useState(false);
  const [Category_edit, setCategory_edit] = useState(false);

  const [Activities_show, setActivities_show] = useState(false);
  const [Activities_add, setActivities_add] = useState(false);
  const [Activities_edit, setActivities_edit] = useState(false);
  const [Activities_delete, setActivities_delete] = useState(false);

  const [ReservedActivities_show, setReservedActivities_show] = useState(false);
  const [ReservedActivities_edit, setReservedActivities_edit] = useState(false);

  const [Course_show, setCourse_show] = useState(false);
  const [Course_add, setCourse_add] = useState(false);
  const [Course_edit, setCourse_edit] = useState(false);
  const [Course_delete, setCourse_delete] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false)
    setGetDataLoading(false)
    setSubmitDataLoading(false)
    setEmail('')
    setName('')
    setBlock(false)
    setPassword('')
    setRePassword('')
    setNameErr('')
    setEmailErr('')
    setPasswordErr('')
    setRePasswordErr('')
    setRoles([])
    setSelectAll(false)
    setAdmin_show(false)
    setAdmin_add(false)
    setAdmin_edit(false)
    setAdmin_delete(false)
    setStudent_show(false)
    setStudent_edit(false)
    setStudent_delete(false)
    setExchangedPoints_show(false)
    setExchangedPoints_edit(false)
    setFacultyCategory_show(false)
    setFacultyCategory_add(false)
    setFacultyCategory_edit(false)
    setTrainer_show(false)
    setTrainer_add(false)
    setTrainer_edit(false)
    setCategory_show(false)
    setCategory_add(false)
    setCategory_edit(false)
    setActivities_show(false)
    setActivities_add(false)
    setActivities_edit(false)
    setActivities_delete(false)
    setReservedActivities_show(false)
    setReservedActivities_edit(false)
    setCourse_show(false)
    setCourse_add(false)
    setCourse_edit(false)
    setCourse_delete(false)
  };

  const handleOpenModal = async () => {
    setGetDataLoading(true)
    setOpenModal(true);

    try {
      const { data: { admin }} = await api.get(`/admin/accounts?_id=${_id}`)
      console.log(admin);

      setName(admin.name)
      setEmail(admin.email)
      setBlock(admin.block)
      setRoles(admin.roles)
      setSelectAll(admin.roles.length === 0 ? false : true )

      for (const role of admin.roles) {
        if(role === 'Admin_show') setAdmin_show(true)
        if(role === 'Admin_add') setAdmin_add(true)
        if(role === 'Admin_edit') setAdmin_edit(true)
        if(role === 'Admin_delete') setAdmin_delete(true)
    
        if(role === 'Student_show') setStudent_show(true)
        if(role === 'Student_edit') setStudent_edit(true)
        if(role === 'Student_delete') setStudent_delete(true)
    
        if(role === 'ExchangedPoints_show') setExchangedPoints_show(true)
        if(role === 'ExchangedPoints_edit') setExchangedPoints_edit(true)
    
        if(role === 'FacultyCategory_show') setFacultyCategory_show(true)
        if(role === 'FacultyCategory_add') setFacultyCategory_add(true)
        if(role === 'FacultyCategory_edit') setFacultyCategory_edit(true)
    
        if(role === 'Trainer_show') setTrainer_show(true)
        if(role === 'Trainer_add') setTrainer_add(true)
        if(role === 'Trainer_edit') setTrainer_edit(true)
    
        if(role === 'Category_show') setCategory_show(true)
        if(role === 'Category_add') setCategory_add(true)
        if(role === 'Category_edit') setCategory_edit(true)
    
        if(role === 'Activities_show') setActivities_show(true)
        if(role === 'Activities_add') setActivities_add(true)
        if(role === 'Activities_edit') setActivities_edit(true)
        if(role === 'Activities_delete') setActivities_delete(true)

        if(role === 'ReservedActivities_show') setReservedActivities_show(true)
        if(role === 'ReservedActivities_edit') setReservedActivities_edit(true)
    
        if(role === 'Course_show') setCourse_show(true)
        if(role === 'Course_add') setCourse_add(true)
        if(role === 'Course_edit') setCourse_edit(true)
        if(role === 'Course_delete') setCourse_delete(true)
      }

      setGetDataLoading(false)
      
    } catch (error) {
      console.log(error,'error error');
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء إستقبال البيانات, حاول مرة أخري' }))
      }
      handleCloseModal()
    }
    
  }

  const showErrMsg = message => {
    if(message.includes("الأسم")){
      setNameErr(message)
    } else if(message.includes("البريد الإلكتروني")){
      setEmailErr(message)
    } else if(message.includes("تأكيد كلمة المرور")){
      setRePasswordErr(message)
    } else if(message.includes("كلمة المرور")){
      setPasswordErr(message)
    }
  }

  const EditDataRequest = async () => {
      try {
        setSubmitDataLoading(true)

        const response = await api.patch('/admin/accounts', { _id, name, email, password, re_password: rePassword, roles, block })
        console.log(response, 'patch data');

        const data = { AI_Id, email: sliceEmail, block: sliceBlock, page: page + 1, sort, items: rowsPerPage }
        dispatch(adminTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تم التعديل بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();

      try {
          await AdminAuthEditSchema.validateAsync({ _id, name, email, password, re_password: rePassword, roles, block })
          
          EditDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  const resetErrMsgs = () => {
    setNameErr('')
    setEmailErr('')
    setPasswordErr('')
    setRePasswordErr('')
  }

  const onSelectAll = e => {
    const checked = e.target.checked

    setSelectAll(checked)
    
    setAdmin_show(checked)
    setAdmin_add(checked)
    setAdmin_edit(checked)
    setAdmin_delete(checked)
    setStudent_show(checked)
    setStudent_edit(checked)
    setStudent_delete(checked)
    setExchangedPoints_show(checked)
    setExchangedPoints_edit(checked)
    setFacultyCategory_show(checked)
    setFacultyCategory_add(checked)
    setFacultyCategory_edit(checked)
    setTrainer_show(checked)
    setTrainer_add(checked)
    setTrainer_edit(checked)
    setCategory_show(checked)
    setCategory_add(checked)
    setCategory_edit(checked)
    setActivities_show(checked)
    setActivities_add(checked)
    setActivities_edit(checked)
    setActivities_delete(checked)
    setReservedActivities_show(checked)
    setReservedActivities_edit(checked)
    setCourse_show(checked)
    setCourse_add(checked)
    setCourse_edit(checked)
    setCourse_delete(checked)

    const defaultRoles = [
      'Admin_show', 'Admin_add', 'Admin_edit', 'Admin_delete',
      'Student_show', 'Student_edit', 'Student_delete',
      'ExchangedPoints_show', 'ExchangedPoints_edit',
      'FacultyCategory_show', 'FacultyCategory_add', 'FacultyCategory_edit',
      'Trainer_show', 'Trainer_add', 'Trainer_edit',
      'Category_show', 'Category_add', 'Category_edit',
      'Activities_show', 'Activities_add', 'Activities_edit', 'Activities_delete',
      'ReservedActivities_show', 'ReservedActivities_edit',
      'Course_show', 'Course_add', 'Course_edit', 'Course_delete'
    ]

    setRoles(state => checked ? defaultRoles : [])
  }

  return (
    <>
      <Tooltip title="تعديل" style={{ margin: '0 10px' }}>
        <Avatar onClick={handleOpenModal} variant="rounded" sx={style}>
            <EditIcon fontSize='small'/>
        </Avatar>
      </Tooltip>

      <AppModal title="تعديل البيانات" open={openModal} onClose={handleCloseModal} isLoading={getDataLoading} size="sm">

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '100%' }}
            
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={nameErr.length > 0 && true }
                    id="outlined-error-name"
                    label="الأسم"
                    helperText={nameErr}
                    value={name}
                    onChange={e => {
                      setName(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={emailErr.length > 0 && true }
                    id="outlined-error-email"
                    label="البريد الإلكتروني"
                    helperText={emailErr}
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <Typography variant='subtitle2' component="span" style={{textAlign: 'right', width: '100%', marginBottom: '-10px'}}>
                    <p>ملاحظة:</p>
                    <p> كلمة المرور يجب أن تحتوي على الأقل 8 أحرف, و على حرف كبير، وحرف صغير، ورقم، وحرف خاص</p>
                </Typography>

                <TextField
                    fullWidth
                    error={passwordErr.length > 0 && true }
                    id="outlined-error-password"
                    label="كلمة المرور"
                    helperText={passwordErr}
                    value={password}
                    type="password"
                    onChange={e => {
                      setPassword(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={rePasswordErr.length > 0 && true }
                    id="outlined-error-re-password"
                    label="تأكيد كلمة المرور"
                    helperText={rePasswordErr}
                    value={rePassword}
                    type="password"
                    onChange={e => {
                      setRePassword(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <Stack direction={'row'} justifyContent={'flex-start'} sx={{ width: '100%'}}>
                  <FormControlLabel
                    style={{direction: 'ltr', marginRight: 0 }}
                    control={
                    <Switch color="primary" checked={block} onChange={e => {
                      setBlock(e.target.checked)
                      resetErrMsgs()
                    }}/>}
                    label="حالة الحظر"
                  />
                </Stack>

                  <Box style={{...BoxStyle, marginTop: '20px'}} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} className="permissionsTitle">الصلاحيات :</Stack>
                    <FormControlLabel
                      label="تحديد الكل"
                      control={<Checkbox checked={SelectAll} onChange={onSelectAll} />}
                    />
                  </Box>

                  <Box style={{...BoxStyle, marginTop: '20px'}} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>حسابات (المدربين) :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={Admin_show} onChange={e => {
                        setAdmin_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Admin_show' ] : state.filter(i => i !== 'Admin_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="إضافة"
                      control={<Checkbox checked={Admin_add} onChange={e => { 
                        setAdmin_add(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Admin_add' ] : state.filter(i => i !== 'Admin_add'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={Admin_edit} onChange={e => { 
                        setAdmin_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Admin_edit' ] : state.filter(i => i !== 'Admin_edit'))
                      }} />}
                    />
                    <FormControlLabel
                      label="حذف"
                      control={<Checkbox checked={Admin_delete} onChange={e => { 
                        setAdmin_delete(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Admin_delete' ] : state.filter(i => i !== 'Admin_delete'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>حسابات (الطلاب) :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={Student_show} onChange={e => { 
                        setStudent_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Student_show' ] : state.filter(i => i !== 'Student_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={Student_edit} onChange={e => { 
                        setStudent_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Student_edit' ] : state.filter(i => i !== 'Student_edit'))
                      }} />}
                    />
                    <FormControlLabel
                      label="حذف"
                      control={<Checkbox checked={Student_delete} onChange={e => { 
                        setStudent_delete(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Student_delete' ] : state.filter(i => i !== 'Student_delete'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>تبديل النقاط :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={ExchangedPoints_show} onChange={e => { 
                        setExchangedPoints_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'ExchangedPoints_show' ] : state.filter(i => i !== 'ExchangedPoints_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={ExchangedPoints_edit} onChange={e => { 
                        setExchangedPoints_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'ExchangedPoints_edit' ] : state.filter(i => i !== 'ExchangedPoints_edit'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>الأقسام :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={FacultyCategory_show} onChange={e => { 
                        setFacultyCategory_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'FacultyCategory_show' ] : state.filter(i => i !== 'FacultyCategory_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="إضافة"
                      control={<Checkbox checked={FacultyCategory_add} onChange={e => { 
                        setFacultyCategory_add(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'FacultyCategory_add' ] : state.filter(i => i !== 'FacultyCategory_add'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={FacultyCategory_edit} onChange={e => { 
                        setFacultyCategory_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'FacultyCategory_edit' ] : state.filter(i => i !== 'FacultyCategory_edit'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>المدربين :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={Trainer_show} onChange={e => { 
                        setTrainer_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Trainer_show' ] : state.filter(i => i !== 'Trainer_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="إضافة"
                      control={<Checkbox checked={Trainer_add} onChange={e => { 
                        setTrainer_add(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Trainer_add' ] : state.filter(i => i !== 'Trainer_add'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={Trainer_edit} onChange={e => { 
                        setTrainer_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Trainer_edit' ] : state.filter(i => i !== 'Trainer_edit'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>التصنيفات :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={Category_show} onChange={e => { 
                        setCategory_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Category_show' ] : state.filter(i => i !== 'Category_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="إضافة"
                      control={<Checkbox checked={Category_add} onChange={e => { 
                        setCategory_add(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Category_add' ] : state.filter(i => i !== 'Category_add'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={Category_edit} onChange={e => { 
                        setCategory_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Category_edit' ] : state.filter(i => i !== 'Category_edit'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>البرامج :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={Activities_show} onChange={e => { 
                        setActivities_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Activities_show' ] : state.filter(i => i !== 'Activities_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="إضافة"
                      control={<Checkbox checked={Activities_add} onChange={e => { 
                        setActivities_add(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Activities_add' ] : state.filter(i => i !== 'Activities_add'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={Activities_edit} onChange={e => { 
                        setActivities_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Activities_edit' ] : state.filter(i => i !== 'Activities_edit'))
                      }} />}
                    />
                    <FormControlLabel
                      label="حذف"
                      control={<Checkbox checked={Activities_delete} onChange={e => { 
                        setActivities_delete(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Activities_delete' ] : state.filter(i => i !== 'Activities_delete'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>حضور الطلاب :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={ReservedActivities_show} onChange={e => { 
                        setReservedActivities_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'ReservedActivities_show' ] : state.filter(i => i !== 'ReservedActivities_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={ReservedActivities_edit} onChange={e => { 
                        setReservedActivities_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'ReservedActivities_edit' ] : state.filter(i => i !== 'ReservedActivities_edit'))
                      }} />}
                    />
                  </Box>

                  <Box style={BoxStyle} className="checkBox">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>المواد الدراسية :</Stack>
                    <FormControlLabel
                      label="مشاهدة"
                      control={<Checkbox checked={Course_show} onChange={e => { 
                        setCourse_show(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Course_show' ] : state.filter(i => i !== 'Course_show'))
                      }} />}
                    />
                    <FormControlLabel
                      label="إضافة"
                      control={<Checkbox checked={Course_add} onChange={e => { 
                        setCourse_add(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Course_add' ] : state.filter(i => i !== 'Course_add'))
                      }} />}
                    />
                    <FormControlLabel
                      label="تعديل"
                      control={<Checkbox checked={Course_edit} onChange={e => { 
                        setCourse_edit(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Course_edit' ] : state.filter(i => i !== 'Course_edit'))
                      }} />}
                    />
                    <FormControlLabel
                      label="حذف"
                      control={<Checkbox checked={Course_delete} onChange={e => { 
                        setCourse_delete(e.target.checked)
                        setRoles(state => e.target.checked ? [ ...state, 'Course_delete' ] : state.filter(i => i !== 'Course_delete'))
                      }} />}
                    />
                  </Box>


                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<EditIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>تعديل</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
