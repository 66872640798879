import { useState } from 'react';
import Box from '@mui/material/Box';

import { Avatar, FormControlLabel, InputAdornment, Stack, Switch, TextField, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { EditCourseSchema } from '../../../../validation/Course.validation';
import { useDispatch, useSelector } from 'react-redux';
import { courseTableThunk, changeAlert } from '../../../../store';
import { AppModal } from '../../../AppModal';

export const EditCourseModalBtn = ({ style, _id }) => {

  const dispatch = useDispatch()
  
  const { search: { AI_Id, name: sliceName, courseCode: sliceCourseCode, courseNumber: sliceCourseNumber }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.courseTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);

  const [getDataLoading, setGetDataLoading] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [courseNameAr, setCourseNameAr] = useState('');
  const [courseNameEn, setCourseNameEn] = useState('');
  const [courseInstructorAr, setCourseInstructorAr] = useState('');
  const [courseInstructorEn, setCourseInstructorEn] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const [courseNumber, setCourseNumber] = useState('');

  const [courseNameArErr, setCourseNameArErr] = useState('');
  const [courseNameEnErr, setCourseNameEnErr] = useState('');
  const [courseInstructorArErr, setCourseInstructorArErr] = useState('');
  const [courseInstructorEnErr, setCourseInstructorEnErr] = useState('');
  const [courseCodeErr, setCourseCodeErr] = useState('');
  const [courseNumberErr, setCourseNumberErr] = useState('');


  const handleCloseModal = () => {
    setOpenModal(false)
    setGetDataLoading(false)
    setSubmitDataLoading(false)

    setCourseNameAr('')
    setCourseNameEn('')
    setCourseInstructorAr('')
    setCourseInstructorEn('')
    setCourseCode('')
    setCourseNumber('')

    setCourseNameArErr('')
    setCourseNameEnErr('')
    setCourseInstructorArErr('')
    setCourseInstructorEnErr('')
    setCourseCodeErr('')
    setCourseNumberErr('')
  };

  const handleOpenModal = async () => {
    setGetDataLoading(true)
    setOpenModal(true);

    try {
      const { data: { course }} = await api.get(`/admin/course?_id=${_id}`)
      console.log(course);
      
      setCourseNameAr(course.courseName.ar)
      setCourseNameEn(course.courseName.en)
      setCourseInstructorAr(course.courseInstructor.ar)
      setCourseInstructorEn(course.courseInstructor.en)
      setCourseCode(course.courseCode)
      setCourseNumber(course.courseNumber)

      setGetDataLoading(false)
      
    } catch (error) {
      console.log(error,'error error');
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء إستقبال البيانات, حاول مرة أخري' }))
      }
      handleCloseModal()
    }
    
  }

  const showErrMsg = message => {
    if(message.includes('أسم المادة (عربي)')){
      setCourseNameArErr(message)
    } else if(message.includes('أسم المادة (إنجليزي)')){
      setCourseNameEnErr(message)
    } else if(message.includes('أسم المحاضر (عربي)')){
      setCourseInstructorArErr(message)
    } else if(message.includes('أسم المحاضر (إنجليزي)')){
      setCourseInstructorEnErr(message)
    } else if(message.includes('الرقم المرجعي')){
      setCourseCodeErr(message)
    } else if(message.includes('رقم المادة')){
      setCourseNumberErr(message)
    }
  }

  const EditDataRequest = async () => {
      try {
        setSubmitDataLoading(true)

        const requestData = {
          _id,
          courseName: {
              ar: courseNameAr,
              en: courseNameEn
          },
          courseInstructor: {
              ar: courseInstructorAr,
              en: courseInstructorEn
          },
          courseCode,
          courseNumber
        }

        const response = await api.patch('/admin/course', requestData)
        console.log(response, 'patch data');

        const data = { AI_Id, name: sliceName, courseCode: sliceCourseCode, courseNumber: sliceCourseNumber, page: page + 1, sort, items: rowsPerPage }
        dispatch(courseTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تم التعديل بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();

      const requestData = {
        _id,
        courseName: {
            ar: courseNameAr,
            en: courseNameEn
        },
        courseInstructor: {
            ar: courseInstructorAr,
            en: courseInstructorEn
        },
        courseCode,
        courseNumber
      }

      try {
          await EditCourseSchema.validateAsync(requestData)
          
          EditDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  const resetErrMsgs = () => {
    setCourseNameArErr('')
    setCourseNameEnErr('')
    setCourseInstructorArErr('')
    setCourseInstructorEnErr('')
    setCourseCodeErr('')
    setCourseNumberErr('')
  }

  return (
    <>
      <Tooltip title="تعديل" style={{ margin: '0 10px' }}>
        <Avatar onClick={handleOpenModal} variant="rounded" sx={style}>
            <EditIcon fontSize='small'/>
        </Avatar>
      </Tooltip>

      <AppModal title="تعديل البيانات" open={openModal} onClose={handleCloseModal} isLoading={getDataLoading} >

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={courseNameArErr.length > 0 && true }
                    id="outlined-error-courseNameAr"
                    label='أسم المادة (عربي)'
                    helperText={courseNameArErr}
                    value={courseNameAr}
                    onChange={e => {
                      setCourseNameAr(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={courseNameEnErr.length > 0 && true }
                    id="outlined-error-courseNameEn"
                    label='أسم المادة (إنجليزي)'
                    helperText={courseNameEnErr}
                    value={courseNameEn}
                    onChange={e => {
                      setCourseNameEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseInstructorArErr.length > 0 && true }
                    id="outlined-error-courseInstructorAr"
                    label='أسم المحاضر (عربي)'
                    helperText={courseInstructorArErr}
                    value={courseInstructorAr}
                    onChange={e => {
                      setCourseInstructorAr(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseInstructorEnErr.length > 0 && true }
                    id="outlined-error-courseInstructorEn"
                    label='أسم المحاضر (إنجليزي)'
                    helperText={courseInstructorEnErr}
                    value={courseInstructorEn}
                    onChange={e => {
                      setCourseInstructorEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseCodeErr.length > 0 && true }
                    id="outlined-error-courseCode"
                    label='الرقم المرجعي'
                    helperText={courseCodeErr}
                    value={courseCode}
                    onChange={e => {
                      setCourseCode(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseNumberErr.length > 0 && true }
                    id="outlined-error-courseNumber"
                    label='رقم المادة'
                    helperText={courseNumberErr}
                    value={courseNumber}
                    onChange={e => {
                      setCourseNumber(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<EditIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>تعديل</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
