import { useState } from 'react';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { exchangePointsTableThunk, changeAlert } from '../../../../store';
import { ConfirmModal } from '../../../ConfirmModal';

export const ConfirmExchangePointsTableModalBtn = ({ _id, type, orderStatus, title }) => {

  const dispatch = useDispatch()
  
  const { search: { student_academic_number, type: sliceType, status, course }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.exchangePointsTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false)
    setSubmitDataLoading(false)
  };

  const handleOpenModal = () => {
    setOpenModal(true) 
  }

  const handleConfirmBtn = async () => {
    try {
      setSubmitDataLoading(true)
      
      if(type === 'exchange'){

        const response = await api.post('/admin/exchangedPoints', { _id, status: orderStatus })
        console.log(response, 'post data');

      } else if(type === 'objection'){

        const response = await api.patch('/admin/exchangedPoints', { _id, status: orderStatus })
        console.log(response, 'patch data');
      }

      const data = { student_academic_number, type: sliceType, status, course, page: page + 1, sort, items: rowsPerPage }
      dispatch(exchangePointsTableThunk({ api, data })) // refetch all data according to pagination records

      dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت العملية بنجاح' }))

      handleCloseModal()

    } catch (error) {
      console.log(error.response.data.error, 'patch error');
      setSubmitDataLoading(false)
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء التنفيذ, حاول مرة أخري' }))
      }
    }
  }

  return (<ConfirmModal TooltipTitle={title} Header={`عملية ${title} الطلب`} Body={`تأكيد عملية ${title} الطلب ؟`} orderStatus={orderStatus} open={openModal} handleOpen={handleOpenModal} handleClose={handleCloseModal} handleConfirmBtn={handleConfirmBtn} submitDataLoading={submitDataLoading} />);
}


