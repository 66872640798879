import { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { PrivateApi, PublicApi } from '../../../api/api';
import { AddFacultyCategorySchema } from '../../../validation/FacultyCategory.validation';
import { useDispatch, useSelector } from 'react-redux';
import { facultyCategoryTableThunk, changeAlert } from '../../../store';
import { AppModal } from '../../AppModal';

export const AddFacultyCategoryTableModalBtn = () => {

  const dispatch = useDispatch()

  const { search: { AI_Id, search: sliceSearch }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.facultyCategoryTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [facultyCategoryNameAr, setFacultyCategoryNameAr] = useState('');
  const [facultyCategoryNameEn, setFacultyCategoryNameEn] = useState('');

  const [facultyCategoryNameArErr, setFacultyCategoryNameArErr] = useState('');
  const [facultyCategoryNameEnErr, setFacultyCategoryNameEnErr] = useState('');

  const handleCloseModal = () => {
    setOpenModal(false)
    setSubmitDataLoading(false)
    setFacultyCategoryNameAr('')
    setFacultyCategoryNameEn('')

    setFacultyCategoryNameArErr('')
    setFacultyCategoryNameEnErr('')
  };

  const handleOpenModal = () => {
    setOpenModal(true) 
  }
  
  const showErrMsg = message => {
    if(message.includes('الأسم (عربي)')){
      setFacultyCategoryNameArErr(message)
    } else if(message.includes('الأسم (إنجليزي)')){
      setFacultyCategoryNameEnErr(message)
    }
  }

  const resetErrMsgs = () => {
    setFacultyCategoryNameArErr('')
    setFacultyCategoryNameEnErr('')
  }

  const AddDataRequest = async () => {
      try {
        setSubmitDataLoading(true)

        const requestData = {
          name: {
              ar: facultyCategoryNameAr,
              en: facultyCategoryNameEn
          }
        }

        const response = await api.post('/admin/facultyCategory', requestData)
        console.log(response, 'add data');

        const data = { AI_Id, search: sliceSearch, page: page + 1, sort, items: rowsPerPage }
        
        dispatch(facultyCategoryTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت الإضافة بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();
      
      const requestData = {
        name: {
            ar: facultyCategoryNameAr,
            en: facultyCategoryNameEn
        }
      }
      
      try {
          await AddFacultyCategorySchema.validateAsync(requestData)
          
          AddDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }


  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant="contained" 
        size="small" 
        style={{ height: '38px', width: '120px'}} 
        startIcon={<AddIcon className='btnLogo' />}
        className='loadingBtn'
      >
        أضف تصنيف
      </Button>

      <AppModal title="إضافة تصنيف" open={openModal} onClose={handleCloseModal} >

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={facultyCategoryNameArErr.length > 0 && true }
                    id="outlined-error-facultyCategoryNameAr"
                    label='الأسم (عربي)'
                    helperText={facultyCategoryNameArErr}
                    value={facultyCategoryNameAr}
                    onChange={e => {
                      setFacultyCategoryNameAr(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={facultyCategoryNameEnErr.length > 0 && true }
                    id="outlined-error-facultyCategoryNameEn"
                    label='الأسم (إنجليزي)'
                    helperText={facultyCategoryNameEnErr}
                    value={facultyCategoryNameEn}
                    onChange={e => {
                      setFacultyCategoryNameEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<AddIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>أضف</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
