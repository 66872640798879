import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { FormControl, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import { CircularProgress } from '@mui/material'

import moment from 'moment';
import 'moment/locale/ar'; 

import { useDispatch, useSelector } from 'react-redux';
import { changeCategoryTablePage, changeCategoryTableSort, changeCategoryTablePagination, categoryTableThunk } from '../../../../store';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { EditCategoryModalBtn } from './EditCategoryModalBtn';
// import { DeleteCategoryTableModalBtn } from './DeleteCategoryTableModalBtn';


const columns = [
  { id: 'AI_Id', label: 'رقم', minWidth: 50 },
  { id: 'name_ar', label: 'الأسم (عربي)', minWidth: 50 },
  { id: 'name_en', label: 'الأسم (إنجليزي)', minWidth: 50 },
  { id: 'createdAt', label: 'تاريخ الإنشاء', minWidth: 50 },
  { id: 'updatedAt', label: 'تاريخ التعديل', minWidth: 50 },
  { id: 'setting', label: 'خصائص', minWidth: 50 }
];

export const CategoryTable = ({ roles }) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  
  const api = PrivateApi(PublicApi)

  const { data: rows, search: { AI_Id, name }, pagination: {items: rowsPerPage, page, sort, lastPage}, isLoading } = useSelector( state => state.categoryTable )
  
  const TablePaginationActions = (props) => {
   
    const { count, page, rowsPerPage, onPageChange } = props;
    
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
      dispatch(changeCategoryTablePage(0))
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
      dispatch(changeCategoryTablePage(page - 1))
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
      dispatch(changeCategoryTablePage(page + 1))
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      dispatch(changeCategoryTablePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1)))
    };
  
    return (
      <>

      <Stack sx={{ flexShrink: 0, ml: 0.5 }} direction={'row'} justifyContent={'center'} alignItems={'center'}>
        <FormControl variant="standard" sx={{ m: 1, width: 85, }} className='appSortList'>
          <Select
            inputProps={{MenuProps: {disableScrollLock: true}}}
            sx={{fontSize: '12px', border: 'unset',  textAlign: 'right'}}
            value={sort}
            onChange={(e) => dispatch(changeCategoryTableSort(+e.target.value))}
          >
            <MenuItem value={1}>تصاعدي</MenuItem>
            <MenuItem value={-1}>تنازلي</MenuItem>
          </Select>
        </FormControl>

        <div> : ترتيب </div>
      </Stack>


      <Box sx={{ flexShrink: 0, ml: 0.5, direction: 'rtl' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {/* {theme.direction === 'ltr' ? <LastPageIcon /> : <FirstPageIcon />} */}
          <LastPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {/* {theme.direction === 'ltr' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {/* {theme.direction === 'ltr' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {/* {theme.direction === 'ltr' ? <FirstPageIcon /> : <LastPageIcon />} */}
          <FirstPageIcon />
        </IconButton>
        
      </Box>
     
      </>
    );
  }

  const labelDisplayedRows = ({ from, to, count }) => `${from}–${to} إلي ${count !== -1 ? count : `أكثر من ${to}`}`

  const handleChangePage = (event, newPage) => {
    dispatch(changeCategoryTablePage(newPage))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeCategoryTablePagination({ page: 0, items: +event.target.value }))
  };

  const ShowData = (row, column) => {

    if(column.id === 'name_ar'){
      return row.name.ar
    } else if(column.id === 'name_en'){
      return row.name.en
    } else if(['createdAt', 'updatedAt'].includes(column.id)){
      return (
        <Tooltip title={moment(row[column.id]).locale('en').format('YYYY-MM-DD hh:mm:ss A')}>
         <span>{moment(row[column.id]).locale('ar').fromNow()}</span>
        </Tooltip>
      )
    } else if(column.id === 'setting'){
      const style = { width: 28, height: 28, cursor: 'pointer'}
      const _id = `${row._id}`
      
      return(
        <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
          { roles.includes('Category_edit') ? <EditCategoryModalBtn  style={{ ...style, bgcolor: theme.palette.warning.main }} _id={_id} /> : '' }
          {/* <DeleteCategoryTableModalBtn _id={_id} /> */}
        </Stack>
      )
  
    } else {
      return row[column.id]
    }
  
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    },
  }));

  const tableStatus = status => {
    return (
      <TableRow>
        <TableCell colSpan={columns.length} style={{height: '55vh', border: 'unset', overflow: 'hidden'}}>
          <Stack justifyContent={'center'} alignItems={'center'} spacing={1}>
            {status === 'notFound' ?
            <>
              <CommentsDisabledIcon fontSize='large' sx={{color: '#a9a9a9'}}/>
              <Typography variant="subtitle1" component={'div'}>
                لا يوجد بيانات
              </Typography> 
            </>
            : <CircularProgress /> }
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  const loadData = () => {

    if(isLoading) return tableStatus('loading')

    if(rows.length === 0) return tableStatus('notFound')

    if(rows.length > 0){
        console.log(page , rowsPerPage, 'page * rowsPerPage');
        return rows.map((row) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.AI_Id}>
              {columns.map((column) => {
                // const value = row[column.id];
                return (
                  <TableCell key={column.id} align="center" style={column.direction && {direction: column.direction}}>
                      {ShowData(row, column)}
                  </TableCell>
                );
              })}
            </TableRow>
        ))
    }

  }

  const [isReqMount, setIsReqMount] = useState(false);

  
  useEffect(() => {

    const data = { AI_Id, name, page: page + 1, sort, items: rowsPerPage }

    setIsReqMount(true)
    if(isReqMount) dispatch(categoryTableThunk({ api, data }))

  }, [isReqMount, AI_Id, name, page, sort, rowsPerPage, api, dispatch]);


  return (
    <>
    <Paper className="appTable" sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ height: '65vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loadData()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[12, 25, 50, 80]}
        component="div"
        // count={rows.length}
        count={rowsPerPage * lastPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage={"عدد النتائج في الصفحة"}
        labelDisplayedRows={labelDisplayedRows}
      />

    </Paper>
    </>
  );
}