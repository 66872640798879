import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

export const AppSelect = ({ items, value, onChange, minWidth, label, variant, disabled, ErrorMsg }) => {
    const attr = variant && variant === 'outlined' ? { className: 'outlinedSelect' } : { variant: 'standard' }
    return (
        <FormControl {...attr} sx={{ m: 1, minWidth }} >
            <InputLabel id={`label-${Math.random()}`}>{label}</InputLabel>
            {items && (
                <>
                    <Select value={value} inputProps={{MenuProps: {disableScrollLock: true}}} label={label} onChange={onChange} labelId={`label-${Math.random()}`} disabled={disabled ? true : false}>
                        {items.map(({ key, value }, index) => (<MenuItem key={index} value={value}>{key}</MenuItem>))}
                    </Select>
                    <FormHelperText style={{color: '#d32f2f'}}>{ErrorMsg && ErrorMsg.length > 0 ? ErrorMsg : ''}</FormHelperText>
                </>)}
        </FormControl>
    )
}
