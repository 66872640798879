import { useState } from 'react';
import Box from '@mui/material/Box';

import { Autocomplete, Avatar, FormControlLabel, InputAdornment, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { StudentEditSchema } from '../../../../validation/Student.validation';
import { useDispatch, useSelector } from 'react-redux';
import { studentAccountsTableThunk, changeAlert } from '../../../../store';
import { AppModal } from '../../../AppModal';
import { ErrParagragh } from '../../../ErrParagragh';

export const EditStudentAccountsModalBtn = ({ style, _id }) => {

  const dispatch = useDispatch()
  
  const { search: { AI_Id, email: sliceEmail, mobCode: sliceMobCode, mobile: sliceMobile, academic_number: sliceAcademic_number, earnedPoints, remainingPoints, exchangedPoints, facultyCategory: sliceFacultyCategory, block: sliceBlock }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.studentAccountsTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);

  const [getDataLoading, setGetDataLoading] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const [mobCode, setMobCode] = useState('+966');
  const [mobile, setMobile] = useState('');

  const [selectCategory, setSelectCategory] = useState('');
  const [selectCategoryItems, setSelectCategoryItems] = useState([]);

  const [academic_number, setAcademic_number] = useState('');
  
  const [block, setBlock] = useState(false);
  
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [mobileErr, setMobileErr] = useState('');
  const [selectCategoryErr, setSelectCategoryErr] = useState('');
  const [academic_numberErr, setAcademic_numberErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [rePasswordErr, setRePasswordErr] = useState('');


  const handleCloseModal = () => {
    setOpenModal(false)
    setGetDataLoading(false)
    setSubmitDataLoading(false)

    setEmail('')
    setName('')
    setMobCode('+966')
    setMobile('')
    // setSelectCategory('');
    // setSelectCategoryItems([]);
    setAcademic_number('')
    setBlock(false)
    setPassword('')
    setRePassword('')
    
    setNameErr('')
    setEmailErr('')
    setMobileErr('')
    setSelectCategoryErr('')
    setAcademic_numberErr('')
    setPasswordErr('')
    setRePasswordErr('')
  };

  const handleOpenModal = async () => {
    setGetDataLoading(true)
    setOpenModal(true);

    try {

      const { data: { results: cats }} = await api.get(`/admin/facultyCategory/list`)

      if(cats.length > 0){

        const filteredCategories = cats.map(category => ({ value: category._id, label: category.name.ar }))
        setSelectCategoryItems(filteredCategories)
      } else {
        dispatch(changeAlert({ open: true, type: 'error', msg: 'يجب إضافة أقسام أولاً' }))
        handleCloseModal()
      }

      const { data: { student }} = await api.get(`/admin/student/studentAccounts?_id=${_id}`)
      
      setName(student.name)
      setEmail(student.email)
      setAcademic_number(student.academic_number)
      setMobCode(student.mobCode)
      setMobile(student.mobile)
      setBlock(student.block)
      setSelectCategory(student.facultyCategory)
      setGetDataLoading(false)
      
    } catch (error) {
      console.log(error,'error error');
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء إستقبال البيانات, حاول مرة أخري' }))
      }
      handleCloseModal()
    }
    
  }

  const showErrMsg = message => {
    if(message.includes("الأسم")){
      setNameErr(message)
    } else if(message.includes("رقم الجوال")){
      setMobileErr(message)
    } else if(message.includes("القسم")){
      setSelectCategoryErr(message)
    } else if(message.includes("البريد الإلكتروني")){
      setEmailErr(message)
    } else if(message.includes("الرقم الأكاديمي")){
      setAcademic_numberErr(message)
    } else if(message.includes("تأكيد كلمة المرور")){
      setRePasswordErr(message)
    } else if(message.includes("كلمة المرور")){
      setPasswordErr(message)
    }
  }
  
  const EditDataRequest = async () => {
      try {
        setSubmitDataLoading(true)

        const response = await api.patch('/admin/student/studentAccounts', { _id, name, mobCode, mobile, facultyCategory: selectCategory, email, academic_number, password, re_password: rePassword, block })
        console.log(response, 'patch data');

        const data = { AI_Id, email: sliceEmail, mobCode: sliceMobCode, mobile: sliceMobile, academic_number: sliceAcademic_number, earnedPoints, remainingPoints, exchangedPoints, facultyCategory: sliceFacultyCategory, block: sliceBlock, page: page + 1, sort, items: rowsPerPage }
        dispatch(studentAccountsTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تم التعديل بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();

      try {
          await StudentEditSchema.validateAsync({ _id, name, mobCode, mobile, facultyCategory: selectCategory, email, academic_number, password, re_password: rePassword, block })
          
          EditDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  const resetErrMsgs = () => {
    setNameErr('')
    setEmailErr('')
    setMobileErr('')
    setAcademic_numberErr('')
    setPasswordErr('')
    setRePasswordErr('')
    setSelectCategoryErr('')
  }

  return (
    <>
      <Tooltip title="تعديل" style={{ margin: '0 10px' }}>
        <Avatar onClick={handleOpenModal} variant="rounded" sx={style}>
            <EditIcon fontSize='small'/>
        </Avatar>
      </Tooltip>

      <AppModal title="تعديل البيانات" open={openModal} onClose={handleCloseModal} isLoading={getDataLoading} >

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={nameErr.length > 0 && true }
                    id="outlined-error-name"
                    label="الأسم"
                    helperText={nameErr}
                    value={name}
                    onChange={e => {
                      setName(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                  fullWidth
                  error={mobileErr.length > 0 && true }
                  id="outlined-error-mobile"
                  label="رقم الجوال"
                  helperText={mobileErr}
                  value={mobile}
                  onChange={e => {
                    setMobile(e.target.value)
                    resetErrMsgs()
                  }}
                  type='text'
                  inputProps={{ maxLength: '10' }}
                  InputProps={{
                  endAdornment: <InputAdornment position="end" style={{direction:'ltr'}}>({mobCode})</InputAdornment>,
                  }}
                />

                <Autocomplete
                  disablePortal
                  disableClearable
                  fullWidth
                  id="categoryList"
                  options={selectCategoryItems}
                  value={selectCategoryItems.find(item => item.value === selectCategory)} // Set the default value here.
                  getOptionLabel={(option) => option.label}
                  // getOptionSelected={(option, value) => option.value === value.value}
                  // getoptionselected
                  renderInput={(params) => <TextField {...params} label="القسم" />}
                  onChange={(e, value) => {
                    console.log(value, 'valuevalue');
                    // setSelectCategory(value.value)
                    if (value) {
                      setSelectCategory(value.value);
                    } else {
                      setSelectCategory(''); // Handle the case where no option is selected to clear the default.
                    }
                    resetErrMsgs()
                  }}
                />
                <ErrParagragh errMsg={selectCategoryErr} />
                
                <TextField
                    fullWidth
                    error={emailErr.length > 0 && true }
                    id="outlined-error-email"
                    label="البريد الإلكتروني"
                    helperText={emailErr}
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={academic_numberErr.length > 0 && true }
                    id="outlined-error-academic_number"
                    label="الرقم الأكاديمي"
                    helperText={academic_numberErr}
                    value={academic_number}
                    onChange={e => {
                      setAcademic_number(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <Typography variant='subtitle2' component="span" style={{textAlign: 'right', width: '100%', marginBottom: '-10px'}}>
                    <p>ملاحظة:</p>
                    <p> كلمة المرور يجب أن تحتوي على الأقل 8 أحرف, و على حرف كبير، وحرف صغير، ورقم، وحرف خاص</p>
                </Typography>

                <TextField
                    fullWidth
                    error={passwordErr.length > 0 && true }
                    id="outlined-error-password"
                    label="كلمة المرور"
                    helperText={passwordErr}
                    value={password}
                    type="password"
                    onChange={e => {
                      setPassword(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={rePasswordErr.length > 0 && true }
                    id="outlined-error-re-password"
                    label="تأكيد كلمة المرور"
                    helperText={rePasswordErr}
                    value={rePassword}
                    type="password"
                    onChange={e => {
                      setRePassword(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <Stack direction={'row'} justifyContent={'flex-start'} sx={{ width: '100%'}}>
                  <FormControlLabel
                    style={{direction: 'ltr', marginRight: 0 }}
                    control={<Switch color="primary" checked={block} onChange={e => {
                      setBlock(e.target.checked)
                      resetErrMsgs()
                    }} />}
                    label="حالة الحظر"
                  />
                </Stack>
                
                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<EditIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>تعديل</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
