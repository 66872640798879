import { createSlice } from "@reduxjs/toolkit";
import { msgThunk } from "../thunks/msgThunk";

const msgSlice = createSlice({
    name: 'msg',
    initialState: '',
    extraReducers(builder){
        builder.addCase(msgThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'msgThunk.fulfilled')
            return action.payload.name
        });
        // builder.addCase(msgThunk.rejected, (state, action) => {
        //     console.log(action.payload, 'msgThunk.rejected')
        // })
    }
})

export const msgReducer = msgSlice.reducer