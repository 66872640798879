import { createAsyncThunk } from "@reduxjs/toolkit";

export const studentAccountsTableThunk = createAsyncThunk('studentAccountsTable/getData', async ({ api, data }, { rejectWithValue }) => {

    try {
        const res = await api.get('/admin/student/studentAccounts', { params: data })
        console.log(res, 'studentAccountsTableThunk succ');
        return res?.data

    } catch (error) {
        console.log(error, 'studentAccountsTableThunk err');
        return rejectWithValue(error.response.data)
    }

})