import { useEffect, useState } from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { AppSelect } from '../../../AppSelect'
import { useDispatch } from 'react-redux';
import { changeExchangePointsTableType, changeExchangePointsTableStatus, changeExchangePointsTableCourse, changeExchangePointsTableSearch } from '../../../../store';
// import { PrivateApi, PublicApi } from '../../../../api/api';

export const ExchangePointsSearchBar = ({ courses }) => {

  const dispatch = useDispatch()
  
  const initSearchData = { student_academic_number: '', type: '', status: '', course: '' }

  const initPaginationData = { page: 0, lastPage: 0 }

  // const api = PrivateApi(PublicApi)

  const [search, setSearch] = useState('all');
  const searchItems = [
    { value: 'all', key: "الكل" },
    { value: 'student_academic_number', key: "الرقم الأكاديمي" },
  ]

  const [inputSearch, setInputSearch] = useState('');

  const onSearchListChange = (e) => {
    setSearch(e.target.value)
    if(e.target.value === 'all') dispatch(changeExchangePointsTableSearch({ search: initSearchData, pagination: initPaginationData }))
    setInputSearch('')
  }

  
  const [searchCourse, setSearchCourse] = useState('all');

  const onSearchCoursesListChange = (e) => {
    setSearchCourse(e.target.value)
    if(e.target.value === 'all') {
      dispatch(changeExchangePointsTableCourse(''))
    } else {
      dispatch(changeExchangePointsTableCourse(e.target.value))
    }
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }

  // const [categories, setCategories] = useState([ { value: 'all', key: 'الكل' } ]);
  // const [searchStatus, setSearchStatus] = useState('all');

  // const [isReqMount, setIsReqMount] = useState(false);

  // useEffect(() => {
  //   const getCategories = async () => {
  //     const { data: { results } } = await api.get('/admin/category?allWithoutImg=true')

  //     const filteredStatus = results.map(category => ({ value: category._id, key: category.ar.name }))
  //     const categoriesArr = [ { value: 'all', key: 'الكل' }, ...filteredStatus]
  //     setCategories(categoriesArr)
  //   }

  //   setIsReqMount(true)
  //   if(isReqMount) getCategories()

  // }, [isReqMount, api]);


  const [searchType, setSearchType] = useState('all');

  const typeItems = [
    { value: 'all', key: "الكل" },
    { value: 'exchange', key: "استبدال" },
    { value: 'objection', key: "أعتراض" }
  ]
 
  const onSearchTypeListChange = (e) => {
    setSearchType(e.target.value)
    if(e.target.value === 'all') {
      dispatch(changeExchangePointsTableType(''))
    } else {
      dispatch(changeExchangePointsTableType(e.target.value))
    }
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }
  

  const [searchStatus, setSearchStatus] = useState('all');

  const statusItems = [
    { value: 'all', key: "الكل" },
    { value: 'pending', key: 'قيد الأنتظار' },
    { value: 'accepted', key: "مقبول" },
    { value: 'rejected', key: "مرفوض" }
  ]

  const onSearchStatusListChange = (e) => {
    setSearchStatus(e.target.value)
    if(e.target.value === 'all') {
      dispatch(changeExchangePointsTableStatus(''))
    } else {
      dispatch(changeExchangePointsTableStatus(e.target.value))
    }
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }


  const handleSubmit = e => {
    e.preventDefault()
    console.log('clicked');

    if(inputSearch && search !== 'all'){
      let value = '';

      if(search === 'AI_Id') {
        value = isNaN(parseInt(inputSearch)) ? 0 : parseInt(inputSearch)
      } else {
        value = inputSearch
      }
      
      const data = { ...initSearchData, [search]: value }
      console.log(data, 'data');
      dispatch(changeExchangePointsTableSearch({ search: data, pagination: initPaginationData }))

      //reset other select lists
      setSearchType('all')
      setSearchStatus('all')
      setSearchCourse('all')
    }
  }
  

  return (
    <Stack direction={'row'} justifyContent={'flex-start'} justifyItems={'center'} className='overflowXScroll'>

      <Box component="form" onSubmit={handleSubmit} noValidate className='appSearchBox outlineForm'>

        <AppSelect value={search} onChange={onSearchListChange} items={searchItems} minWidth={130} label={'بحث بـ'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <InputBase sx={{ ml: 1, flex: 1, p: '10px 0' }} fullWidth onChange={(e) => setInputSearch(e.target.value)} value={inputSearch} type="text" placeholder="بحث..." startAdornment={<InputAdornment position="start" sx={{ ml: 0.5 }}><SearchIcon /></InputAdornment>} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchType} onChange={onSearchTypeListChange} items={typeItems} minWidth={120} label={'بحث بنوع الطلب'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchStatus} onChange={onSearchStatusListChange} items={statusItems} minWidth={120} label={'بحث بحالة الطلب'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchCourse} onChange={onSearchCoursesListChange} items={courses} minWidth={160} label={'بحث بالمادة'} />

        <Button sx={{display: 'none'}} type="submit">submit</Button>
      </Box>

    </Stack>
  );
}