import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { TrainerIndex } from '../../components/Dashboard/Activity/Trainer'

export const TrainerPage = () => {
  const title = usePageTitle("المدربين")

  return (
    <>
      { title }
      <div>
        <TrainerIndex />
      </div>
    </>
  )
}
