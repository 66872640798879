import React from 'react'
import { usePageTitle } from '../hooks/usePageTitle'
import { useThunk } from '../hooks/useThunk'
import { msgThunk } from '../store'

export const TestPage = () => {
  const title = usePageTitle('الصفحة غير موجودة')

  const [ runMsgThunk, isMsgThunkLoading ] = useThunk(msgThunk) 

  const homeClick = async e => {
    e.preventDefault();
    // setIsHomeDisabled(true)

    console.log('home');
    // dispatch(msgThunk({ api: axiosInstance }))
    // .unwrap()
    // .then(() => setIsHomeDisabled(false))
    // .catch(err => {
    //     console.log(err, 'msgThunk unwraped err')
    //     setIsHomeDisabled(false)
    // })

    runMsgThunk()
    console.log(isMsgThunkLoading, 'isMsgThunkLoading');
}

  return (
    <>
      { title }
      <div> TestPage </div>
      <br /><br />
        <button onClick={homeClick}>homeClick</button>
        <br /><br />
    </>
  )
}
