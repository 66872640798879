import { createAsyncThunk } from "@reduxjs/toolkit";

export const msgThunk = createAsyncThunk('msg/show', async ( { api }, { rejectWithValue }) => {

    console.log('msgThumkkk');
    try {
        const res = await api.get('/admin/accounts/me')
        return res?.data?.admin
    } catch (error) {
        console.log(error, 'msgThunk res');
        return rejectWithValue(error.response.data)
    }

})