import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ActivitiesIndex } from '../../components/Dashboard/Activity/Activities'

export const ActivitiesPage = () => {
  const title = usePageTitle("البرامج")

  return (
    <>
      { title }
      <div>
        <ActivitiesIndex />
      </div>
    </>
  )
}
