import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { decode } from 'js-base64';
import { clearNameAndChangeSlicePayload } from "../actions";

const payload = Cookies.get('payload')
const adminName = payload ? JSON.parse(decode(payload))['name'] : ''

const profileSlice = createSlice({
    name: 'profile',
    initialState:{
        name: adminName
    },
    reducers:{
        changeName(state, action){
            state.name = action.payload
        }
    },
    extraReducers(builder){
        builder.addCase(clearNameAndChangeSlicePayload, (state, action) => {
            state.name = ''
        })
    }
})

export const { changeName } = profileSlice.actions
export const profileReducer = profileSlice.reducer