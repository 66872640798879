import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeAlert } from "../store";

export const PublicApi = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {"Content-Type": "application/json", "Accept-Language": "ar"},
    withCredentials: true
});

export const PrivateApi = (api) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const abortControllerRef = useRef(new AbortController());
    api.defaults.signal = abortControllerRef.current.signal;
    
    // console.log('run1');
    useEffect(() => {
        
        // console.log('run2');
        
        // const requestIntercept = api.interceptors.request.use(
        //     config => {
        //         // if (!config.headers['Authorization']) {
        //         //     config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
        //         // }
        //         // return config;
                
        //     }, (error) => Promise.reject(error)
        // );

        const responseIntercept = api.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data

            // console.log(response, 'success response');
            return response;
        },  async (error) => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error

            // if(error && error.length > 0){
            
            const prevRequest = error?.config;
            const status = error?.response?.status
            const message = error?.response?.data?.error?.message
        
            console.log(prevRequest?.sent, 'prevRequest?.sent');
            console.log(error, error?.response?.status , error?.response?.data?.error?.message, 'interceptors err');
            
            if(status === 403) { // Forbidden

                console.log("api reLogin");
                Cookies.remove('payload')
                abortControllerRef.current.abort() // cancel any request and navigate to login page
                navigate('/login')
                return Promise.reject(error);

            } else if(status === 401 && message === 'unAuthorized') {

                console.log("unAuthorized");
                // abortControllerRef.current.abort() // cancel any request and navigate to home page
                // return navigate('/unAuthorized')
                dispatch(changeAlert({ open: true, type: 'error', msg: 'لا تملك صلاحية لتنفيذ هذا الأمر' }))
                return Promise.reject(error);

            } else if(status === 406 && message === 'account has been blocked') {

                console.log("account has been blocked");
                Cookies.remove('payload')
                abortControllerRef.current.abort() // cancel any request and navigate to block page
                navigate('/blocked')
                return Promise.reject(error);

            } else if(status === 404 && message === 'Not Found') {

                console.log("Not Found");
                abortControllerRef.current.abort() // cancel any request and navigate to NotFound page
                navigate('/NotFound')
                return Promise.reject(error);

            } else if(status >= 500){

                console.log("internal server error");
                abortControllerRef.current.abort() // cancel any request and navigate to serverError page
                navigate('/serverError')
                return Promise.reject(error);

            } else {
                return Promise.reject(error);
            }

        });

        return () => {
            // api.interceptors.request.eject(requestIntercept);
            api.interceptors.response.eject(responseIntercept);
            // abortControllerRef.current = undefined
        };

    }, [navigate, dispatch, api]);

    return api
}