import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { CategoryIndex } from '../../components/Dashboard/Activity/Category'

export const CategoryPage = () => {
  const title = usePageTitle("التصنيفات")

  return (
    <>
      { title }
      <div>
        <CategoryIndex />
      </div>
    </>
  )
}
