import { ExchangePointsTable } from './ExchangePointsTable'
import { ExchangePointsSearchBar } from './ExchangePointsSearchBar'
import { useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { PrivateApi, PublicApi } from '../../../../api/api';

import { ServerErrComponent } from '../../../ServerErrComponent';
import { useSelector } from 'react-redux';


export const ExchangePointsIndex = () => {

  const { roles } = useSelector(state => state.payload)

  const api = PrivateApi(PublicApi)

  const [courses, setCourses] = useState([ { value: 'all', key: 'الكل' } ]);

  const [isReqMount, setIsReqMount] = useState(false);
  
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {

    const getData = async () => {

      try {
      
        const { data: { results } } = await api.get('/admin/course/list')
        
        const filteredCourses = results.map(course => ({ value: course._id, key: `${course.courseName.ar} (${course.courseCode})` }))
        const coursesArr = [ { value: 'all', key: 'الكل' }, ...filteredCourses]
        setCourses(coursesArr)

        setIsDataFetched(true)

      } catch (error) {
        console.log(error);
      }

    }
    
    setIsReqMount(true)
    if(isReqMount && roles.includes('ExchangedPoints_show')) getData()

  }, [isReqMount, roles, api]);


  if(roles.includes('ExchangedPoints_show')){

    if(isDataFetched){
      return (
        <>
          <ExchangePointsSearchBar courses={courses} />
          <ExchangePointsTable roles={roles} />
        </>
      )
    } else {
      return(
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} style={{height: '55vh'}}>
          <CircularProgress />
        </Stack>
        )
    }

  } else {
    return <ServerErrComponent title={'هذا الحساب لايملك صلاحية لمشاهدة هذه الصفحة'} src={'/401.svg'} HomeBtn={'hide'} />
  }

}