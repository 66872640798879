import { useEffect, useState } from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { AppSelect } from '../../../AppSelect'
import { useDispatch } from 'react-redux';
import { changeActivitiesTableCategory, changeActivitiesTableTrainer, changeActivitiesTableSearch } from '../../../../store';
import { AddActivitiesTableModalBtn } from './AddActivitiesTableModalBtn';
// import { PrivateApi, PublicApi } from '../../../../api/api';

export const ActivitiesSearchBar = ({ categories, trainers, roles }) => {

  const dispatch = useDispatch()

  const initSearchData = { AI_Id: '', search: '', category: '' }

  const initPaginationData = { page: 0, lastPage: 0 }

  // const api = PrivateApi(PublicApi)

  const [search, setSearch] = useState('all');
  const searchItems = [
    { value: 'all', key: "الكل" },
    { value: 'AI_Id', key: "الرقم" },
    { value: 'name', key: "الأسم" }
  ]

  const [inputSearch, setInputSearch] = useState('');

  const onSearchListChange = (e) => {
    setSearch(e.target.value)
    if(e.target.value === 'all') dispatch(changeActivitiesTableSearch({ search: initSearchData, pagination: initPaginationData }))
    setInputSearch('')
  }

  const [searchCategory, setSearchCategory] = useState('all');

  const [searchTrainer, setSearchTrainer] = useState('all');

  // const [categories, setCategories] = useState([ { value: 'all', key: 'الكل' } ]);
  // const [searchCategory, setSearchCategory] = useState('all');

  // const [isReqMount, setIsReqMount] = useState(false);

  // useEffect(() => {
  //   const getCategories = async () => {
  //     const { data: { results } } = await api.get('/admin/category?allWithoutImg=true')

  //     const filteredCategory = results.map(category => ({ value: category._id, key: category.ar.name }))
  //     const categoriesArr = [ { value: 'all', key: 'الكل' }, ...filteredCategory]
  //     setCategories(categoriesArr)
  //   }

  //   setIsReqMount(true)
  //   if(isReqMount) getCategories()

  // }, [isReqMount, api]);

  const onSearchCategoriesListChange = (e) => {
    setSearchCategory(e.target.value)
    if(e.target.value === 'all') {
      dispatch(changeActivitiesTableCategory(''))
    } else {
      dispatch(changeActivitiesTableCategory(e.target.value))
    }
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }


  const onSearchTrainersListChange = (e) => {
    setSearchTrainer(e.target.value)
    if(e.target.value === 'all') {
      dispatch(changeActivitiesTableTrainer(''))
    } else {
      dispatch(changeActivitiesTableTrainer(e.target.value))
    }
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }


  const handleSubmit = e => {
    e.preventDefault()
    console.log('clicked');

    if(inputSearch && search !== 'all'){
      let value = '';

      if(search === 'AI_Id') {
        value = isNaN(parseInt(inputSearch)) ? 0 : parseInt(inputSearch)
      } else {
        value = inputSearch
      }
      
      const data = { ...initSearchData, [search]: value }
      console.log(data, 'data');
      dispatch(changeActivitiesTableSearch({ search: data, pagination: initPaginationData }))

      //reset other select lists
      setSearchCategory('all')
      setSearchTrainer('all')
    }
  }
  

  return (
    <Stack direction={'row'} justifyContent={'flex-start'} justifyItems={'center'} className='overflowXScroll'>

      <Box component="form" onSubmit={handleSubmit} noValidate className='appSearchBox outlineForm'>

        <AppSelect value={search} onChange={onSearchListChange} items={searchItems} minWidth={130} label={'بحث بـ'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <InputBase sx={{ ml: 1, flex: 1, p: '10px 0' }} fullWidth onChange={(e) => setInputSearch(e.target.value)} value={inputSearch} type="text" placeholder="بحث..." startAdornment={<InputAdornment position="start" sx={{ ml: 0.5 }}><SearchIcon /></InputAdornment>} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchCategory} onChange={onSearchCategoriesListChange} items={categories} minWidth={160} label={'بحث بالتصنيف'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchTrainer} onChange={onSearchTrainersListChange} items={trainers} minWidth={160} label={'بحث بالمدرب'} />

        <Button sx={{display: 'none'}} type="submit">submit</Button>
      </Box>

      <Stack justifyContent={'center'} justifyItems={'center'} mx={2}>
        { roles.includes('Activities_add') ? <AddActivitiesTableModalBtn /> : '' }
      </Stack>

    </Stack>
  );
}