import { createSlice } from "@reduxjs/toolkit";
import { activitiesTableThunk } from "../thunks/activitiesTableThunk";

const changedSearch = {
    AI_Id: '',
    name: '',
}

const activitiesTableSlice = createSlice({
    name: "activitiesTable",
    initialState: {
        data: [],
        search:{
            AI_Id: '',
            name: '',
            category: '',
            trainer: ''
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeActivitiesTableSearch(state, action){
            console.log('changeActivitiesTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeActivitiesTableItems(state, action){
            console.log('changeActivitiesTableItems');
            state.pagination.items = action.payload
        },
        changeActivitiesTablePage(state, action){
            console.log('changeActivitiesTablePage');
            state.pagination.page = action.payload
        },
        changeActivitiesTableSort(state, action){
            console.log('changeActivitiesTableSort');
            state.pagination.sort = action.payload
        },
        changeActivitiesTablePagination(state, action){
            console.log('changeActivitiesTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        },
        changeActivitiesTableCategory(state, action){
            console.log('changeActivitiesTableCategory');
            // state.search.region = action.payload
            state.search = { ...state.search, ...changedSearch, category: action.payload }
        },
        changeActivitiesTableTrainer(state, action){
            console.log('changeActivitiesTableTrainer');
            // state.search.region = action.payload
            state.search = { ...state.search, ...changedSearch, trainer: action.payload }
        },
    },
    extraReducers(builder){
        builder.addCase(activitiesTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'activitiesTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(activitiesTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('activitiesTableThunk.pending');
        })

        builder.addCase(activitiesTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'activitiesTableThunk.rejected');
            }
        })
    }
})

export const { changeActivitiesTableSearch, changeActivitiesTableItems, changeActivitiesTablePage, changeActivitiesTableSort, changeActivitiesTablePagination, changeActivitiesTableCategory, changeActivitiesTableTrainer } = activitiesTableSlice.actions
export const activitiesTableReducer = activitiesTableSlice.reducer