import { createSlice } from "@reduxjs/toolkit";
import { moderatorAccountsTableThunk } from "../thunks/moderatorAccountsTableThunk";

const changedSearch = {
    AI_Id: '',
    email: ''
}

const moderatorAccountsTableSlice = createSlice({
    name: "moderatorAccountsTable",
    initialState: {
        data: [],
        search:{
            AI_Id: '',
            email: '',
            block: 'all'
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeModeratorAccountsTableSearch(state, action){
            console.log('changeModeratorAccountsTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeModeratorAccountsTableBlock(state, action){
            console.log('changeModeratorAccountsTableBlock');
            // state.search.block = action.payload
            state.search = { ...state.search, ...changedSearch, block: action.payload }
        },
        changeModeratorAccountsTableItems(state, action){
            console.log('changeModeratorAccountsTableItems');
            state.pagination.items = action.payload
        },
        changeModeratorAccountsTablePage(state, action){
            console.log('changeModeratorAccountsTablePage');
            state.pagination.page = action.payload
        },
        changeModeratorAccountsTableSort(state, action){
            console.log('changeModeratorAccountsTableSort');
            state.pagination.sort = action.payload
        },
        changeModeratorAccountsTablePagination(state, action){
            console.log('changeModeratorAccountsTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        }
    },
    extraReducers(builder){
        builder.addCase(moderatorAccountsTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'moderatorAccountsTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(moderatorAccountsTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('moderatorAccountsTableThunk.pending');
        })

        builder.addCase(moderatorAccountsTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'moderatorAccountsTableThunk.rejected');
            }
        })
    }
})

export const { changeModeratorAccountsTableSearch, changeModeratorAccountsTableBlock, changeModeratorAccountsTableItems, changeModeratorAccountsTablePage, changeModeratorAccountsTableSort, changeModeratorAccountsTablePagination } = moderatorAccountsTableSlice.actions
export const moderatorAccountsTableReducer = moderatorAccountsTableSlice.reducer