import { ActivitiesTable } from './ActivitiesTable'
import { ActivitiesSearchBar } from './ActivitiesSearchBar'
import { useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { PrivateApi, PublicApi } from '../../../../api/api';

import { ServerErrComponent } from '../../../ServerErrComponent';
import { useSelector } from 'react-redux';


export const ActivitiesIndex = () => {

  const { roles } = useSelector(state => state.payload)

  const api = PrivateApi(PublicApi)

  const [categories, setCategories] = useState([ { value: 'all', key: 'الكل' } ]);

  const [trainers, setTrainers] = useState([ { value: 'all', key: 'الكل' } ]);

  const [isReqMount, setIsReqMount] = useState(false);
  
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {

    const getData = async () => {
      try {
     
        const { data: { results: cats } } = await api.get('/admin/category/list')

        const filteredCategory = cats.map(category => ({ value: category._id, key: category.name.ar }))
        const categoriesArr = [ { value: 'all', key: 'الكل' }, ...filteredCategory]
        setCategories(categoriesArr)


        const { data: { results: trains } } = await api.get('/admin/trainer/list')
        
        const filteredTrainers = trains.map(trainer => ({ value: trainer._id, key: `${trainer.scientificDegree.ar} - ${trainer.name.ar}` }))
        const trainersArr = [ { value: 'all', key: 'الكل' }, ...filteredTrainers]
        setTrainers(trainersArr)

        setIsDataFetched(true)

      } catch (error) {
        console.log(error);
      }
    }
    
    setIsReqMount(true)
    if(isReqMount && roles.includes('Activities_show')) getData()

  }, [isReqMount, roles, api]);

  if(roles.includes('Activities_show')){
    
    if(isDataFetched){
      return (
        <>
          <ActivitiesSearchBar categories={categories} trainers={trainers} roles={roles} />
          <ActivitiesTable roles={roles} />
        </>
      )
    } else {
      return(
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} style={{height: '55vh'}}>
          <CircularProgress />
        </Stack>
        )
    }

  } else {
    return <ServerErrComponent title={'هذا الحساب لايملك صلاحية لمشاهدة هذه الصفحة'} src={'/401.svg'} HomeBtn={'hide'} />
  }

}