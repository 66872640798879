import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { PrivateApi, PublicApi } from '../../../api/api';
import { AdminChangeMyInfoSchema } from '../../../validation/AdminAuth.validation';
import { useDispatch } from 'react-redux';
import { changeAlert, changeName } from '../../../store';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { AppModal } from '../../AppModal';
import { decode, encode } from 'js-base64';


export const ChangeAdminProfileMenuItem = ({ handleCloseProfileMenu }) => {

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);

  const [getDataLoading, setGetDataLoading] = useState(false);
  const [submitDataLoading, setsubmitDataLoading] = useState(false);

  const [oldEmail, setOldEmail] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');

  const handleCloseModal = () => {
    setOpenModal(false)
    setOldEmail('')
    setEmail('')
    setName('')
    setNameErr('')
    setEmailErr('')
    setGetDataLoading(false)
    setsubmitDataLoading(false)
};

  const handleOpenModal = async () => {
    handleCloseProfileMenu()
    setGetDataLoading(true)
    setOpenModal(true);

    try {
      const { data: { admin }} = await api.get('/admin/accounts/me')
      console.log(admin);

      setOldEmail(admin.email)
      setEmail(admin.email)
      setName(admin.name)

      setGetDataLoading(false)
    } catch (error) {
      console.log(error,'error error');
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء إستقبال البيانات, حاول مرة أخري' }))
      }
      handleCloseModal()
    }
    
  }

  const showErrMsg = message => {
    if(message.includes("الأسم")){
      setNameErr(message)
    } else if(message.includes("البريد الإلكتروني")){
      setEmailErr(message)
    }
  }

  const EditDataRequest = async () => {
      try {
        setsubmitDataLoading(true)

        const response = await api.patch('/admin/accounts/info/me', { name, email })

        const payload = Cookies.get('payload')

        if(payload) {
          const data = { ...JSON.parse(decode(payload)), name }
          Cookies.remove('payload')
          Cookies.set('payload', encode(JSON.stringify(data)))
        }
        
        dispatch(changeAlert({ open: true, type: 'success', msg: 'تم التعديل بنجاح' }))

        dispatch(changeName(name))

        handleCloseModal()

        if(oldEmail !== email){
          Cookies.remove('payload')
          setTimeout(() => navigate('/login', { replace: true }), 4000);
        }
        

      } catch (error) {
        console.log(error.response.data.error, 'patch error');

        setsubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }

        
      }
  }


  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          await AdminChangeMyInfoSchema.validateAsync({ name, email })
          
          EditDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  const resetErrMsgs = () => {
    setNameErr('')
    setEmailErr('')
  }

  return (
    <>
      <MenuItem onClick={handleOpenModal}>
          <EditIcon fontSize="small" color="primary" />
          <Typography variant="subtitle2" component="div" mr={1} >تعديل البيانات</Typography>
      </MenuItem>

      <AppModal title="تعديل البيانات" open={openModal} onClose={handleCloseModal} isLoading={getDataLoading} >

        <Box
          onSubmit={handleSubmit}
          component="form"
          noValidate
          sx={{ my: 2 }}
          style={{ maxWidth: '300px' }}
          
        >
          <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
              <TextField
                  fullWidth
                  error={nameErr.length > 0 && true }
                  id="outlined-error-name"
                  label="الأسم"
                  helperText={nameErr}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                    resetErrMsgs()
                  }}
              />

              <TextField
                  fullWidth
                  error={emailErr.length > 0 && true }
                  id="outlined-error-email"
                  label="البريد الإلكتروني"
                  helperText={emailErr}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    resetErrMsgs()
                  }}
              />

              <LoadingButton
                  fullWidth
                  className='loadingBtn'
                  startIcon={<EditIcon className='btnLogo' />}
                  loading={submitDataLoading}
                  loadingPosition="start"
                  variant="contained"
                  color="secondary"
                  type="submit"
              >
                  <span>تعديل</span>
              </LoadingButton>

          </Stack>
        </Box>

      </AppModal>
    </>
  );
}