import { useDispatch, useSelector } from 'react-redux';
import { changeEmail, changePassword, changeEmailErr, changePasswordErr, loginThunk, changeIsSuccess, updateRoles } from '../store';
import { useThunk } from '../hooks/useThunk';
import { usePageTitle } from '../hooks/usePageTitle'
import LoginIcon from '@mui/icons-material/Login';
import { Box, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginSchema } from '../validation/AdminAuth.validation';
import Cookies from 'js-cookie';
import { clearNameAndChangeSlicePayload } from '../store/actions';
// import { AuthContext } from '../context/AuthContext';

import { decode } from 'js-base64';


export const LoginPage = () => {

    usePageTitle('تسجيل دخول')
    
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { email, password, emailErr, passwordErr, isSuccess, slicePayload } = useSelector(state => {
        return state.login
    })
    
    // const { auth, setAuth } = useContext(AuthContext)

    const [ runLoginThunk, isLoginThunkLoading ] = useThunk(loginThunk, { email, password })

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            await LoginSchema.validateAsync({ email, password })
            runLoginThunk()
        } catch ({ message }) {
            if(message.includes("البريد الإلكتروني")){
                dispatch(changeEmailErr(message))
            } else if(message.includes("كلمة المرور")){
                dispatch(changePasswordErr(message))
            }
        }
    }
      
    useEffect(() => {

        if(isSuccess){
            if(slicePayload) {
                const roles = JSON.parse(decode(slicePayload))['roles']
                dispatch(updateRoles(roles))

                Cookies.remove('payload')
                Cookies.set('payload', slicePayload)
                dispatch(clearNameAndChangeSlicePayload())
            }
            navigate('/', { replace: true })
        }

        return () => {
            dispatch(changeIsSuccess(false))
        };
    }, [isSuccess, slicePayload, navigate, dispatch]);

  return (

    <Stack spacing={1} justifyContent={'flex-start'} alignItems={'center'} sx={{ pt: 10, background: 'rgba(0, 0, 0, 0.05)', height: '100vh'}}>
        <Box component="div" sx={{ width: '200px', height: '200px'}}>
            <img src='/nkaty.png' alt="Nkaty" title="Nkaty" loading='lazy' style={{ objectFit: 'contain', width: '100%', height: '100%'}}/>
        </Box>

        <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            style={{ margin: '20px 15px 50px', maxWidth: '300px' }}
            
        >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={emailErr.length > 0 && true }
                    id="outlined-error-email"
                    label="البريد الإلكتروني"
                    helperText={emailErr}
                    value={email}
                    onChange={(e) => {
                        dispatch(changeEmail(e.target.value));
                        dispatch(changeEmailErr(''))
                        dispatch(changePasswordErr(''))
                    }}
                />
                <TextField
                    fullWidth
                    error={passwordErr.length > 0 && true }
                    id="outlined-error-password"
                    label="كلمة المرور"
                    helperText={passwordErr}
                    value={password}
                    type="password"
                    onChange={(e) => {
                        dispatch(changePassword(e.target.value))
                        dispatch(changeEmailErr(''))
                        dispatch(changePasswordErr(''))
                    }}
                />

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<LoginIcon className='btnLogo' />}
                    loading={isLoginThunkLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>تسجيل دخول</span>
                </LoadingButton>

            </Stack>
        </Box>
    </Stack>
  )
}