const Joi = require('joi');

const AddTrainerSchema = Joi.object({
    name: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الأسم (عربي) مطلوب',
            'string.empty': 'الأسم (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الأسم (إنجليزي) مطلوب',
            'string.empty': 'الأسم (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    trainerNumber: Joi.number().greater(0).required().messages({
        'number.base': 'رقم المدرب يجب أن يكون رقمًا',
        'number.greater': 'رقم المدرب يجب أن يكون أكبر من 0',
        'any.required': 'رقم المدرب مطلوب',
    }),
    scientificDegree: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الدرجة العلمية (عربي) مطلوبة',
            'string.empty': 'الدرجة العلمية (عربي) لا يمكن أن يكون فارغة',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الدرجة العلمية (إنجليزي) مطلوبة',
            'string.empty': 'الدرجة العلمية (إنجليزي) لا يمكن أن يكون فارغة',
        })
    }
});

const EditTrainerSchema = Joi.object({
    _id: Joi.string().trim().required(),
    name: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الأسم (عربي) مطلوب',
            'string.empty': 'الأسم (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الأسم (إنجليزي) مطلوب',
            'string.empty': 'الأسم (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    trainerNumber: Joi.number().greater(0).required().messages({
        'number.base': 'رقم المدرب يجب أن يكون رقمًا',
        'number.greater': 'رقم المدرب يجب أن يكون أكبر من 0',
        'any.required': 'رقم المدرب مطلوب',
    }),
    scientificDegree: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الدرجة العلمية (عربي) مطلوبة',
            'string.empty': 'الدرجة العلمية (عربي) لا يمكن أن يكون فارغة',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الدرجة العلمية (إنجليزي) مطلوبة',
            'string.empty': 'الدرجة العلمية (إنجليزي) لا يمكن أن يكون فارغة',
        })
    }
});

module.exports = { AddTrainerSchema, EditTrainerSchema }