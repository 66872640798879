import { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment } from '@mui/material';
import { AppSelect } from '../../../../AppSelect'
import { useDispatch } from 'react-redux';
import { changeActivityStudentHistoryTableAttendance, changeActivityStudentHistoryTableSearch } from '../../../../../store';


export const ActivityStudentHistorySearchBar = () => {

  const dispatch = useDispatch()

  const initSearchData = { student_academic_number: '', attendance: 'all'}

  const initPaginationData = { page: 0, lastPage: 0 }

  const [inputSearch, setInputSearch] = useState('');

  const [search, setSearch] = useState('all');
  const searchItems = [
    { value: 'all', key: "الكل" },
    { value: 'student_academic_number', key: "الرقم الأكاديمي" }
  ]

  const [searchAttendance, setSearchAttendance] = useState('all');
  const searchAttendanceItems = [
    { value: 'all', key: "الكل" },
    { value: 'attended', key: "حضور" },
    { value: 'notAttended', key: "غياب" }
  ]

  const onSearchListChange = (e) => {
    setSearch(e.target.value)
    if(e.target.value === 'all') dispatch(changeActivityStudentHistoryTableSearch({ search: initSearchData, pagination: initPaginationData }))
    setInputSearch('')
  }

  const onSearchAttendanceListChange = (e) => {
    setSearchAttendance(e.target.value)
    dispatch(changeActivityStudentHistoryTableAttendance(e.target.value))
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }


  const handleSubmit = e => {
    e.preventDefault()
    console.log('clicked');

      if(inputSearch && search !== 'all'){

        let value = '';

        if(search === 'AI_Id') {
          value = isNaN(parseInt(inputSearch)) ? 0 : parseInt(inputSearch)
        } else {
          value = inputSearch
        }
        
        const data = { ...initSearchData, [search]: value }
        console.log(data, 'data');
        dispatch(changeActivityStudentHistoryTableSearch({ search: data, pagination: initPaginationData }))

        //reset other select lists
        setSearchAttendance('all')
      }
  
  }
  

  return (
    <div className='overflowXScroll'>
      <Box component="form" onSubmit={handleSubmit} noValidate className='appSearchBox outlineForm' style={{ maxWidth: '600px'}}>

        <AppSelect value={search} onChange={onSearchListChange} items={searchItems} minWidth={130} label={'بحث بـ'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <InputBase sx={{ ml: 1, flex: 1, p: '10px 0' }} fullWidth onChange={(e) => setInputSearch(e.target.value)} value={inputSearch} type="text" placeholder="بحث..." startAdornment={<InputAdornment position="start" sx={{ ml: 0.5 }}><SearchIcon /></InputAdornment>} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchAttendance} onChange={onSearchAttendanceListChange} items={searchAttendanceItems} minWidth={130} label={'بحث بالحضور/غياب'} />

        <Button sx={{display: 'none'}} type="submit">submit</Button>
      </Box>
    </div>
  );
}