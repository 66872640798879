import { createAsyncThunk } from "@reduxjs/toolkit";

export const facultyCategoryTableThunk = createAsyncThunk('facultyCategoryTable/getData', async ({ api, data }, { rejectWithValue }) => {

    try {
        const res = await api.get('/admin/facultyCategory', { params: data })
        console.log(res, 'facultyCategoryTableThunk succ');
        return res?.data

    } catch (error) {
        console.log(error, 'facultyCategoryTableThunk err');
        return rejectWithValue(error.response.data)
    }

})