import { createSlice } from "@reduxjs/toolkit";
import { studentAccountsTableThunk } from "../thunks/studentAccountsTableThunk";

const changedSearch = {
    AI_Id: '',
    email: '',
    mobCode: '+966',
    mobile: '',
    academic_number: '',
    earnedPoints: '',
    remainingPoints: '',
    exchangedPoints: ''
}

const studentAccountsTableSlice = createSlice({
    name: "studentAccountsTable",
    initialState: {
        data: [],
        search:{
            AI_Id: '',
            email: '',
            mobCode: '+966',
            mobile: '',
            academic_number: '',
            earnedPoints: '',
            remainingPoints: '',
            exchangedPoints: '',
            facultyCategory: '',
            block: 'all'
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeStudentAccountsTableSearch(state, action){
            console.log('changeStudentAccountsTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeStudentAccountsTableBlock(state, action){
            console.log('changeStudentAccountsTableBlock');
            // state.search.block = action.payload
            state.search = { ...state.search, ...changedSearch, block: action.payload }
        },
        changeStudentAccountsTableItems(state, action){
            console.log('changeStudentAccountsTableItems');
            state.pagination.items = action.payload
        },
        changeStudentAccountsTablePage(state, action){
            console.log('changeStudentAccountsTablePage');
            state.pagination.page = action.payload
        },
        changeStudentAccountsTableSort(state, action){
            console.log('changeStudentAccountsTableSort');
            state.pagination.sort = action.payload
        },
        changeStudentAccountsTablePagination(state, action){
            console.log('changeStudentAccountsTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        },
        changeStudentAccountsTableFacultyCategory(state, action){
            console.log('changeStudentAccountsTableFacultyCategory');
            // state.search.region = action.payload
            state.search = { ...state.search, ...changedSearch, facultyCategory: action.payload }
        },
    },
    extraReducers(builder){
        builder.addCase(studentAccountsTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'studentAccountsTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(studentAccountsTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('studentAccountsTableThunk.pending');
        })

        builder.addCase(studentAccountsTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'studentAccountsTableThunk.rejected');
            }
        })
    }
})

export const { changeStudentAccountsTableSearch, changeStudentAccountsTableBlock, changeStudentAccountsTableItems, changeStudentAccountsTablePage, changeStudentAccountsTableSort, changeStudentAccountsTablePagination, changeStudentAccountsTableFacultyCategory } = studentAccountsTableSlice.actions
export const studentAccountsTableReducer = studentAccountsTableSlice.reducer