import { createAsyncThunk } from "@reduxjs/toolkit";

export const studentAccountsReservedActivitiesTableThunk = createAsyncThunk('studentAccountsReservedActivitiesTable/getData', async ({ api, data }, { rejectWithValue }) => {

    try {
        const res = await api.get('/admin/reservedActivities', { params: data })
        console.log(res, 'studentAccountsReservedActivitiesTableThunk succ');
        return res?.data

    } catch (error) {
        console.log(error, 'studentAccountsReservedActivitiesTableThunk err');
        return rejectWithValue(error.response.data)
    }

})