const Joi = require('joi');

const AddCategorySchema = Joi.object({
    name: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الأسم (عربي) مطلوب',
            'string.empty': 'الأسم (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الأسم (إنجليزي) مطلوب',
            'string.empty': 'الأسم (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
});

const EditCategorySchema = Joi.object({
    _id: Joi.string().trim().required(),
    name: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الأسم (عربي) مطلوب',
            'string.empty': 'الأسم (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الأسم (إنجليزي) مطلوب',
            'string.empty': 'الأسم (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
});

module.exports = { AddCategorySchema, EditCategorySchema }