import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import HistoryIcon from '@mui/icons-material/History';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { AppModal } from '../../../AppModal';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Avatar, FormControl, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import { CircularProgress } from '@mui/material'

import moment from 'moment';
import 'moment/locale/ar'; 

import { changeStudentAccountsReservedActivitiesTablePage, changeStudentAccountsReservedActivitiesTableSort, changeStudentAccountsReservedActivitiesTablePagination, studentAccountsReservedActivitiesTableThunk, resetStudentAccountsReservedActivitiesTable } from '../../../../store';

const columns = [
  { id: 'img', label: 'الصورة', minWidth: 50 },
  { id: 'activity', label: 'البرنامج', minWidth: 50 },
  { id: 'category', label: 'التصنيف', minWidth: 50 },
  { id: 'trainer', label: 'المدرب', minWidth: 50},
  { id: 'points', label: 'عدد النقاط', minWidth: 50 },
  { id: 'dateTime', label: 'ميعاد البرنامج', minWidth: 50 },
  { id: 'finished', label: 'إنتهاء البرنامج', minWidth: 50 },
  { id: 'createdAt', label: 'تاريخ الحجز', minWidth: 50 },
  { id: 'attended', label: 'حضور/غياب', minWidth: 50 }
];


export const StudentAccountsReservedActivitiesTableModalBtn = ({ style, _id }) => {

  const dispatch = useDispatch()

  const api = PrivateApi(PublicApi)

  const { data: rows, pagination: {items: rowsPerPage, page, sort, lastPage}, isLoading } = useSelector( state => state.studentAccountsReservedActivitiesTable )

  const TablePaginationActions = (props) => {
   
    const { count, page, rowsPerPage, onPageChange } = props;
    
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
      dispatch(changeStudentAccountsReservedActivitiesTablePage(0))
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
      dispatch(changeStudentAccountsReservedActivitiesTablePage(page - 1))
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
      dispatch(changeStudentAccountsReservedActivitiesTablePage(page + 1))
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      dispatch(changeStudentAccountsReservedActivitiesTablePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1)))
    };
  
    return (
      <>
      <Stack sx={{ flexShrink: 0, ml: 0.5 }} direction={'row'} justifyContent={'center'} alignItems={'center'}>
        <FormControl variant="standard" sx={{ m: 1, width: 85 }} className='appSortList'>
          <Select
            inputProps={{MenuProps: {disableScrollLock: true}}}
            sx={{fontSize: '12px', border: 'unset'}}
            value={sort}
            onChange={(e) => dispatch(changeStudentAccountsReservedActivitiesTableSort(+e.target.value))}
          >
            <MenuItem value={1}>تصاعدي</MenuItem>
            <MenuItem value={-1}>تنازلي</MenuItem>
          </Select>
        </FormControl>

        <div> : ترتيب </div>
      </Stack>


      <Box sx={{ flexShrink: 0, ml: 0.5, direction: 'rtl' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <LastPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <FirstPageIcon />
        </IconButton>
        
      </Box>
      </>
    );
  }

  const labelDisplayedRows = ({ from, to, count }) => `${from}–${to} إلي ${count !== -1 ? count : `أكثر من ${to}`}`

  const handleChangePage = (event, newPage) => {
    dispatch(changeStudentAccountsReservedActivitiesTablePage(newPage))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeStudentAccountsReservedActivitiesTablePagination({ page: 0, items: +event.target.value }))
  };

  const ShowData = (row, column) => {

    if(column.id === 'img'){
      return (
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <img src={`${process.env.REACT_APP_IMG_URL}${row.activity.img}`} alt={row.activity.name.ar} title={row.activity.name.ar} loading='lazy' style={{ objectFit: 'contain', width: 40, height: 40 }} />
        </Stack>
      )
    } else if(column.id === 'activity'){
      return row.activity.name.ar
    } else if(column.id === 'category'){
      return row.activity.category.name.ar
    } else if(column.id === 'trainer'){
      return `(${row.activity.trainer.scientificDegree.ar}) ${row.activity.trainer.name.ar}`
    } else if(column.id === 'points'){
      return row.activity.points
    } else if(column.id === 'dateTime'){
      return (
        <Tooltip title={moment(row.activity.dateTime).locale('en').format('YYYY-MM-DD hh:mm:ss A')}>
         <span>{moment(row.activity.dateTime).locale('ar').fromNow()}</span>
        </Tooltip>
      )
    } else if(column.id === 'finished'){
      return (
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <Tooltip title={ row.activity.finished ? "إنتهي" : "مازال قائم"}>
            {row.activity.finished ? <CheckCircleOutlineIcon color="primary" /> : <BlockIcon color="error" /> }
          </Tooltip>
        </Stack>
      )
    } else if(column.id === 'attended'){
      return (
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <Tooltip title={ row.attended ? "حضور" : "غياب"}>
            {row.attended ? <CheckCircleOutlineIcon color="primary" /> : <BlockIcon color="error" /> }
          </Tooltip>
        </Stack>
      )
    } else if(['createdAt', 'updatedAt'].includes(column.id)){
      return (
        <Tooltip title={moment(row[column.id]).locale('en').format('YYYY-MM-DD hh:mm:ss A')}>
         <span>{moment(row[column.id]).locale('ar').fromNow()}</span>
        </Tooltip>
      )
    } else {
      return row[column.id]
    }
  
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    },
  }));

  const tableStatus = status => {
    return (
      <TableRow>
        <TableCell colSpan={columns.length} style={{height: '55vh', border: 'unset', overflow: 'hidden'}}>
          <Stack justifyContent={'center'} alignItems={'center'} spacing={1}>
            {status === 'notFound' ?
            <>
              <CommentsDisabledIcon fontSize='large' sx={{color: '#a9a9a9'}}/>
              <Typography variant="subtitle1" component={'div'}>
                لا يوجد بيانات
              </Typography> 
            </>
            : <CircularProgress /> }
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  const loadData = () => {

    if(isLoading) return tableStatus('loading')

    if(rows.length === 0) return tableStatus('notFound')

    if(rows.length > 0){
        console.log(page , rowsPerPage, 'page * rowsPerPage');
        return rows.map((row) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
              {columns.map((column) => {
                // const value = row[column.id];
                return (
                  <TableCell key={column.id} align="center" style={column.direction && {direction: column.direction}}>
                      {ShowData(row, column)}
                  </TableCell>
                );
              })}
            </TableRow>
        ))
    }

  }

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false)
    dispatch(resetStudentAccountsReservedActivitiesTable()) // reset slice values
  }

  useEffect(() => {

    if(openModal){
      const data = { student_id: _id ,page: page + 1, sort, items: rowsPerPage }

      dispatch(studentAccountsReservedActivitiesTableThunk({ api, data }))
    }

  }, [openModal, _id, page, sort, rowsPerPage, api, dispatch]);


  return (
    <>
      <Tooltip title="سجل حضور البرامج" style={{ marginLeft: '10px'}}>
        <Avatar onClick={handleOpenModal} variant="rounded" sx={style}>
            <HistoryIcon fontSize='small'/>
        </Avatar>
      </Tooltip>

      <AppModal title="سجل حضور البرامج" open={openModal} onClose={handleCloseModal} className="largeModall" size="lg">

        <Box sx={{ my: 2 }}>
          <Paper className="appTable" sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ height: '65vh' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align="center"
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadData()}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[12, 25, 50, 80]}
                component="div"
                // count={rows.length}
                count={rowsPerPage * lastPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"عدد النتائج"}
                labelDisplayedRows={labelDisplayedRows}
              />
            </Paper>
        </Box>

      </AppModal>
      
    </>
  );
}
