import { createSlice } from "@reduxjs/toolkit";
import { studentAccountsReservedActivitiesTableThunk } from "../thunks/studentAccountsReservedActivitiesTableThunk";


const studentAccountsReservedActivitiesTableSlice = createSlice({
    name: "studentAccountsReservedActivitiesTable",
    initialState: {
        data: [],
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeStudentAccountsReservedActivitiesTablePage(state, action){
            console.log('changeStudentAccountsReservedActivitiesTablePage');
            state.pagination.page = action.payload
        },
        changeStudentAccountsReservedActivitiesTableSort(state, action){
            console.log('changeStudentAccountsReservedActivitiesTableSort');
            state.pagination.sort = action.payload
        },
        changeStudentAccountsReservedActivitiesTablePagination(state, action){
            console.log('changeStudentAccountsReservedActivitiesTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        },
        resetStudentAccountsReservedActivitiesTable(state, action){
            console.log('resetStudentAccountsReservedActivitiesTable');
            return {
                data: [],
                pagination: {
                    items: 12,
                    page: 0,
                    sort: -1,
                    lastPage: 0
                },
                isLoading: false
            }
        }
    },
    extraReducers(builder){
        builder.addCase(studentAccountsReservedActivitiesTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'studentAccountsReservedActivitiesTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(studentAccountsReservedActivitiesTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('studentAccountsReservedActivitiesTableThunk.pending');
        })

        builder.addCase(studentAccountsReservedActivitiesTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'studentAccountsReservedActivitiesTableThunk.rejected');
            }
        })
    }
})

export const { changeStudentAccountsReservedActivitiesTablePage, changeStudentAccountsReservedActivitiesTableSort, changeStudentAccountsReservedActivitiesTablePagination, resetStudentAccountsReservedActivitiesTable } = studentAccountsReservedActivitiesTableSlice.actions
export const studentAccountsReservedActivitiesTableReducer = studentAccountsReservedActivitiesTableSlice.reducer