import { createSlice } from "@reduxjs/toolkit";
import { loginThunk } from "../index";
import { clearNameAndChangeSlicePayload } from "../actions";

const loginSlice = createSlice({
    name: 'login',
    initialState:{
        email: '',
        password: '',
        emailErr: '',
        passwordErr: '',
        isSuccess: false,
        slicePayload: null
    },
    reducers:{
        changeEmail(state, action){
            state.email = action.payload
        },
        changePassword(state, action){
            state.password = action.payload
        },
        changeEmailErr(state, action){
            state.emailErr = action.payload
        },
        changePasswordErr(state, action){
            state.passwordErr = action.payload
        },
        changeIsSuccess(state, action){
            state.isSuccess = action.payload
        }
    },
    extraReducers(builder){
        builder.addCase(loginThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'loginThunk.fulfilled');
            return {email: '', password: '', emailErr: '', passwordErr: '', isSuccess: true, slicePayload: action.payload.admin }
        });
        builder.addCase(loginThunk.rejected, (state, action) => {
            console.log(action.payload, 'loginThunk.rejected slice');

            if(action.payload.error){
                const { status, message } = action.payload.error
                if(status === 422){
                    if(message.includes("البريد الإلكتروني")){
                        state.emailErr = message
                    } else if(message.includes("كلمة المرور")){
                        state.passwordErr = message
                    }
                }
            }

        });

        builder.addCase(clearNameAndChangeSlicePayload, (state, action) => {
            state.slicePayload = null
        })
    }
});


export const loginReducer = loginSlice.reducer
export const { changeEmail, changePassword, changeEmailErr, changePasswordErr, changeIsSuccess } = loginSlice.actions