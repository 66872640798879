import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { CourseIndex } from '../../components/Dashboard/Course/Courses'

export const CoursePage = () => {
  const title = usePageTitle(" المواد الدراسية ")

  return (
    <>
      { title }
      <div>
        <CourseIndex />
      </div>
    </>
  )
}
