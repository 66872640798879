import { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { AddCourseSchema } from '../../../../validation/Course.validation';
import { useDispatch, useSelector } from 'react-redux';
import { courseTableThunk, changeAlert } from '../../../../store';
import { AppModal } from '../../../AppModal';

export const AddCourseTableModalBtn = () => {

  const dispatch = useDispatch()

  const { search: { AI_Id, search: sliceSearch }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.courseTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [courseNameAr, setCourseNameAr] = useState('');
  const [courseNameEn, setCourseNameEn] = useState('');
  const [courseInstructorAr, setCourseInstructorAr] = useState('');
  const [courseInstructorEn, setCourseInstructorEn] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const [courseNumber, setCourseNumber] = useState('');

  const [courseNameArErr, setCourseNameArErr] = useState('');
  const [courseNameEnErr, setCourseNameEnErr] = useState('');
  const [courseInstructorArErr, setCourseInstructorArErr] = useState('');
  const [courseInstructorEnErr, setCourseInstructorEnErr] = useState('');
  const [courseCodeErr, setCourseCodeErr] = useState('');
  const [courseNumberErr, setCourseNumberErr] = useState('');

  const handleCloseModal = () => {
    setOpenModal(false)
    setSubmitDataLoading(false)
    setCourseNameAr('')
    setCourseNameEn('')
    setCourseInstructorAr('')
    setCourseInstructorEn('')
    setCourseCode('')
    setCourseNumber('')

    setCourseNameArErr('')
    setCourseNameEnErr('')
    setCourseInstructorArErr('')
    setCourseInstructorEnErr('')
    setCourseCodeErr('')
    setCourseNumberErr('')
  };

  const handleOpenModal = () => {
    setOpenModal(true) 
  }
  
  const showErrMsg = message => {
    if(message.includes('أسم المادة (عربي)')){
      setCourseNameArErr(message)
    } else if(message.includes('أسم المادة (إنجليزي)')){
      setCourseNameEnErr(message)
    } else if(message.includes('أسم المحاضر (عربي)')){
      setCourseInstructorArErr(message)
    } else if(message.includes('أسم المحاضر (إنجليزي)')){
      setCourseInstructorEnErr(message)
    } else if(message.includes('الرقم المرجعي')){
      setCourseCodeErr(message)
    } else if(message.includes('رقم المادة')){
      setCourseNumberErr(message)
    }
  }

  const resetErrMsgs = () => {
    setCourseNameArErr('')
    setCourseNameEnErr('')
    setCourseInstructorArErr('')
    setCourseInstructorEnErr('')
    setCourseCodeErr('')
    setCourseNumberErr('')
  }

  const AddDataRequest = async () => {
      try {
        setSubmitDataLoading(true)

        const requestData = {
          courseName: {
              ar: courseNameAr,
              en: courseNameEn
          },
          courseInstructor: {
              ar: courseInstructorAr,
              en: courseInstructorEn
          },
          courseCode,
          courseNumber
        }

        const response = await api.post('/admin/course', requestData)
        console.log(response, 'add data');

        const data = { AI_Id, search: sliceSearch, page: page + 1, sort, items: rowsPerPage }
        
        dispatch(courseTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت الإضافة بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();
      
      const requestData = {
        courseName: {
            ar: courseNameAr,
            en: courseNameEn
        },
        courseInstructor: {
            ar: courseInstructorAr,
            en: courseInstructorEn
        },
        courseCode,
        courseNumber
      }
      
      try {
          await AddCourseSchema.validateAsync(requestData)
          
          AddDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }


  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant="contained" 
        size="small" 
        style={{ height: '38px', width: '150px', marginBottom: '10px'}} 
        startIcon={<AddIcon className='btnLogo' />}
        className='loadingBtn'
      >
        أضف مادة دراسية
      </Button>

      <AppModal title="إضافة مادة دراسية" open={openModal} onClose={handleCloseModal} >

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={courseNameArErr.length > 0 && true }
                    id="outlined-error-courseNameAr"
                    label='أسم المادة (عربي)'
                    helperText={courseNameArErr}
                    value={courseNameAr}
                    onChange={e => {
                      setCourseNameAr(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={courseNameEnErr.length > 0 && true }
                    id="outlined-error-courseNameEn"
                    label='أسم المادة (إنجليزي)'
                    helperText={courseNameEnErr}
                    value={courseNameEn}
                    onChange={e => {
                      setCourseNameEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseInstructorArErr.length > 0 && true }
                    id="outlined-error-courseInstructorAr"
                    label='أسم المحاضر (عربي)'
                    helperText={courseInstructorArErr}
                    value={courseInstructorAr}
                    onChange={e => {
                      setCourseInstructorAr(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseInstructorEnErr.length > 0 && true }
                    id="outlined-error-courseInstructorEn"
                    label='أسم المحاضر (إنجليزي)'
                    helperText={courseInstructorEnErr}
                    value={courseInstructorEn}
                    onChange={e => {
                      setCourseInstructorEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseCodeErr.length > 0 && true }
                    id="outlined-error-courseCode"
                    label='الرقم المرجعي'
                    helperText={courseCodeErr}
                    value={courseCode}
                    onChange={e => {
                      setCourseCode(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={courseNumberErr.length > 0 && true }
                    id="outlined-error-courseNumber"
                    label='رقم المادة'
                    helperText={courseNumberErr}
                    value={courseNumber}
                    onChange={e => {
                      setCourseNumber(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<AddIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>أضف</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
