import { CourseTable } from './CourseTable'
import { CourseSearchBar } from './CourseSearchBar'
import { ServerErrComponent } from '../../../ServerErrComponent'

import { useSelector } from 'react-redux';

export const CourseIndex = () => {

  const { roles } = useSelector(state => state.payload)

  return roles.includes('Course_show') ?
  <>
    <CourseSearchBar roles={roles} />
    <CourseTable roles={roles} />
  </> :
  <ServerErrComponent title={'هذا الحساب لايملك صلاحية لمشاهدة هذه الصفحة'} src={'/401.svg'} HomeBtn={'hide'} />
}
