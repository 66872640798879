import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

export const ConfirmModal = ({ TooltipTitle, Header, Body, orderStatus, open, handleOpen, handleClose, handleConfirmBtn, submitDataLoading }) => {

    const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title={TooltipTitle}>
        <Avatar onClick={handleOpen} variant="rounded" sx={{ width: 28, height: 28, cursor: 'pointer', bgcolor: orderStatus === 'accepted' ? theme.palette.primary.main : theme.palette.error.main }}>
            {orderStatus === 'accepted' ? <ThumbUpAltIcon fontSize='small'/> : <ThumbDownIcon fontSize='small'/> }
        </Avatar>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle style={{ fontSize: '1.1rem' }}>
          {Header}
        </DialogTitle>
        <DialogContent style={{ borderBottom: '1px solid #bdbdbd', borderTop: '1px solid #bdbdbd', paddingTop: '20px'}}>
          <DialogContentText>
            {Body}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-around', padding: '16px 20px'}}>
            <LoadingButton
                className='loadingSmallBtn'
                startIcon={<CheckIcon className='btnLogo' />}
                loading={submitDataLoading}
                loadingPosition="start"
                variant="contained"
                autoFocus
                size="small"
                onClick={handleConfirmBtn}
                style={{ marginLeft: '10px' }}
            >
                <span>تأكيد</span>
            </LoadingButton>
            <LoadingButton
                className='loadingBtn'
                startIcon={<ClearIcon className='btnLogo' />}
                loadingPosition="start"
                color="error"
                variant="contained"
                size="small"
                onClick={handleClose}
            >
                <span>إلغاء</span>
            </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}