import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ExchangePointsIndex } from '../../components/Dashboard/Student/ExchangePoints'

export const ExchangePointsPage = () => {
  const title = usePageTitle("تبديل النقاط")

  return (
    <>
      { title }
      <div>
        <ExchangePointsIndex />
      </div>
    </>
  )
}
