import { AdminIndex } from '../../components/Dashboard/Admin/index'
import { usePageTitle } from '../../hooks/usePageTitle'

export const AdminPage = () => {
  const title = usePageTitle('حسابات (المدربين)')

  return (
    <>
      { title }
      <div> 
        <AdminIndex />
      </div>
    </>
  )
}
