import { createSlice } from "@reduxjs/toolkit";
import { categoryTableThunk } from "../thunks/categoryTableThunk";

const categoryTableSlice = createSlice({
    name: "categoryTable",
    initialState: {
        data: [],
        search:{
            AI_Id: '',
            name: ''
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeCategoryTableSearch(state, action){
            console.log('changeCategoryTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeCategoryTableItems(state, action){
            console.log('changeCategoryTableItems');
            state.pagination.items = action.payload
        },
        changeCategoryTablePage(state, action){
            console.log('changeCategoryTablePage');
            state.pagination.page = action.payload
        },
        changeCategoryTableSort(state, action){
            console.log('changeCategoryTableSort');
            state.pagination.sort = action.payload
        },
        changeCategoryTablePagination(state, action){
            console.log('changeCategoryTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        }
    },
    extraReducers(builder){
        builder.addCase(categoryTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'categoryTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(categoryTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('categoryTableThunk.pending');
        })

        builder.addCase(categoryTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'categoryTableThunk.rejected');
            }
        })
    }
})

export const { changeCategoryTableSearch, changeCategoryTableItems, changeCategoryTablePage, changeCategoryTableSort, changeCategoryTablePagination } = categoryTableSlice.actions
export const categoryTableReducer = categoryTableSlice.reducer