import { createAsyncThunk } from "@reduxjs/toolkit";

export const loginThunk = createAsyncThunk('login/submit', async ({ api, data }, { rejectWithValue }) => {

    try {
        const res = await api.post('/admin/auth/login', data)
        console.log(res, 'loginThunk');
        return res?.data
        
    } catch (error) {
        console.log(error, 'loginThunk');
        return rejectWithValue(error.response.data)
    }

})