import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
    name: 'alert',
    initialState:{
        open: false,
        type: 'success',
        msg: ''
    },
    reducers:{
        changeAlert(state, action){
            return action.payload
        }
    }
})

export const { changeAlert } = alertSlice.actions

export const alertReducer = alertSlice.reducer