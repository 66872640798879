import { createSlice } from "@reduxjs/toolkit";
import { activityStudentHistoryTableThunk } from "../thunks/activityStudentHistoryTableThunk";

const changedSearch = {
    student_academic_number: ''
}

const activityStudentHistoryTableSlice = createSlice({
    name: "activityStudentHistoryTable",
    initialState: {
        data: [],
        search:{
            student_academic_number: '',
            attendance: 'all'
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeActivityStudentHistoryTableSearch(state, action){
            console.log('changeActivityStudentHistoryTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeActivityStudentHistoryTableAttendance(state, action){
            console.log('changeActivityStudentHistoryTableAttendance');
            // state.search.attendance = action.payload
            state.search = { ...state.search, ...changedSearch, attendance: action.payload }
        },
        changeActivityStudentHistoryTableItems(state, action){
            console.log('changeActivityStudentHistoryTableItems');
            state.pagination.items = action.payload
        },
        changeActivityStudentHistoryTablePage(state, action){
            console.log('changeActivityStudentHistoryTablePage');
            state.pagination.page = action.payload
        },
        changeActivityStudentHistoryTableSort(state, action){
            console.log('changeActivityStudentHistoryTableSort');
            state.pagination.sort = action.payload
        },
        changeActivityStudentHistoryTablePagination(state, action){
            console.log('changeActivityStudentHistoryTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        },
        resetActivityStudentHistoryTable(state, action){
            console.log('resetActivityStudentHistoryTable');
            return {
                data: [],
                search:{
                    student_academic_number: '',
                    attendance: 'all'
                },
                pagination: {
                    items: 12,
                    page: 0,
                    sort: -1,
                    lastPage: 0
                },
                isLoading: false
            }
        }
    },
    extraReducers(builder){
        builder.addCase(activityStudentHistoryTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'activityStudentHistoryTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(activityStudentHistoryTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('activityStudentHistoryTableThunk.pending');
        })

        builder.addCase(activityStudentHistoryTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'activityStudentHistoryTableThunk.rejected');
            }
        })
    }
})

export const { changeActivityStudentHistoryTableSearch, changeActivityStudentHistoryTableAttendance, changeActivityStudentHistoryTableItems, changeActivityStudentHistoryTablePage, changeActivityStudentHistoryTableSort, changeActivityStudentHistoryTablePagination, resetActivityStudentHistoryTable } = activityStudentHistoryTableSlice.actions
export const activityStudentHistoryTableReducer = activityStudentHistoryTableSlice.reducer