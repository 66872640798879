import { CategoryTable } from './CategoryTable'
import { CategorySearchBar } from './CategorySearchBar'

import { ServerErrComponent } from '../../../ServerErrComponent';
import { useSelector } from 'react-redux';


export const CategoryIndex = () => {

  const { roles } = useSelector(state => state.payload)

  return roles.includes('Category_show') ?
  <>
    <CategorySearchBar roles={roles} />
    <CategoryTable roles={roles} />
  </> :
  <ServerErrComponent title={'هذا الحساب لايملك صلاحية لمشاهدة هذه الصفحة'} src={'/401.svg'} HomeBtn={'hide'} />
}
