import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

export const ServerErrComponent = ({ title, src, HomeBtn }) => {
  return (
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} style={{height: '80vh'}}>
      <div style={{objectFit: 'contain', textAlign: 'center'}}>
        <img src={src} alt={title} title={title} loading='lazy' style={{ objectFit: 'contain', height: '50vh', width: '50vw'}} />
        <div style={{ marginTop: '10px'}}>{title}</div>
        { HomeBtn && HomeBtn === 'hide' ? '' : <Link to={'/'}>
          <Button variant='contained'>الرئيسية</Button>
        </Link> }
      </div>
    </Stack>
  )
}
