import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { SideMenu } from './SideMenu';
import { AppBar } from './AppBar';
import { LoadingIcon } from '../LoadingIcon'
import { Outlet, useNavigate } from "react-router-dom"
import { PrivateApi, PublicApi } from "../../api/api"
import Cookies from "js-cookie"
import { useDispatch, useSelector } from 'react-redux';
import { changeName, updateRoles } from '../../store';
import { decode } from 'js-base64';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export const DashboardIndex = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const api = PrivateApi(PublicApi)

  const { name } = useSelector(state => {
    return state.profile
  })

  const [openSideMenu, setOpenSideMenu] = useState(false);

  const handleDrawerOpen = () => {
    setOpenSideMenu(state => !state);
  };

  const content = useCallback(() => (
      <Box>
        <AppBar openSideMenu={openSideMenu} handleDrawerOpen={handleDrawerOpen} />
        <SideMenu openSideMenu={openSideMenu} />
  
        <Main open={openSideMenu} sx={{ textAlign: 'right' }}>
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    ), [openSideMenu])


    const [isReqMount, setIsReqMount] = useState(true);

  useEffect(() => {

    const getAdminPayload = async () => {
        try {

            setLoading(true)
          
            const { data: { payload } } = await api.get('/admin/auth/payload') // set payload in browser automatically
            console.log(payload, 'admin data 22');

            if(payload && payload.length > 0){

                const roles = JSON.parse(decode(payload))['roles']
                dispatch(updateRoles(roles))

                Cookies.remove('payload')
                Cookies.set('payload', payload)
                const newName = JSON.parse(decode(payload))['name']
                dispatch(changeName(newName))

                setLoading(false)
                return content()

            } else {
              setLoading(false)
              console.log('relogin getAdminPayload');
              navigate('/login', { replace: true })
            }

        } catch (error) {
            console.log(error, 'err payload msg data 22');
        }
    }
    
    if(isReqMount || !name) {
      getAdminPayload()
      setIsReqMount(false)
    }

  }, [isReqMount, name, content, api, navigate, dispatch]);

  
  if(loading) return <LoadingIcon height={'100vh'}/>

  // check if payload data found then show Outlet, else call to get payload again and show Outlet, rather than interceptor redirect it to login page
  if(name) return content()

}
