import { useState } from 'react';
import { PrivateApi, PublicApi } from '../../../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { activityStudentHistoryTableThunk, changeAlert } from '../../../../../store';
import { ConfirmModal } from '../../../../ConfirmModal';

export const ConfirmExchangeStudentAttendanceModalBtn = ({ activity_id, ReservedActivity_id, orderStatus, title }) => {

  const dispatch = useDispatch()
  
  const { search: { student_academic_number, attendance }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.activityStudentHistoryTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false)
    setSubmitDataLoading(false)
  };

  const handleOpenModal = () => {
    setOpenModal(true) 
  }

  const handleConfirmBtn = async () => {
    try {
      setSubmitDataLoading(true)
      
      const response = await api.patch('/admin/reservedActivities', { ReservedActivity_id })
      console.log(response, 'post data');
      
      const data = { activity: activity_id , student_academic_number, attendance, page: page + 1, sort, items: rowsPerPage }
      
      dispatch(activityStudentHistoryTableThunk({ api, data })) // refetch all data according to pagination records

      dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت العملية بنجاح' }))

      handleCloseModal()

    } catch (error) {
      console.log(error.response.data.error.message, 'patch error');
      setSubmitDataLoading(false)

      if(error?.response){
        const { status, message } = error.response.data.error
        if(status === 401){
          handleCloseModal()
        } else {
          dispatch(changeAlert({ open: true, type: 'error', msg: message }))
        }
      }

      handleCloseModal()
    }
  }

  return (<ConfirmModal TooltipTitle={title} Header={`عملية ${title}`} Body={`تأكيد عملية ${title} ؟`} orderStatus={orderStatus} open={openModal} handleOpen={handleOpenModal} handleClose={handleCloseModal} handleConfirmBtn={handleConfirmBtn} submitDataLoading={submitDataLoading} />);
}


