import {Drawer, Typography, Divider, Avatar, Chip, List, Stack } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PersonIcon from '@mui/icons-material/Person';
import InterestsIcon from '@mui/icons-material/Interests';
import CategoryIcon from '@mui/icons-material/Category';
import GroupIcon from '@mui/icons-material/Group';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EventIcon from '@mui/icons-material/Event';
import ArticleIcon from '@mui/icons-material/Article';

import { SideBarMenuItem } from './SideBarMenuItem';
import { NavLink } from 'react-router-dom';

import './SideMenu.css'
import Cookies from 'js-cookie';
import { decode } from 'js-base64';

const drawerWidth = 240;

export const SideMenu = ({ openSideMenu }) => {

  const payload = Cookies.get('payload')
  const adminEmail = !payload ? 'Admin@email.com' : JSON.parse(decode(payload))['email']
  const adminAI_Id = !payload ? '1' : JSON.parse(decode(payload))['AI_Id']

  const NavLinkStyle = { textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}
  const SideMenuCaptionContainerStyle = {textAlign: 'right', fontWeight: 'bold'}
  const SideMenuCaptionStyle = { marginRight: '5px', marginLeft: '5px'}

  return (
    <Drawer className="sideMenu"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={openSideMenu}
      >
        {/* <DrawerHeader sx={{ flexDirection:'row', justifyContent: "center", alignItems: 'center' }}> */}
          <Stack justifyContent="center" alignItems='center' p={1} spacing={1}>
            <Avatar alt="avatar" src="/avatar.png" loading="lazy" imgProps={{ loading: 'lazy'}} sx={{border: '1px solid #007dce' }} />
            <Typography variant="subtitle2" noWrap component="div" sx={{width: '100%', direction: 'ltr', textAlign: 'center'}}>{adminEmail}</Typography>
            <Chip label={`# ${adminAI_Id}`} color="secondary" size='small'/>
          </Stack>
        {/* </DrawerHeader> */}
        
        <Divider />

        <List>
          {/* <NavLink to="/" style={NavLinkStyle}>
            <SideBarMenuItem text={'الرئيسية'} icon={<HomeIcon color="primary" fontSize='small' />} />
          </NavLink> */}
          <NavLink to="/trainers" style={NavLinkStyle}>
            <SideBarMenuItem text={'حسابات المدربين'} icon={<AdminPanelSettingsIcon color="primary" fontSize='small' />} />
          </NavLink>
        </List>

        <Divider />
        
        <List>

          <Typography variant="caption" noWrap component="div" sx={SideMenuCaptionContainerStyle} mt={1} mb={1.5}>
            <Stack direction={'row'}>
            <PersonIcon color="secondary" fontSize='small' style={SideMenuCaptionStyle}/> <div>الطلاب</div>
            </Stack>
          </Typography>

          <NavLink to="/student/accounts" style={NavLinkStyle}>
            <SideBarMenuItem text={'الحسابات'} icon={<GroupIcon color="primary" fontSize='small' />} />
          </NavLink>
          
          <NavLink to="/student/exchangePoints" style={NavLinkStyle}>
            <SideBarMenuItem text={'تبديل نقاط'} icon={<EventNoteIcon color="primary" fontSize='small' />} />
          </NavLink>

        </List>

        {/* <Divider />

        <List>

          <Typography variant="caption" noWrap component="div" sx={SideMenuCaptionContainerStyle} mt={1} mb={1.5}>
            <Stack direction={'row'}>
            <PersonIcon color="secondary" fontSize='small' style={SideMenuCaptionStyle}/> <div>المشرفين</div>
            </Stack>
          </Typography>

          <NavLink to="/moderator/accounts" style={NavLinkStyle}>
            <SideBarMenuItem text={'الحسابات'} icon={<GroupIcon color="primary" fontSize='small' />} />
          </NavLink>
          
        </List> */}

      <Divider />

        <List>
          <NavLink to="/facultyCategory" style={NavLinkStyle}>
            <SideBarMenuItem text={'الأقسام'} icon={<WidgetsIcon color="primary" fontSize='small' />} />
          </NavLink>
        </List>

        <Divider />
        
        <List>

          <Typography variant="caption" noWrap component="div" sx={SideMenuCaptionContainerStyle} mt={1} mb={1.5}>
            <Stack direction={'row'}>
            <ChecklistIcon color="secondary" fontSize='small' style={SideMenuCaptionStyle}/> <div>البرامج</div>
            </Stack>
          </Typography>
          
          <NavLink to="/activity/trainer" style={NavLinkStyle}>
            <SideBarMenuItem text={'المدربين'} icon={<GroupIcon color="primary" fontSize='small' />} />
          </NavLink>

          <NavLink to="/activity/category" style={NavLinkStyle}>
            <SideBarMenuItem text={'التصنيفات'} icon={<InterestsIcon color="primary" fontSize='small' />} />
          </NavLink>

          <NavLink to="/activity/activities" style={NavLinkStyle}>
            <SideBarMenuItem text={'البرامج'} icon={<EventIcon color="primary" fontSize='small' />} />
          </NavLink>

        </List>

        <Divider />

        

        <List>
        
          <Typography variant="caption" noWrap component="div" sx={SideMenuCaptionContainerStyle} mt={1} mb={1.5}>
            <Stack direction={'row'}>
            <ArticleIcon color="secondary" fontSize='small' style={SideMenuCaptionStyle}/> <div>الكورسات</div>
            </Stack>
          </Typography>

          <NavLink to="/course/courses" style={NavLinkStyle}>
            <SideBarMenuItem text={'المواد الدراسية'} icon={<CategoryIcon color="primary" fontSize='small' />} />
          </NavLink>
          
        </List>

        <Divider />

      </Drawer>
  )
}
