import { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment } from '@mui/material';
import { AppSelect } from '../../../AppSelect'
import { useDispatch } from 'react-redux';
import { changeStudentAccountsTableBlock, changeStudentAccountsTableSearch, changeStudentAccountsTableFacultyCategory } from '../../../../store';


export const StudentAccountsSearchBar = ({ categories }) => {

  const dispatch = useDispatch()
  
  const initSearchData = { AI_Id: '', email: '', mobCode: '+966', mobile: '', academic_number: '', earnedPoints: '', remainingPoints: '', exchangedPoints: '', facultyCategory: '', block: 'all'}

  const initPaginationData = { page: 0, lastPage: 0 }

  const [inputSearch, setInputSearch] = useState('');

  const [search, setSearch] = useState('all');
  const searchItems = [
    { value: 'all', key: "الكل" },
    { value: 'AI_Id', key: "الرقم" },
    { value: 'academic_number', key: "الرقم الأكاديمي" },
    { value: 'earnedPoints', key: "النقاط المكتسبة" },
    { value: 'remainingPoints', key: "النقاط المتبقية" },
    { value: 'exchangedPoints', key: "النقاط المستبدلة" },
    { value: 'mobile', key: " الجوال (966+)" },
    { value: 'email', key: "البريد الإلكتروني" }
  ]

  const [searchBlock, setSearchBlock] = useState('all');
  const searchBlockItems = [
    { value: 'all', key: "الكل" },
    { value: 'blocked', key: "محظور" },
    { value: 'notBlocked', key: "غير محظور" }
  ]

  const [searchCategory, setSearchCategory] = useState('all');

  const onSearchCategoriesListChange = (e) => {
    setSearchCategory(e.target.value)
    if(e.target.value === 'all') {
      dispatch(changeStudentAccountsTableFacultyCategory(''))
    } else {
      dispatch(changeStudentAccountsTableFacultyCategory(e.target.value))
    }
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }

  const onSearchListChange = (e) => {
    setSearch(e.target.value)
    if(e.target.value === 'all') dispatch(changeStudentAccountsTableSearch({ search: initSearchData, pagination: initPaginationData }))
    setInputSearch('')
  }

  const onSearchBlockListChange = (e) => {
    setSearchBlock(e.target.value)
    dispatch(changeStudentAccountsTableBlock(e.target.value))
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }


  const handleSubmit = e => {
    e.preventDefault()
    console.log('clicked');

      if(inputSearch && search !== 'all'){

        let value = '';

        if(search === 'AI_Id') {
          value = isNaN(parseInt(inputSearch)) ? 0 : parseInt(inputSearch)
        } else {
          value = inputSearch
        }
        
        const data = { ...initSearchData, [search]: value }
        console.log(data, 'data');
        dispatch(changeStudentAccountsTableSearch({ search: data, pagination: initPaginationData }))

        //reset other select lists
        setSearchBlock('all')
        setSearchCategory('all')
      }
  
  }
  

  return (
    <div className='overflowXScroll'>
      <Box component="form" onSubmit={handleSubmit} noValidate className='appSearchBox outlineForm' style={{ maxWidth: '800px'}}>

        <AppSelect value={search} onChange={onSearchListChange} items={searchItems} minWidth={130} label={'بحث بـ'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <InputBase sx={{ ml: 1, flex: 1, p: '10px 0' }} fullWidth onChange={(e) => setInputSearch(e.target.value)} value={inputSearch} type="text" placeholder="بحث..." startAdornment={<InputAdornment position="start" sx={{ ml: 0.5 }}><SearchIcon /></InputAdornment>} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchCategory} onChange={onSearchCategoriesListChange} items={categories} minWidth={190} label={'بحث بالقسم'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchBlock} onChange={onSearchBlockListChange} items={searchBlockItems} minWidth={130} label={'بحث بالحظر'} />

        <Button sx={{display: 'none'}} type="submit">submit</Button>
      </Box>
    </div>
  );
}