import { createSlice } from "@reduxjs/toolkit";
// import Cookies from "js-cookie";
// import { decode } from 'js-base64';
// import { changeRolesSlicePayload } from "../actions";

// const payload = Cookies.get('payload')
// const roles = payload ? JSON.parse(decode(payload))['roles'] : []

const payloadSlice = createSlice({
    name: 'payload',
    initialState:{
        roles: []
    },
    reducers:{
        updateRoles(state, action){
            state.roles = action.payload
        }
    },
    // extraReducers(builder){
    //     builder.addCase(changeRolesSlicePayload, (state, action) => {
    //         state.roles = []
    //     })
    // }
})

export const { updateRoles } = payloadSlice.actions
export const payloadReducer = payloadSlice.reducer