import { useState } from 'react';
import { PrivateApi, PublicApi } from '../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { adminTableThunk, changeAlert } from '../../../store';
import { DeleteModal } from '../../DeleteModal';

export const DeleteAdminTableModalBtn = ({ _id }) => {

  const dispatch = useDispatch()

  const { search: { AI_Id, search: sliceSearch }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.adminTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false)
    setSubmitDataLoading(false)
  };

  const handleOpenModal = () => {
    setOpenModal(true) 
  }

  const handleDeleteBtn = async () => {
    try {
      setSubmitDataLoading(true)
      
      const response = await api.delete('/admin/accounts', { data: { _id } })
      console.log(response, 'patch data');

      const data = { AI_Id, search: sliceSearch, page: page + 1, sort, items: rowsPerPage }
      dispatch(adminTableThunk({ api, data })) // refetch all data according to pagination records

      dispatch(changeAlert({ open: true, type: 'success', msg: 'تم الحذف بنجاح' }))

      handleCloseModal()

    } catch (error) {
      console.log(error.response.data.error, 'patch error');
      setSubmitDataLoading(false)
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء الحذف, حاول مرة أخري' }))
      }
      handleCloseModal()
    }
  }

  return (<DeleteModal open={openModal} handleOpen={handleOpenModal} handleClose={handleCloseModal} handleDeleteBtn={handleDeleteBtn} submitDataLoading={submitDataLoading} />);
}
