import { useState } from 'react';
import Box from '@mui/material/Box';
import axios from "axios";
import { Autocomplete, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { AddActivitiesSchema } from '../../../../validation/Activities.validation';
import { useDispatch, useSelector } from 'react-redux';
import { activitiesTableThunk, changeAlert } from '../../../../store';
import { AppModal } from '../../../AppModal';
import { AddDropZone } from '../../../DropZone';
import { AppSelect } from '../../../AppSelect';
import { ErrParagragh } from '../../../ErrParagragh';
import { CustomInputLabel } from '../../../CustomInputLabel';
import { DatePickerContainer } from '../../../DatePickerContainer';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';

export const AddActivitiesTableModalBtn = () => {

  const dispatch = useDispatch()

  const { search: { AI_Id, search: sliceSearch, category: sliceCategory, trainer: sliceTrainer }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.activitiesTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);

  const [getDataLoading, setGetDataLoading] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [nameAr, setNameAr] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [location, setLocation] = useState('');
  const [points, setPoints] = useState('');
  const [maxStudents, seMaxStudents] = useState('');

  // const [dateTime, setDateTime] = useState(moment('2023-12-31T23:59:59').locale('en').format('YYYY-MM-DD hh:mm:ss A'));
  const [dateTime, setDateTime] = useState(moment().add(2, 'days')); // moment('2023-12-31T23:50')
  const [dateTimeErr, setDateTimeErr] = useState('');

  const [selectCategory, setSelectCategory] = useState('');
  const [selectCategoryItems, setSelectCategoryItems] = useState([]);

  const [selectTrainer, setSelectTrainer] = useState('');
  const [selectTrainerItems, setSelectTrainerItems] = useState([]);

  const [selectMaxStudentsItems, setSelectMaxStudentsItems] = useState('infinite')
  const [maxStudentsItems, setMaxStudentsItems] = useState([
      { value: 'infinite', key: "عدد لا نهائي" },
      { value: 'custom', key: "عدد محدد" }
    ]);

  const [filesErr, setFilesErr] = useState('');
  const [nameArErr, setNameArErr] = useState('');
  const [nameEnErr, setNameEnErr] = useState('');
  const [descriptionArErr, setDescriptionArErr] = useState('');
  const [descriptionEnErr, setDescriptionEnErr] = useState('');
  const [locationErr, setLocationErr] = useState('');
  const [pointsErr, setPointsErr] = useState('');
  const [maxStudentsErr, seMaxStudentsErr] = useState('');

  const [selectCategoryErr, setSelectCategoryErr] = useState('');
  const [selectTrainerErr, setSelectTrainerErr] = useState('');


  const handleCloseModal = () => {
    setOpenModal(false)
    setGetDataLoading(false)
    setSubmitDataLoading(false)
    setSelectMaxStudentsItems('infinite')
    setFiles([])
    setNameAr('')
    setNameEn('')
    setDescriptionAr('')
    setDescriptionEn('')
    setLocation('')
    setPoints('')
    seMaxStudents('')
    setDateTime(moment().add(2, 'days'));
    setDateTimeErr('');
    // setSelectCategory('');
    // setSelectCategoryItems([]);
    // setSelectTrainer('');
    // setSelectTrainerItems([]);
    setFilesErr('');
    setNameArErr('');
    setNameEnErr('');
    setDescriptionArErr('');
    setDescriptionEnErr('');
    setLocationErr('');
    setPointsErr('');
    seMaxStudentsErr('');
    setSelectCategoryErr('');
    setSelectTrainerErr('');
  };

  const handleOpenModal = async () => {
    setGetDataLoading(true)
    setOpenModal(true) 

    try {
      const { data: { results: cats }} = await api.get(`/admin/category/list`)

      if(cats.length > 0){

        const filteredCategories = cats.map(category => ({ value: category._id, label: category.name.ar }))
        setSelectCategoryItems(filteredCategories)
        setGetDataLoading(false)
      } else {
        setGetDataLoading(false)
        dispatch(changeAlert({ open: true, type: 'error', msg: 'يجب إضافة تصنيفات أولاً' }))
        handleCloseModal()
      }


      const { data: { results: trains }} = await api.get(`/admin/trainer/list`)
      
      if(trains.length > 0){

        const filteredTrainers = trains.map(trainer => ({ value: trainer._id, label: `${trainer.scientificDegree.ar} - ${trainer.name.ar}` }))
        setSelectTrainerItems(filteredTrainers)
        setGetDataLoading(false)
      } else {
        setGetDataLoading(false)
        dispatch(changeAlert({ open: true, type: 'error', msg: 'يجب إضافة مدربين أولاً' }))
        handleCloseModal()
      }
      
    } catch (error) {
      console.log(error,'error error');
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء إستقبال البيانات, حاول مرة أخري' }))
      }
      
      handleCloseModal()
    }

  }
  
  const showErrMsg = message => {
    if(message.includes("الأسم (عربي)")){
      setNameArErr(message)
    } else if(message.includes("الأسم (إنجليزي)")){
      setNameEnErr(message)
    } else if(message.includes("الوصف (عربي)")){
      setDescriptionArErr(message)
    } else if(message.includes("الوصف (إنجليزي)")){
      setDescriptionEnErr(message)
    } else if(message.includes("عدد الحضور")){
      seMaxStudentsErr(message)
    } else if(message.includes("المكان")){
      setLocationErr(message)
    } else if(message.includes("عدد النقاط")){
      setPointsErr(message)
    } else if(message.includes("صورة البرنامج")){
      setFilesErr(message)
    } else if(message.includes("التاريخ و الوقت")){
      setDateTimeErr(message)
    } else if(message.includes("التصنيف")){
      setSelectCategoryErr(message)
    } else if(message.includes("المدرب")){
      setSelectTrainerErr(message)
    }
  }

  const resetErrMsgs = () => {
    setNameArErr('')
    setNameEnErr('')
    setDescriptionArErr('')
    setDescriptionEnErr('')
    setLocationErr('')
    setPointsErr('')
    seMaxStudentsErr('')
    setFilesErr('')
    setDateTimeErr('')
    setSelectCategoryErr('')
    setSelectTrainerErr('')
  }

  const onUploadChange = acceptedFiles => {
    console.log(acceptedFiles, 'acceptedFiles');
    setFiles(acceptedFiles)
    resetErrMsgs()
  }

  const AddActivitiesRequest = async () => {
      try {
        setSubmitDataLoading(true)
        
        const activitiesData = {
          name: {
            ar: nameAr,
            en: nameEn
          },
          description: {
              ar: descriptionAr,
              en: descriptionEn
          },
          category: selectCategory,
          trainer: selectTrainer,
          dateTime: dateTime.locale('en').format('YYYY-MM-DD HH:mm').trim().replace(' ', 'T') + ':00',
          location, points,
          maxStudents: selectMaxStudentsItems === 'infinite' ? 0 : maxStudents
        }

        const response = await api.post('/admin/activities?status=v1', activitiesData)
        console.log(response, 'patch data');

        if(response.data.msg === 'valid'){

          const file = files[0]
          const filename = file['name']
          const filetype = file['type']

          const { data: {fields, url} } = await api.post(`/admin/upload`, { 
              folderName: 'activitiesImages',
              fileName: filename,
              ContentType: filetype
          })

          console.log(fields, url,'ssss', fields.key);

          const data2 = {
              ...fields,
              "Content-Type": filetype,
              file
          }

          const formData = new FormData();
          for( const name in data2) {
              formData.append(name, data2[name])
          }

          const response = await axios.post(url, formData)
          console.log(response, 'response');

          if (response.status === 204){
            activitiesData.img= fields.key //"activitiesImages2/8850ed60-6101-11ed-bf23-ebd4845df321.jpeg"
            
            const createActivities = await api.post('/admin/activities?status=v2', activitiesData)
            console.log(createActivities, 'createActivities');

            const data = { AI_Id, search: sliceSearch, category: sliceCategory, trainer: sliceTrainer, page: page + 1, sort, items: rowsPerPage }
            dispatch(activitiesTableThunk({ api, data })) // refetch all data according to pagination records

            dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت الإضافة بنجاح' }))

            handleCloseModal()
          }
        }

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          if(error?.response?.data?.error){
            const { status, message } = error.response.data.error
            if(status === 422) {
              showErrMsg(message)
            } else if(status === 401){
              handleCloseModal()
            }
          }else{ //s3 error
            dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء إرسال البيانات, حاول مرة أخري' }))
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();
      
      try {
          const activitiesData = {
            name: {
              ar: nameAr,
              en: nameEn
            },
            description: {
                ar: descriptionAr,
                en: descriptionEn
            },
            category: selectCategory,
            trainer: selectTrainer,
            dateTime: dateTime.locale('en').format('YYYY-MM-DD HH:mm').trim().replace(' ', 'T') + ':00',
            location, points,
            maxStudents: selectMaxStudentsItems === 'infinite' ? 0 : maxStudents,
            img: files
          }

          await AddActivitiesSchema.validateAsync(activitiesData)
          
          AddActivitiesRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }


  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant="contained" 
        size="small" 
        style={{ height: '38px', width: '120px'}} 
        startIcon={<AddIcon className='btnLogo' />}
        className='loadingBtn'
        >
        أضف برنامج
      </Button>

      <AppModal title="إضافة برنامج" open={openModal} onClose={handleCloseModal} isLoading={getDataLoading}>

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>

                <TextField
                    fullWidth
                    error={nameArErr.length > 0 && true }
                    id="outlined-error-nameAr"
                    label="الأسم (عربي)"
                    helperText={nameArErr}
                    value={nameAr}
                    onChange={e => {
                      setNameAr(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={nameEnErr.length > 0 && true }
                    id="outlined-error-nameEn"
                    label="الأسم (إنجليزي)"
                    helperText={nameEnErr}
                    value={nameEn}
                    onChange={e => {
                      setNameEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                  fullWidth
                  error={descriptionArErr.length > 0 && true }
                  id="outlined-error-descriptionAr"
                  label="الوصف (عربي)"
                  type="text"
                  helperText={descriptionArErr}
                  value={descriptionAr}
                  multiline
                  rows={4}
                  onChange={e => {
                    setDescriptionAr(e.target.value)
                    resetErrMsgs()
                  }}
                />

                <TextField
                  fullWidth
                  error={descriptionEnErr.length > 0 && true }
                  id="outlined-error-descriptionEn"
                  label="الوصف (إنجليزي)"
                  type="text"
                  helperText={descriptionEnErr}
                  value={descriptionEn}
                  multiline
                  rows={4}
                  onChange={e => {
                    setDescriptionEn(e.target.value)
                    resetErrMsgs()
                  }}
                />

                <Autocomplete
                  disablePortal
                  disableClearable
                  fullWidth
                  id="categoryList"
                  options={selectCategoryItems}
                  renderInput={(params) => <TextField {...params} label="التصنيف" />}
                  onChange={(e, value) => {
                    console.log(value, 'valuevalue');
                    setSelectCategory(value.value)
                    resetErrMsgs()
                  }}
                />
                <ErrParagragh errMsg={selectCategoryErr} />

                <Autocomplete
                  disablePortal
                  disableClearable
                  fullWidth
                  id="trainerList"
                  options={selectTrainerItems}
                  renderInput={(params) => <TextField {...params} label="المدرب" />}
                  onChange={(e, value) => {
                    console.log(value, 'valuevalue');
                    setSelectTrainer(value.value)
                    resetErrMsgs()
                  }}
                />
                <ErrParagragh errMsg={selectTrainerErr} />


                <CustomInputLabel label={'صورة البرنامج:'} />
                
                <AddDropZone sizeInMB={3} fileslimit={1} onUploadChange={onUploadChange}/>
                <ErrParagragh errMsg={filesErr} />

                <span style={{ width: '100%'}}>
                  <DatePickerContainer>
                    <DemoContainer components={['DateTimePicker']}>
                      <DateTimePicker
                          label="التاريخ و الوقت"
                          value={dateTime}
                          onChange={(newValue) => {
                            console.log(newValue._i, 'newValue');
                            setDateTime(newValue)
                            resetErrMsgs()
                          }}
                          views={['year', 'day', 'hours', 'minutes']}
                          format="YYYY/MM/DD hh:mm A"
                          disablePast
                      />
                    </DemoContainer>
                  </DatePickerContainer>
                  <ErrParagragh errMsg={dateTimeErr} />
                </span>

                <AppSelect 
                  value={selectMaxStudentsItems}
                  items={maxStudentsItems}
                  minWidth={'100%'}
                  label={"عدد الحضور"}
                  variant="outlined"
                  // ErrorMsg={discountTypeErr}
                  onChange={async (e) => {
                    setSelectMaxStudentsItems(e.target.value)
                    resetErrMsgs()
                  }}
                />

                {selectMaxStudentsItems === 'custom' && <TextField
                    fullWidth
                    error={maxStudentsErr.length > 0 && true }
                    id="outlined-error-maxStudents"
                    label="عدد الحضور"
                    helperText={maxStudentsErr}
                    value={maxStudents}
                    onChange={e => {
                      seMaxStudents(e.target.value)
                      resetErrMsgs()
                    }}
                />}

                <TextField
                    fullWidth
                    error={locationErr.length > 0 && true }
                    id="outlined-error-location"
                    label="المكان"
                    helperText={locationErr}
                    value={location}
                    onChange={e => {
                      setLocation(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={pointsErr.length > 0 && true }
                    id="outlined-error-points"
                    label="عدد النقاط"
                    helperText={pointsErr}
                    value={points}
                    onChange={e => {
                      setPoints(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<AddIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>أضف</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
