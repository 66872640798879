import Joi from 'joi';

export const AdminRegisterSchema = Joi.object({
    name: Joi.string().trim().required().messages({
        'any.required': 'الأسم مطلوب',
        'string.empty': 'الأسم لا يمكن أن يكون فارغًا',
    }),
    email: Joi.string().trim().email({ tlds: { allow: false } }).lowercase().required().messages({
        'any.required': 'البريد الإلكتروني مطلوب',
        'string.empty': 'البريد الإلكتروني لا يمكن أن يكون فارغًا',
        'string.email': 'البريد الإلكتروني يجب أن يكون صالحًا',
    }),
    password: Joi.string().trim().min(8)
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~])[a-zA-Z0-9-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~]+$')).required()
    .messages({
        'string.pattern.base': 'كلمة المرور يجب أن تحتوي علي الأقل على حرف كبير، وحرف صغير، ورقم، وحرف خاص',
        'string.min': 'كلمة المرور يجب أن تحتوي على الأقل 8 أحرف',
        'any.required': 'كلمة المرور مطلوبة',
        'string.empty': 'كلمة المرور لا يمكن أن يكون فارغة'
    }),
    re_password: Joi.string().trim().valid(Joi.ref('password')).required().messages({
      'any.only': 'تأكيد كلمة المرور يجب أن تكون متطابقة',
      'any.required': 'تأكيد كلمة المرور مطلوب',
      'string.empty': 'تأكيد كلمة المرور لا يمكن أن يكون فارغًا',
    }),
    roles: Joi.array().items(Joi.string().trim().allow('')).messages({
        'array.items': 'الصلاحيات يجب أن تحتوي على عناصر صالحة',
    }),
});

export const AdminAuthEditSchema = Joi.object({
    _id: Joi.string().trim().required(),
    name: Joi.string().trim().required().messages({
        'any.required': 'الأسم مطلوب',
        'string.empty': 'الأسم لا يمكن أن يكون فارغًا',
    }),
    email: Joi.string().trim().email({ tlds: { allow: false } }).lowercase().required().messages({
        'any.required': 'البريد الإلكتروني مطلوب',
        'string.empty': 'البريد الإلكتروني لا يمكن أن يكون فارغًا',
        'string.email': 'البريد الإلكتروني يجب أن يكون صالحًا',
    }),
    password: Joi.string().trim().min(8)
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~])[a-zA-Z0-9-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~]+$')).allow('')
    .messages({
        'string.pattern.base': 'كلمة المرور يجب أن تحتوي علي الأقل على حرف كبير، وحرف صغير، ورقم، وحرف خاص',
        'string.min': 'كلمة المرور يجب أن تحتوي على الأقل 8 أحرف',
        'any.required': 'كلمة المرور مطلوبة',
        'string.empty': 'كلمة المرور لا يمكن أن يكون فارغة'
    }),
    re_password: Joi.string().trim().valid(Joi.ref('password')).messages({
        'any.only': 'تأكيد كلمة المرور يجب أن تكون متطابقة'
    }),
    roles: Joi.array().items(Joi.string().trim().allow('')).messages({
        'array.items': 'الصلاحيات يجب أن تحتوي على عناصر صالحة',
    }),
    block: Joi.boolean().required().messages({
        'any.required': 'حالة الحظر مطلوبة',
    })
});

export const AdminChangeMyInfoSchema = Joi.object({
    name: Joi.string().trim().required().messages({
        'any.required': 'الأسم مطلوب',
        'string.empty': 'الأسم لا يمكن أن يكون فارغًا',
    }),
    email: Joi.string().trim().email({ tlds: { allow: false } }).lowercase().required().messages({
        'any.required': 'البريد الإلكتروني مطلوب',
        'string.empty': 'البريد الإلكتروني لا يمكن أن يكون فارغًا',
        'string.email': 'البريد الإلكتروني يجب أن يكون صالحًا',
    })
});

export const LoginSchema = Joi.object({
    email: Joi.string().trim().email({ tlds: { allow: false } }).lowercase().required().messages({
        'any.required': 'البريد الإلكتروني مطلوب',
        'string.empty': 'البريد الإلكتروني لا يمكن أن يكون فارغًا',
        'string.email': 'البريد الإلكتروني يجب أن يكون صالحًا',
    }),
    password: Joi.string().trim().min(8)
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~])[a-zA-Z0-9-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~]+$')).required()
    .messages({
        'string.pattern.base': 'كلمة المرور يجب أن تحتوي علي الأقل على حرف كبير، وحرف صغير، ورقم، وحرف خاص',
        'string.min': 'كلمة المرور يجب أن تحتوي على الأقل 8 أحرف',
        'any.required': 'كلمة المرور مطلوبة',
        'string.empty': 'كلمة المرور لا يمكن أن يكون فارغة'
    })
})

export const ChangeMyPasswordSchema = Joi.object({
    current_password: Joi.string().trim().min(8)
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~])[a-zA-Z0-9-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~]+$')).required()
    .messages({
        'string.pattern.base': 'كلمة المرور الحالية يجب أن تحتوي علي الأقل على حرف كبير، وحرف صغير، ورقم، وحرف خاص',
        'string.min': 'كلمة المرور الحالية يجب أن تحتوي على الأقل 8 أحرف',
        'any.required': 'كلمة المرور الحالية مطلوبة',
        'string.empty': 'كلمة المرور الحالية لا يمكن أن يكون فارغة'
    }),
    password: Joi.string().trim().min(8)
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~])[a-zA-Z0-9-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~]+$')).required()
    .messages({
        'string.pattern.base': 'كلمة المرور يجب أن تحتوي علي الأقل على حرف كبير، وحرف صغير، ورقم، وحرف خاص',
        'string.min': 'كلمة المرور يجب أن تحتوي على الأقل 8 أحرف',
        'any.required': 'كلمة المرور مطلوبة',
        'string.empty': 'كلمة المرور لا يمكن أن يكون فارغة'
    }),
    re_password: Joi.string().trim().valid(Joi.ref('password')).required().messages({
      'any.only': 'تأكيد كلمة المرور يجب أن تكون متطابقة',
      'any.required': 'تأكيد كلمة المرور مطلوب',
      'string.empty': 'تأكيد كلمة المرور لا يمكن أن يكون فارغًا',
    })
});