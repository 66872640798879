const Joi = require('joi');

const AddActivitiesSchema = Joi.object({
    name: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الأسم (عربي) مطلوب',
            'string.empty': 'الأسم (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الأسم (إنجليزي) مطلوب',
            'string.empty': 'الأسم (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    description: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الوصف (عربي) مطلوب',
            'string.empty': 'الوصف (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الوصف (إنجليزي) مطلوب',
            'string.empty': 'الوصف (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    category: Joi.string().trim().required().messages({
        'any.required': 'التصنيف مطلوب',
        'string.empty': 'التصنيف لا يمكن أن يكون فارغًا',
    }), 
    trainer: Joi.string().trim().required().messages({
        'any.required': 'المدرب مطلوب',
        'string.empty': 'المدرب لا يمكن أن يكون فارغًا',
    }),
    img: Joi.array().min(1).required().messages({
        'array.base': 'صورة البرنامج الفرعي مطلوبة',
        'array.empty': 'صورة البرنامج الفرعي لا يمكن أن تكون فارغة',
        'any.required': 'صورة البرنامج الفرعي مطلوبة',
        'array.min': 'صورة البرنامج الفرعي مطلوبة',
    }),
    dateTime: Joi.date().iso().greater('now').required().messages({ // dateTime: '2023-12-31T23:59:59', // Ensure this is in ISO format and greater than now
        'any.required': 'التاريخ و الوقت مطلوب',
        'date.base': 'التاريخ و الوقت يجب أن يكون تاريخًا صحيحًا',
        'date.greater': 'التاريخ و الوقت يجب أن يكون في المستقبل',
    }),
    location: Joi.string().trim().required().messages({
        'any.required': 'المكان مطلوب',
        'string.empty': 'المكان لا يمكن أن يكون فارغًا',
    }),
    points: Joi.number().greater(0).required().messages({
        'number.base': 'عدد النقاط يجب أن يكون رقمًا',
        'number.greater': 'عدد النقاط يجب أن يكون أكبر من 0',
        'any.required': 'عدد النقاط مطلوب'
    }),
    maxStudents: Joi.number().integer().min(0).required().messages({
        'number.base': 'عدد الحضور يجب أن تكون رقمًا',
        'number.min': 'أقل عدد الحضور يجب أن تكون 0',
        'number.integer': 'يجب أن يكون عدد الحضور عددًا صحيحًا',
        'any.required': 'عدد الحضور مطلوب'
    })
});

const EditActivitiesSchema = Joi.object({
    _id: Joi.string().trim().required(),
    name: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الأسم (عربي) مطلوب',
            'string.empty': 'الأسم (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الأسم (إنجليزي) مطلوب',
            'string.empty': 'الأسم (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    description: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'الوصف (عربي) مطلوب',
            'string.empty': 'الوصف (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'الوصف (إنجليزي) مطلوب',
            'string.empty': 'الوصف (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    category: Joi.string().trim().required().messages({
        'any.required': 'التصنيف مطلوب',
        'string.empty': 'التصنيف لا يمكن أن يكون فارغًا',
    }), 
    trainer: Joi.string().trim().required().messages({
        'any.required': 'المدرب مطلوب',
        'string.empty': 'المدرب لا يمكن أن يكون فارغًا',
    }),
    // img: Joi.string().trim().allow('').messages({
    //     'string.empty': 'صورة البرنامج لا يمكن أن تكون فارغة',
    // }),
    dateTime: Joi.date().iso().greater('now').required().messages({ // dateTime: '2023-12-31T23:59:59', // Ensure this is in ISO format and greater than now
        'any.required': 'التاريخ و الوقت مطلوب',
        'date.base': 'التاريخ و الوقت يجب أن يكون تاريخًا صحيحًا',
        'date.greater': 'التاريخ و الوقت يجب أن يكون في المستقبل',
    }),
    location: Joi.string().trim().required().messages({
        'any.required': 'المكان مطلوب',
        'string.empty': 'المكان لا يمكن أن يكون فارغًا',
    }),
    points: Joi.number().greater(0).required().messages({
        'number.base': 'عدد النقاط يجب أن يكون رقمًا',
        'number.greater': 'عدد النقاط يجب أن يكون أكبر من 0',
        'any.required': 'عدد النقاط مطلوب'
    }),
    maxStudents: Joi.number().integer().min(0).required().messages({
        'number.base': 'عدد الحضور يجب أن تكون رقمًا',
        'number.min': 'أقل عدد الحضور يجب أن تكون 0',
        'number.integer': 'يجب أن يكون عدد الحضور عددًا صحيحًا',
        'any.required': 'عدد الحضور مطلوب'
    })
});

module.exports = { AddActivitiesSchema, EditActivitiesSchema }