import { createSlice } from "@reduxjs/toolkit";
import { trainerTableThunk } from "../thunks/trainerTableThunk";

const trainerTableSlice = createSlice({
    name: "trainerTable",
    initialState: {
        data: [],
        search:{
            AI_Id: '',
            name: '',
            trainerNumber: '',
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeTrainerTableSearch(state, action){
            console.log('changeTrainerTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeTrainerTableItems(state, action){
            console.log('changeTrainerTableItems');
            state.pagination.items = action.payload
        },
        changeTrainerTablePage(state, action){
            console.log('changeTrainerTablePage');
            state.pagination.page = action.payload
        },
        changeTrainerTableSort(state, action){
            console.log('changeTrainerTableSort');
            state.pagination.sort = action.payload
        },
        changeTrainerTablePagination(state, action){
            console.log('changeTrainerTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        }
    },
    extraReducers(builder){
        builder.addCase(trainerTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'trainerTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(trainerTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('trainerTableThunk.pending');
        })

        builder.addCase(trainerTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'trainerTableThunk.rejected');
            }
        })
    }
})

export const { changeTrainerTableSearch, changeTrainerTableItems, changeTrainerTablePage, changeTrainerTableSort, changeTrainerTablePagination } = trainerTableSlice.actions
export const trainerTableReducer = trainerTableSlice.reducer