import { configureStore } from "@reduxjs/toolkit";
import { loginReducer, changeEmail, changePassword, changeEmailErr, changePasswordErr, changeIsSuccess } from "./slices/loginSlice";
import { msgReducer } from "./slices/msgSlice";
import { profileReducer, changeName } from "./slices/profileSlice";
import { payloadReducer, updateRoles } from "./slices/payloadSlice";
import { changeAlert, alertReducer } from "./slices/alertSlice";
import { adminTableReducer, changeAdminTableSearch, changeAdminTableItems, changeAdminTablePage, changeAdminTableSort, changeAdminTablePagination } from "./slices/adminTableSlice";


import { studentAccountsTableReducer, changeStudentAccountsTableSearch, changeStudentAccountsTableBlock, changeStudentAccountsTableItems, changeStudentAccountsTablePage, changeStudentAccountsTableSort, changeStudentAccountsTablePagination, changeStudentAccountsTableFacultyCategory } from "./slices/studentAccountsTableSlice";
import { studentAccountsReservedActivitiesTableReducer, changeStudentAccountsReservedActivitiesTablePage, changeStudentAccountsReservedActivitiesTableSort, changeStudentAccountsReservedActivitiesTablePagination, resetStudentAccountsReservedActivitiesTable } from "./slices/studentAccountsReservedActivitiesTableSlice";
import { moderatorAccountsTableReducer, changeModeratorAccountsTableSearch, changeModeratorAccountsTableBlock, changeModeratorAccountsTableItems, changeModeratorAccountsTablePage, changeModeratorAccountsTableSort, changeModeratorAccountsTablePagination } from "./slices/moderatorAccountsTableSlice";
import { courseTableReducer, changeCourseTableSearch, changeCourseTableItems, changeCourseTablePage, changeCourseTableSort, changeCourseTablePagination } from "./slices/courseTableSlice";
import { trainerTableReducer, changeTrainerTableSearch, changeTrainerTableItems, changeTrainerTablePage, changeTrainerTableSort, changeTrainerTablePagination } from "./slices/trainerTableSlice";
import { categoryTableReducer, changeCategoryTableSearch, changeCategoryTableItems, changeCategoryTablePage, changeCategoryTableSort, changeCategoryTablePagination } from "./slices/categoryTableSlice";
import { activitiesTableReducer, changeActivitiesTableSearch, changeActivitiesTableItems, changeActivitiesTablePage, changeActivitiesTableSort, changeActivitiesTablePagination, changeActivitiesTableCategory, changeActivitiesTableTrainer } from "./slices/activitiesTableSlice";
import { activityStudentHistoryTableReducer, changeActivityStudentHistoryTableSearch, changeActivityStudentHistoryTableAttendance, changeActivityStudentHistoryTableItems, changeActivityStudentHistoryTablePage, changeActivityStudentHistoryTableSort, changeActivityStudentHistoryTablePagination, resetActivityStudentHistoryTable } from "./slices/activityStudentHistoryTableSlice";
import { exchangePointsTableReducer, changeExchangePointsTableSearch, changeExchangePointsTableItems, changeExchangePointsTablePage, changeExchangePointsTableSort, changeExchangePointsTablePagination, changeExchangePointsTableType, changeExchangePointsTableStatus, changeExchangePointsTableCourse  } from "./slices/exchangePointsTableSlice";
import { facultyCategoryTableReducer, changeFacultyCategoryTableSearch, changeFacultyCategoryTableItems, changeFacultyCategoryTablePage, changeFacultyCategoryTableSort, changeFacultyCategoryTablePagination } from "./slices/facultyCategoryTableSlice";

const store = configureStore({
    reducer: {
        login: loginReducer,
        msg: msgReducer,
        profile: profileReducer,
        payload: payloadReducer,
        alert: alertReducer,
        adminTable: adminTableReducer,


        studentAccountsTable: studentAccountsTableReducer,
        studentAccountsReservedActivitiesTable: studentAccountsReservedActivitiesTableReducer,
        moderatorAccountsTable: moderatorAccountsTableReducer,
        courseTable: courseTableReducer,
        trainerTable: trainerTableReducer,
        categoryTable: categoryTableReducer,
        activitiesTable: activitiesTableReducer,
        activityStudentHistoryTable: activityStudentHistoryTableReducer,
        exchangePointsTable: exchangePointsTableReducer,
        facultyCategoryTable: facultyCategoryTableReducer,
    }
});


export { store, changeEmail, changePassword, changeEmailErr, changePasswordErr, changeIsSuccess, changeName, updateRoles, changeAlert, 
    changeAdminTableSearch, changeAdminTableItems, changeAdminTablePage, changeAdminTableSort, changeAdminTablePagination,


    changeStudentAccountsTableSearch, changeStudentAccountsTableBlock, changeStudentAccountsTableItems, changeStudentAccountsTablePage, changeStudentAccountsTableSort, changeStudentAccountsTablePagination, changeStudentAccountsTableFacultyCategory,
    changeStudentAccountsReservedActivitiesTablePage, changeStudentAccountsReservedActivitiesTableSort, changeStudentAccountsReservedActivitiesTablePagination, resetStudentAccountsReservedActivitiesTable,
    changeModeratorAccountsTableSearch, changeModeratorAccountsTableBlock, changeModeratorAccountsTableItems, changeModeratorAccountsTablePage, changeModeratorAccountsTableSort, changeModeratorAccountsTablePagination,
    changeCourseTableSearch, changeCourseTableItems, changeCourseTablePage, changeCourseTableSort, changeCourseTablePagination,
    changeTrainerTableSearch, changeTrainerTableItems, changeTrainerTablePage, changeTrainerTableSort, changeTrainerTablePagination,
    changeCategoryTableSearch, changeCategoryTableItems, changeCategoryTablePage, changeCategoryTableSort, changeCategoryTablePagination,
    changeActivitiesTableSearch, changeActivitiesTableItems, changeActivitiesTablePage, changeActivitiesTableSort, changeActivitiesTablePagination, changeActivitiesTableCategory, changeActivitiesTableTrainer,
    changeActivityStudentHistoryTableSearch, changeActivityStudentHistoryTableAttendance, changeActivityStudentHistoryTableItems, changeActivityStudentHistoryTablePage, changeActivityStudentHistoryTableSort, changeActivityStudentHistoryTablePagination, resetActivityStudentHistoryTable,
    changeExchangePointsTableSearch, changeExchangePointsTableItems, changeExchangePointsTablePage, changeExchangePointsTableSort, changeExchangePointsTablePagination, changeExchangePointsTableType, changeExchangePointsTableStatus, changeExchangePointsTableCourse,
    changeFacultyCategoryTableSearch, changeFacultyCategoryTableItems, changeFacultyCategoryTablePage, changeFacultyCategoryTableSort, changeFacultyCategoryTablePagination,
 }

export * from './thunks/loginThunk';
export * from './thunks/msgThunk';
export * from './thunks/adminTableThunk';


export * from './thunks/studentAccountsTableThunk';
export * from './thunks/studentAccountsReservedActivitiesTableThunk';
export * from './thunks/moderatorAccountsTableThunk';
export * from './thunks/courseTableThunk';
export * from './thunks/trainerTableThunk';
export * from './thunks/categoryTableThunk';
export * from './thunks/activitiesTableThunk';
export * from './thunks/activityStudentHistoryTableThunk';
export * from './thunks/exchangePointsTableThunk';
export * from './thunks/facultyCategoryTableThunk';