import { createSlice } from "@reduxjs/toolkit";
import { exchangePointsTableThunk } from "../thunks/exchangePointsTableThunk";

const changedSearch = {
    student_academic_number: '',
}

const exchangePointsTableSlice = createSlice({
    name: "exchangePointsTable",
    initialState: {
        data: [],
        search:{
            student_academic_number: '',
            type: '',
            status: '',
            course: ''
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeExchangePointsTableSearch(state, action){
            console.log('changeExchangePointsTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeExchangePointsTableItems(state, action){
            console.log('changeExchangePointsTableItems');
            state.pagination.items = action.payload
        },
        changeExchangePointsTablePage(state, action){
            console.log('changeExchangePointsTablePage');
            state.pagination.page = action.payload
        },
        changeExchangePointsTableSort(state, action){
            console.log('changeExchangePointsTableSort');
            state.pagination.sort = action.payload
        },
        changeExchangePointsTablePagination(state, action){
            console.log('changeExchangePointsTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        },
        changeExchangePointsTableType(state, action){
            console.log('changeExchangePointsTableType');
            // state.search.region = action.payload
            state.search = { ...state.search, ...changedSearch, type: action.payload }
        },
        changeExchangePointsTableStatus(state, action){
            console.log('changeExchangePointsTableStatus');
            // state.search.region = action.payload
            state.search = { ...state.search, ...changedSearch, status: action.payload }
        },
        changeExchangePointsTableCourse(state, action){
            console.log('changeExchangePointsTableCourse');
            // state.search.region = action.payload
            state.search = { ...state.search, ...changedSearch, course: action.payload }
        },
    },
    extraReducers(builder){
        builder.addCase(exchangePointsTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'exchangePointsTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(exchangePointsTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('exchangePointsTableThunk.pending');
        })

        builder.addCase(exchangePointsTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'exchangePointsTableThunk.rejected');
            }
        })
    }
})

export const { changeExchangePointsTableSearch, changeExchangePointsTableItems, changeExchangePointsTablePage, changeExchangePointsTableSort, changeExchangePointsTablePagination, changeExchangePointsTableType, changeExchangePointsTableStatus, changeExchangePointsTableCourse } = exchangePointsTableSlice.actions
export const exchangePointsTableReducer = exchangePointsTableSlice.reducer