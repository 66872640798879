import { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { AddTrainerSchema } from '../../../../validation/Trainer.validation';
import { useDispatch, useSelector } from 'react-redux';
import { trainerTableThunk, changeAlert } from '../../../../store';
import { AppModal } from '../../../AppModal';

export const AddTrainerTableModalBtn = () => {

  const dispatch = useDispatch()

  const { search: { AI_Id, search: sliceSearch }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.trainerTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [trainerNameAr, setTrainerNameAr] = useState('');
  const [trainerNameEn, setTrainerNameEn] = useState('');
  const [trainerScientificDegreeAr, setTrainerScientificDegreeAr] = useState('');
  const [trainerScientificDegreeEn, setTrainerScientificDegreeEn] = useState('');
  const [trainerNumber, setTrainerNumber] = useState('');

  const [trainerNameArErr, setTrainerNameArErr] = useState('');
  const [trainerNameEnErr, setTrainerNameEnErr] = useState('');
  const [trainerScientificDegreeArErr, setTrainerScientificDegreeArErr] = useState('');
  const [trainerScientificDegreeEnErr, setTrainerScientificDegreeEnErr] = useState('');
  const [trainerNumberErr, setTrainerNumberErr] = useState('');

  const handleCloseModal = () => {
    setOpenModal(false)
    setSubmitDataLoading(false)
    setTrainerNameAr('')
    setTrainerNameEn('')
    setTrainerScientificDegreeAr('')
    setTrainerScientificDegreeEn('')
    setTrainerNumber('')

    setTrainerNameArErr('')
    setTrainerNameEnErr('')
    setTrainerScientificDegreeArErr('')
    setTrainerScientificDegreeEnErr('')
    setTrainerNumberErr('')
  };

  const handleOpenModal = () => {
    setOpenModal(true) 
  }
  
  const showErrMsg = message => {
    if(message.includes('الأسم (عربي)')){
      setTrainerNameArErr(message)
    } else if(message.includes('الأسم (إنجليزي)')){
      setTrainerNameEnErr(message)
    } else if(message.includes('الدرجة العلمية (عربي)')){
      setTrainerScientificDegreeArErr(message)
    } else if(message.includes('الدرجة العلمية (إنجليزي)')){
      setTrainerScientificDegreeEnErr(message)
    } else if(message.includes('رقم المدرب')){
      setTrainerNumberErr(message)
    }
  }

  const resetErrMsgs = () => {
    setTrainerNameArErr('')
    setTrainerNameEnErr('')
    setTrainerScientificDegreeArErr('')
    setTrainerScientificDegreeEnErr('')
    setTrainerNumberErr('')
  }

  const AddDataRequest = async () => {
      try {
        setSubmitDataLoading(true)

        const requestData = {
          name: {
              ar: trainerNameAr,
              en: trainerNameEn
          },
          scientificDegree: {
              ar: trainerScientificDegreeAr,
              en: trainerScientificDegreeEn
          },
          trainerNumber
        }

        const response = await api.post('/admin/trainer', requestData)
        console.log(response, 'add data');

        const data = { AI_Id, search: sliceSearch, page: page + 1, sort, items: rowsPerPage }
        
        dispatch(trainerTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت الإضافة بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();
      
      const requestData = {
        name: {
            ar: trainerNameAr,
            en: trainerNameEn
        },
        scientificDegree: {
            ar: trainerScientificDegreeAr,
            en: trainerScientificDegreeEn
        },
        trainerNumber
      }
      
      try {
          await AddTrainerSchema.validateAsync(requestData)
          
          AddDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }


  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant="contained" 
        size="small" 
        style={{ height: '38px', width: '120px'}} 
        startIcon={<AddIcon className='btnLogo' />}
        className='loadingBtn'
      >
        أضف مدرب
      </Button>

      <AppModal title="إضافة مدرب" open={openModal} onClose={handleCloseModal} >

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={trainerNameArErr.length > 0 && true }
                    id="outlined-error-trainerNameAr"
                    label='الأسم (عربي)'
                    helperText={trainerNameArErr}
                    value={trainerNameAr}
                    onChange={e => {
                      setTrainerNameAr(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={trainerNameEnErr.length > 0 && true }
                    id="outlined-error-trainerNameEn"
                    label='الأسم (إنجليزي)'
                    helperText={trainerNameEnErr}
                    value={trainerNameEn}
                    onChange={e => {
                      setTrainerNameEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={trainerScientificDegreeArErr.length > 0 && true }
                    id="outlined-error-trainerScientificDegreeAr"
                    label='الدرجة العلمية (عربي)'
                    helperText={trainerScientificDegreeArErr}
                    value={trainerScientificDegreeAr}
                    onChange={e => {
                      setTrainerScientificDegreeAr(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={trainerScientificDegreeEnErr.length > 0 && true }
                    id="outlined-error-trainerScientificDegreeEn"
                    label='الدرجة العلمية (إنجليزي)'
                    helperText={trainerScientificDegreeEnErr}
                    value={trainerScientificDegreeEn}
                    onChange={e => {
                      setTrainerScientificDegreeEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <TextField
                    fullWidth
                    error={trainerNumberErr.length > 0 && true }
                    id="outlined-error-trainerNumber"
                    label='رقم المدرب'
                    helperText={trainerNumberErr}
                    value={trainerNumber}
                    onChange={e => {
                      setTrainerNumber(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<AddIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>أضف</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
