import { AdminTable } from './AdminTable'
import { AdminSearchBar } from './AdminSearchBar'

import { ServerErrComponent } from '../../ServerErrComponent';
import { useSelector } from 'react-redux';

export const AdminIndex = () => {

  const { roles } = useSelector(state => state.payload)

  return roles.includes('Admin_show') ?
      <>
        <AdminSearchBar roles={roles} />
        <AdminTable roles={roles} />
      </> :
      <ServerErrComponent title={'هذا الحساب لايملك صلاحية لمشاهدة هذه الصفحة'} src={'/401.svg'} HomeBtn={'hide'} />
}
