import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeAlert } from '../store';

export const AppAlert = () => {

  const { open, type, msg } = useSelector(state => {
    return state.alert
  })

  const dispatch = useDispatch()

  // const handleClick = () => {
  //   dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت العملية بنجاح' }))
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(changeAlert({ open: false, type, msg }))
  };

  return (
    <div>
      {/* <Button onClick={handleClick} variant='contained'>AppAlert</Button> */}

      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} className="appAlert"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>

        <Alert variant="filled" severity={type} sx={{ width: '100%' }}>
          { msg }
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
        
      </Snackbar>
    </div>
  );
}