import { TrainerTable } from './TrainerTable'
import { TrainerSearchBar } from './TrainerSearchBar'

import { ServerErrComponent } from '../../../ServerErrComponent';
import { useSelector } from 'react-redux';

export const TrainerIndex = () => {

  const { roles } = useSelector(state => state.payload)

  return roles.includes('Trainer_show') ?
  <>
    <TrainerSearchBar roles={roles} />
    <TrainerTable roles={roles} />
  </> :
  <ServerErrComponent title={'هذا الحساب لايملك صلاحية لمشاهدة هذه الصفحة'} src={'/401.svg'} HomeBtn={'hide'} />

}
