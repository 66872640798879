import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { FacultyCategoryIndex } from '../../components/Dashboard/FacultyCategory'

export const FacultyCategoryPage = () => {
  const title = usePageTitle("الأقسام")

  return (
    <>
      { title }
      <div>
        <FacultyCategoryIndex />
      </div>
    </>
  )
}
