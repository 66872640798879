import { useState } from 'react';
import Box from '@mui/material/Box';

import { Avatar, FormControlLabel, InputAdornment, Stack, Switch, TextField, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { PrivateApi, PublicApi } from '../../../api/api';
import { EditFacultyCategorySchema } from '../../../validation/FacultyCategory.validation';
import { useDispatch, useSelector } from 'react-redux';
import { facultyCategoryTableThunk, changeAlert } from '../../../store';
import { AppModal } from '../../AppModal';

export const EditFacultyCategoryModalBtn = ({ style, _id }) => {

  const dispatch = useDispatch()
  
  const { search: { AI_Id, name: sliceName }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.facultyCategoryTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);

  const [getDataLoading, setGetDataLoading] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [facultyCategoryNameAr, setFacultyCategoryNameAr] = useState('');
  const [facultyCategoryNameEn, setFacultyCategoryNameEn] = useState('');

  const [facultyCategoryNameArErr, setFacultyCategoryNameArErr] = useState('');
  const [facultyCategoryNameEnErr, setFacultyCategoryNameEnErr] = useState('');

  const handleCloseModal = () => {
    setOpenModal(false)
    setGetDataLoading(false)
    setSubmitDataLoading(false)

    setFacultyCategoryNameAr('')
    setFacultyCategoryNameEn('')

    setFacultyCategoryNameArErr('')
    setFacultyCategoryNameEnErr('')
  };

  const handleOpenModal = async () => {
    setGetDataLoading(true)
    setOpenModal(true);

    try {
      const { data: { facultyCategory }} = await api.get(`/admin/facultyCategory?_id=${_id}`)
      console.log(facultyCategory);
      
      setFacultyCategoryNameAr(facultyCategory.name.ar)
      setFacultyCategoryNameEn(facultyCategory.name.en)

      setGetDataLoading(false)
      
    } catch (error) {
      console.log(error,'error error');
      if(error.response.data.error.status !== 401){
        dispatch(changeAlert({ open: true, type: 'error', msg: 'حدث خطأ أثناء إستقبال البيانات, حاول مرة أخري' }))
      }
      handleCloseModal()
    }
    
  }

  const showErrMsg = message => {
    if(message.includes('الأسم (عربي)')){
      setFacultyCategoryNameArErr(message)
    } else if(message.includes('الأسم (إنجليزي)')){
      setFacultyCategoryNameEnErr(message)
    }
  }

  const EditDataRequest = async () => {
      try {
        setSubmitDataLoading(true)

        const requestData = {
          _id,
          name: {
            ar: facultyCategoryNameAr,
            en: facultyCategoryNameEn
          }
        }

        const response = await api.patch('/admin/facultyCategory', requestData)
        console.log(response, 'patch data');

        const data = { AI_Id, name: sliceName, page: page + 1, sort, items: rowsPerPage }
        dispatch(facultyCategoryTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تم التعديل بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();

      const requestData = {
        _id,
        name: {
          ar: facultyCategoryNameAr,
          en: facultyCategoryNameEn
        }
      }

      try {
          await EditFacultyCategorySchema.validateAsync(requestData)
          
          EditDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  const resetErrMsgs = () => {
    setFacultyCategoryNameArErr('')
    setFacultyCategoryNameEnErr('')
  }

  return (
    <>
      <Tooltip title="تعديل" style={{ margin: '0 10px' }}>
        <Avatar onClick={handleOpenModal} variant="rounded" sx={style}>
            <EditIcon fontSize='small'/>
        </Avatar>
      </Tooltip>

      <AppModal title="تعديل البيانات" open={openModal} onClose={handleCloseModal} isLoading={getDataLoading} >

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={facultyCategoryNameArErr.length > 0 && true }
                    id="outlined-error-facultyCategoryNameAr"
                    label='الأسم (عربي)'
                    helperText={facultyCategoryNameArErr}
                    value={facultyCategoryNameAr}
                    onChange={e => {
                      setFacultyCategoryNameAr(e.target.value)
                      resetErrMsgs()
                    }}
                />
                
                <TextField
                    fullWidth
                    error={facultyCategoryNameEnErr.length > 0 && true }
                    id="outlined-error-facultyCategoryNameEn"
                    label='الأسم (إنجليزي)'
                    helperText={facultyCategoryNameEnErr}
                    value={facultyCategoryNameEn}
                    onChange={e => {
                      setFacultyCategoryNameEn(e.target.value)
                      resetErrMsgs()
                    }}
                />

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<EditIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>تعديل</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
