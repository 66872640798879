import { Menu, MenuItem, Box, Typography, IconButton, Avatar } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { PrivateApi, PublicApi } from '../../api/api'
import { useNavigate } from 'react-router-dom';
import { ChangeAdminProfileMenuItem } from './ProfileList/ChangeAdminProfileMenuItem';
import { useSelector } from 'react-redux';
import { ChangeAdminPasswordMenuItem } from './ProfileList/ChangeAdminPasswordMenuItem';



export const ProfileList = () => {

    const navigate = useNavigate()

    const { name } = useSelector(state => {
        return state.profile
    })
    

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenProfileMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseProfileMenu = () => {
        setAnchorElUser(null);
    };

    const api = PrivateApi(PublicApi)

    const handleLogout = async () => {
        try {
            console.log('logout relogin');
            setAnchorElUser(null);
            await api.delete('/admin/auth/logout')
            Cookies.remove('payload')
            navigate('/login')
        } catch (error) {
          console.log(error);
        }
    }

  return (
    <Box>
        <IconButton onClick={handleOpenProfileMenu} sx={{ p: 0 }} disableRipple>
            <Avatar alt="avatar" src="/avatar.png" loading="lazy" imgProps={{ loading: 'lazy'}} sx={{mr: 2.5}} />
            <Typography variant="subtitle2" noWrap component="div" sx={{ mr: 1, width: '120px', unicodeBidi: 'plaintext', fontWeight: 'bold', textAlign: 'right'}} color="white" >{name}</Typography>
        </IconButton>
        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseProfileMenu}
        >
            
            <ChangeAdminProfileMenuItem handleCloseProfileMenu={handleCloseProfileMenu} />

            <ChangeAdminPasswordMenuItem handleCloseProfileMenu={handleCloseProfileMenu} />

            <MenuItem onClick={handleLogout}>
                <LogoutIcon fontSize="small" color="primary" />
                <Typography variant="subtitle2" component="div" mr={1}>تسجيل الخروج</Typography>
            </MenuItem>

        </Menu>
    </Box>
  )
}
