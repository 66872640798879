import { createAsyncThunk } from "@reduxjs/toolkit";

export const courseTableThunk = createAsyncThunk('courseTable/getData', async ({ api, data }, { rejectWithValue }) => {

    try {
        const res = await api.get('/admin/course', { params: data })
        console.log(res, 'courseTableThunk succ');
        return res?.data

    } catch (error) {
        console.log(error, 'courseTableThunk err');
        return rejectWithValue(error.response.data)
    }

})