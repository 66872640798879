import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef } from "react";
import { LoadingIcon } from "./LoadingIcon";

export const AppModal = ({ title, open, onClose, isLoading, children, className, size }) => {

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null)  descriptionElement.focus();
    }
  }, [open]);


  return (
    <>
    <Dialog
        id="modalId"
        open={open}
        onClose={onClose}
        scroll={'body'}
        className={className}
        fullWidth={ size ? true : false }
        maxWidth={ size ? size : '' }
      >
        <DialogTitle id="dialog-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
          {title}

          <IconButton onClick={onClose} sx={{ color: (theme) => theme.palette.grey[500], marginTop: '-3px', marginLeft: '-12px'}}>
            <CloseIcon />
          </IconButton>

        </DialogTitle>
        
        { isLoading ? <LoadingIcon height={'75px'}/> : 
        <>
          <DialogContent dividers={true} ref={descriptionElementRef}>

          {children}
          
          </DialogContent>
        </>
      }

    </Dialog>
    </>
  )
}
