// import logo from './logo.svg';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { DashboardIndex } from './components/Dashboard';
import { HomePage } from './Pages/HomePage'
import { AdminPage } from './Pages/Admin/AdminPage'


import { LoginPage } from './Pages/LoginPage'
import { NotFoundPage } from './Pages/NotFoundPage'
import { BlockedPage } from './Pages/BlockedPage'
import { UnAuthorizedPage } from './Pages/UnAuthorizedPage'
import { ServerErrorPage } from './Pages/ServerErrorPage'

import { TestPage } from './Pages/TestPage' 
import { IsAuth } from './hooks/IsAuth';
import { AppAlert } from './components/AppAlert';


import { StudentAccountsPage } from './Pages/Student/StudentAccountsPage'
import { ExchangePointsPage } from './Pages/Student/ExchangePointsPage'

import { FacultyCategoryPage } from './Pages/FacultyCategory/FacultyCategoryPage'

import { ModeratorAccountsPage } from './Pages/Moderator/ModeratorAccountsPage'
import { CoursePage } from './Pages/Course/CoursePage'

import { ActivitiesPage } from './Pages/Activity/ActivitiesPage'
import { TrainerPage } from './Pages/Activity/TrainerPage'
import { CategoryPage } from './Pages/Activity/CategoryPage'
              
              
function App() {
  return (
    <div className="App">

      <Routes>

            <Route element={<DashboardIndex />}>

              {/* <Route path="/" element={<HomePage />} /> */}
              <Route path="/trainers" element={<AdminPage />} />

              <Route path="/" element={<Navigate to="/student/accounts" />} />

              <Route path="/student">
                <Route index element={<StudentAccountsPage />} />
                <Route path="accounts" element={<StudentAccountsPage />} />
                <Route path="exchangePoints" element={<ExchangePointsPage />} />
              </Route>

              {/* <Route path="/moderator">
                <Route index element={<ModeratorAccountsPage />} />
                <Route path="accounts" element={<ModeratorAccountsPage />} />
              </Route> */}

              <Route path="facultyCategory" element={<FacultyCategoryPage />} />
              
              <Route path="/course">
                <Route index element={<CoursePage />} />
                <Route path="courses" element={<CoursePage />} />
              </Route>

              <Route path="/activity">
                <Route index element={<ActivitiesPage />} />
                <Route path="activities" element={<ActivitiesPage />} />
                <Route path="trainer" element={<TrainerPage />} />
                <Route path="category" element={<CategoryPage />} />
              </Route>

            </Route>

          <Route path="/test" element={<TestPage />}/>   
          
          <Route element={<IsAuth />}>
            <Route path="/login" element={<LoginPage />}/>
          </Route>

          <Route path="/blocked" element={<BlockedPage />} />
          <Route path="/serverError" element={<ServerErrorPage />} />
          <Route path="/unAuthorized" element={<UnAuthorizedPage />} />
          
          <Route path="*" element={<NotFoundPage />} />

      </Routes>

      <AppAlert />
    </div>
  );
}

export default App;
