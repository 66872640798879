const Joi = require('joi');

const AddCourseSchema = Joi.object({
    courseName: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'أسم المادة (عربي) مطلوب',
            'string.empty': 'أسم المادة (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'أسم المادة (إنجليزي) مطلوب',
            'string.empty': 'أسم المادة (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    courseInstructor: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'أسم المحاضر (عربي) مطلوب',
            'string.empty': 'أسم المحاضر (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'أسم المحاضر (إنجليزي) مطلوب',
            'string.empty': 'أسم المحاضر (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    courseCode: Joi.string().trim().lowercase().required().messages({
        'any.required': 'الرقم المرجعي مطلوب',
        'string.empty': 'الرقم المرجعي لا يمكن أن يكون فارغًا',
    }),
    courseNumber: Joi.number().greater(0).required().messages({
        'number.base': 'رقم المادة يجب أن يكون رقمًا',
        'number.greater': 'رقم المادة يجب أن يكون أكبر من 0',
        'any.required': 'رقم المادة مطلوب',
    })
});

const EditCourseSchema = Joi.object({
    _id: Joi.string().trim().required(),
    courseName: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'أسم المادة (عربي) مطلوب',
            'string.empty': 'أسم المادة (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'أسم المادة (إنجليزي) مطلوب',
            'string.empty': 'أسم المادة (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    courseInstructor: {
        ar: Joi.string().trim().required().messages({
            'any.required': 'أسم المحاضر (عربي) مطلوب',
            'string.empty': 'أسم المحاضر (عربي) لا يمكن أن يكون فارغًا',
        }),
        en: Joi.string().trim().required().messages({
            'any.required': 'أسم المحاضر (إنجليزي) مطلوب',
            'string.empty': 'أسم المحاضر (إنجليزي) لا يمكن أن يكون فارغًا',
        })
    },
    courseCode: Joi.string().trim().lowercase().required().messages({
        'any.required': 'الرقم المرجعي مطلوب',
        'string.empty': 'الرقم المرجعي لا يمكن أن يكون فارغًا',
    }),
    courseNumber: Joi.number().greater(0).required().messages({
        'number.base': 'رقم المادة يجب أن يكون رقمًا',
        'number.greater': 'رقم المادة يجب أن يكون أكبر من 0',
        'any.required': 'رقم المادة مطلوب',
    })
});

module.exports = { AddCourseSchema, EditCourseSchema }