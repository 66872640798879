import { createSlice } from "@reduxjs/toolkit";
import { adminTableThunk } from "../thunks/adminTableThunk";


const adminTableSlice = createSlice({
    name: "adminTable",
    initialState: {
        data: [],
        search:{
            AI_Id: '',
            email: '',
            block: 'all'
        },
        pagination: {
            items: 12,
            page: 0,
            sort: -1,
            lastPage: 0
        },
        isLoading: false
    },
    reducers:{
        changeAdminTableSearch(state, action){
            console.log('changeAdminTableSearch');
            state.search = action.payload.search
            state.pagination.page = action.payload.pagination.page
            state.pagination.lastPage = action.payload.pagination.lastPage
        },
        changeAdminTableItems(state, action){
            console.log('changeAdminTableItems');
            state.pagination.items = action.payload
        },
        changeAdminTablePage(state, action){
            console.log('changeAdminTablePage');
            state.pagination.page = action.payload
        },
        changeAdminTableSort(state, action){
            console.log('changeAdminTableSort');
            state.pagination.sort = action.payload
        },
        changeAdminTablePagination(state, action){
            console.log('changeAdminTablePagination');
            state.pagination = { ...state.pagination, ...action.payload }
        }
    },
    extraReducers(builder){
        builder.addCase(adminTableThunk.fulfilled, (state, action) => {
            console.log(action.payload, 'adminTableThunk.fulfilled');

            if(action.payload?.results){
                if(action.payload.results.length === 0){
                    state.data = []
                    state.pagination.lastPage = 0
                }else{
                    state.data = action.payload.results
                    state.pagination.lastPage = action.payload.pagination?.lastPage ? action.payload.pagination.lastPage : 0
                }
                state.isLoading = false
            }
            
        })

        builder.addCase(adminTableThunk.pending, (state, action) => {
            state.isLoading = true
            console.log('adminTableThunk.pending');
        })

        builder.addCase(adminTableThunk.rejected, (state, action) => {
            if(action.payload){
                state.isLoading = false
                console.log(action.payload, 'adminTableThunk.rejected');
            }
        })
    }
})

export const { changeAdminTableSearch, changeAdminTableItems, changeAdminTablePage, changeAdminTableSort, changeAdminTablePagination } = adminTableSlice.actions
export const adminTableReducer = adminTableSlice.reducer