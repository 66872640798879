import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux';
import { PrivateApi, PublicApi } from '../api/api';

export const useThunk = (thunk, data) => {

    const dispatch = useDispatch();
    // const cc = useCallback(() => PrivateApi(), [])
    // const api = cc()
    const api = PrivateApi(PublicApi)

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const runThunk = useCallback(() => {
        
        setIsLoading(true)
        dispatch(thunk({ api, data }))
        .unwrap()
        .catch(err => {
            console.log(err, 'useThunk');
            
            if(err.error){
                const { status, message } = err.error
                const invalidStatus = [403, 500, 401];
                const invalidMessages = ['jwt expired', 'Forbidden', 'reLogin', 'unAuthorized'];

                if (!invalidStatus.includes(status) || !invalidMessages.includes(message)) setError(err.error)
            }
            
        })
        .finally(() => setIsLoading(false))
    }, [dispatch, thunk, data, api])
    

  return [runThunk, isLoading, error]
}


