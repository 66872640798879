import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { StudentAccountsIndex } from '../../components/Dashboard/Student/Accounts'

export const StudentAccountsPage = () => {
  const title = usePageTitle(" حسابات (الطلاب) ")

  return (
    <>
      { title }
      <div>
        <StudentAccountsIndex /> 
      </div>
    </>
  )
}
