import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { MenuItem, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { PrivateApi, PublicApi } from '../../../api/api';
import { ChangeMyPasswordSchema } from '../../../validation/AdminAuth.validation';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { changeAlert } from '../../../store';
import { AppModal } from '../../AppModal';


export const ChangeAdminPasswordMenuItem = ({ handleCloseProfileMenu }) => {

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);

  const [submitDataLoading, setsubmitDataLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const [currentPasswordErr, setCurrentPasswordErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [rePasswordErr, setRePasswordErr] = useState('');



  const handleOpenModal = async () => {
    handleCloseProfileMenu()
    setOpenModal(true);
  }

  const handleCloseModal = () => {
      setOpenModal(false)
      setsubmitDataLoading(false)
      setCurrentPassword('')
      setPassword('')
      setRePassword('')
      setCurrentPasswordErr('')
      setPasswordErr('')
      setRePasswordErr('')
  };

  const showErrMsg = message => {
    if(message.includes("كلمة المرور الحالية")){
      setCurrentPasswordErr(message)
    } else if(message.includes("تأكيد كلمة المرور")){
      setRePasswordErr(message)
    } else if(message.includes("كلمة المرور")){
      setPasswordErr(message)
    } 
  }

  const EditPasswordRequest = async () => {
      try {
        setsubmitDataLoading(true)

        const response = await api.patch('/admin/accounts/password/me', { current_password: currentPassword, password, re_password: rePassword })
        console.log(response, 'patch data');

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تم التعديل بنجاح' }))

        handleCloseModal()
        
        Cookies.remove('payload')
        
        navigate('/login', { replace: true })
        setTimeout(() => navigate('/login', { replace: true }), 4000);

      } catch (error) {
        console.log(error.response.data.error, 'patch error');

        setsubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }

        
      }
  }


  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          await ChangeMyPasswordSchema.validateAsync({ current_password: currentPassword, password, re_password: rePassword })
          
          EditPasswordRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  const resetErrMsgs = () => {
    setCurrentPasswordErr('')
    setPasswordErr('')
    setRePasswordErr('')
  }

  return (
    <>
      
      <MenuItem onClick={handleOpenModal}>
          <LockOpenIcon fontSize="small" color="primary" />
          <Typography variant="subtitle2" component="div" mr={1} >تعديل كلمة المرور</Typography>
      </MenuItem>

      <AppModal title="تعديل كلمة المرور" open={openModal} onClose={handleCloseModal}>
          
        <Box
          onSubmit={handleSubmit}
          component="form"
          noValidate
          sx={{ my: 2 }}
          style={{ maxWidth: '300px' }}
          
        >
          <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                
              <Typography variant='subtitle2' component="span" style={{textAlign: 'right', width: '100%', marginBottom: '-10px'}}>
                  <p>ملاحظة:</p>
                  <p> كلمة المرور يجب أن تحتوي على الأقل 8 أحرف, و على حرف كبير، وحرف صغير، ورقم، وحرف خاص</p>
              </Typography>

              <TextField
                  fullWidth
                  error={currentPasswordErr.length > 0 && true }
                  id="outlined-error-current-password"
                  label="كلمة المرور الحالية"
                  helperText={currentPasswordErr}
                  value={currentPassword}
                  type="password"
                  onChange={(e) => {
                    setCurrentPassword(e.target.value)
                    resetErrMsgs()
                  }}
              />

              <TextField
                  fullWidth
                  error={passwordErr.length > 0 && true }
                  id="outlined-error-password"
                  label="كلمة المرور"
                  helperText={passwordErr}
                  value={password}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value)
                    resetErrMsgs()
                  }}
              />
              
              <TextField
                  fullWidth
                  error={rePasswordErr.length > 0 && true }
                  id="outlined-error-re-password"
                  label="تأكيد كلمة المرور"
                  helperText={rePasswordErr}
                  value={rePassword}
                  type="password"
                  onChange={(e) => {
                    setRePassword(e.target.value)
                    resetErrMsgs()
                  }}
              />

              <LoadingButton
                  fullWidth
                  className='loadingBtn'
                  startIcon={<EditIcon className='btnLogo' />}
                  loading={submitDataLoading}
                  loadingPosition="start"
                  variant="contained"
                  color="secondary"
                  type="submit"
              >
                  <span>تعديل</span>
              </LoadingButton>

          </Stack>
        </Box>

      </AppModal>
    </>
  );
}