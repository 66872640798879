import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Button, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';

export const DeleteModal = ({ title, open, handleOpen, handleClose, handleDeleteBtn, submitDataLoading }) => {

    const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

  return (
    <div>

      { title && title === 'حذف الكل' ? 

        <Button
          onClick={handleOpen}
          color="error"
          variant="contained" 
          size="small" 
          style={{ height: '38px', width: '100px', marginBottom: '10px', marginRight: '10px'}} 
          startIcon={<DeleteIcon className='btnLogo' />}
          className='loadingBtn'
        >
          حذف الكل
        </Button>
     :
        <Tooltip title={"حذف"}>
          <Avatar onClick={handleOpen} variant="rounded" sx={{ width: 28, height: 28, cursor: 'pointer', bgcolor: theme.palette.error.main}}>
              <DeleteIcon fontSize='small'/>
          </Avatar>
        </Tooltip>
      }
      
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle style={{ fontSize: '1.1rem' }}>
          عملية الحذف
        </DialogTitle>
        <DialogContent style={{ borderBottom: '1px solid #bdbdbd', borderTop: '1px solid #bdbdbd', paddingTop: '20px'}}>
          <DialogContentText>
            { title && title === 'حذف الكل' ? ' هل تريد حذف العناصر ؟' : ' هل تريد حذف العنصر ؟' }
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-around', padding: '16px 20px'}}>
            <LoadingButton
                className='loadingSmallBtn'
                startIcon={<DeleteIcon className='btnLogo' />}
                loading={submitDataLoading}
                loadingPosition="start"
                variant="contained"
                color="error"
                autoFocus
                size="small"
                onClick={handleDeleteBtn}
                style={{ marginLeft: '10px' }}
            >
                <span>حذف</span>
            </LoadingButton>
            <LoadingButton
                className='loadingBtn'
                startIcon={<ClearIcon className='btnLogo' />}
                loadingPosition="start"
                variant="contained"
                size="small"
                onClick={handleClose}
            >
                <span>إلغاء</span>
            </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}