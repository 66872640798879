import { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { PrivateApi, PublicApi } from '../../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { courseTableThunk, changeAlert } from '../../../../store';
import { AppModal } from '../../../AppModal';
import { AddDropZoneCsvFile } from '../../../DropZone';
import { ErrParagragh } from '../../../ErrParagragh';
import { CustomInputLabel } from '../../../CustomInputLabel';
import LinearProgress from '@mui/material/LinearProgress';

export const AddCourseByCsvFileTableModalBtn = () => {

  const dispatch = useDispatch()

  const { search: { AI_Id, search: sliceSearch }, pagination: {items: rowsPerPage, page, sort } } = useSelector( state => state.courseTable )

  const api = PrivateApi(PublicApi)

  const [openModal, setOpenModal] = useState(false);
  const [submitDataLoading, setSubmitDataLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesErr, setFilesErr] = useState('');
  const [progress, setProgress] = useState(0);

  const handleCloseModal = () => {
    setOpenModal(false)
    setSubmitDataLoading(false)
    setFilesErr('')
    setFiles([])
    setProgress(0)
  };

  const handleOpenModal = () => {
    setOpenModal(true) 
  }
  
  const showErrMsg = message => {
    if(message.includes('ملف (csv)')){
      setFilesErr(message)
    }
  }

  const resetErrMsgs = () => {
    setFilesErr('')
  }

  const onUploadChange = acceptedFiles => {
    console.log(acceptedFiles, 'acceptedFiles');
    setFiles(acceptedFiles)
    resetErrMsgs()
  }

  const AddDataRequest = async () => {
      try {
        setSubmitDataLoading(true)
       
        const file = files[0]
        const formData = new FormData();

        formData.append('csvFile', file)
        
        console.log(formData, 'formData');

        const response = await api.post(`admin/course/uploadCsvFile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentage);
          }
        })
        console.log(response, 'response');

        const data = { AI_Id, search: sliceSearch, page: page + 1, sort, items: rowsPerPage }
        
        dispatch(courseTableThunk({ api, data })) // refetch all data according to pagination records

        dispatch(changeAlert({ open: true, type: 'success', msg: 'تمت الإضافة بنجاح' }))

        handleCloseModal()

      } catch (error) {
        console.log(error.response.data.error, 'patch error');
        setSubmitDataLoading(false)

        if(error?.response){
          const { status, message } = error.response.data.error
          if(status === 422){
            showErrMsg(message)
          } else if(status === 401){
            handleCloseModal()
          }
        }
      }
  }

  const handleSubmit = async e => {
      e.preventDefault();
      
      try {
          if (files.length === 0) throw new Error('ملف (csv) مطلوب')

          AddDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  const style = {marginBottom: '10px'}

  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant="contained" 
        size="small" 
        style={{ height: '38px', width: '170px', marginRight: '10px', marginBottom: '10px'}} 
        startIcon={<AddIcon className='btnLogo' />}
        className='loadingBtn'
      >
        أضف بواسطة ملف csv
      </Button>

      <AppModal title="أضف بواسطة ملف csv" open={openModal} onClose={handleCloseModal} >

          <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            sx={{ my: 2 }}
            style={{ maxWidth: '300px' }}
          >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>

                <Typography variant='subtitle2' component="div" style={{textAlign: 'right', width: '100%'}}>
                  {/* <p style={{ border: '3px dashed rgba(0, 0, 0, 0.12)', borderRadius: '4px', textAlign: 'center', padding: '10px' }}>قم بتنزيل هذا <a href="/file.csv" download="file.csv" title={'file.csv'} target="_blank" rel="noreferrer"><strong>الملف</strong></a> ثم قم بالتعديل عليه و أرفعه مرة أخري.</p> */}
                  <p style={{ border: '3px dashed rgba(0, 0, 0, 0.12)', borderRadius: '4px', textAlign: 'center', padding: '10px' }}> قم بالدخول علي هذا <a href="https://docs.google.com/spreadsheets/d/1UlnDEuDJcz7apQbd91myD5F-QWJPQDq-6nMemmV7c34/edit?usp=sharing" title={'file.csv'} target="_blank" rel="noreferrer"><strong>الرابط</strong></a> وقم بتعديل البيانات ثم قم بتحميل الملف بصيغة (csv.), ثم قم بإعادة رفعه مرة أخري.</p>
                  <br />
                  <div style={{ border: '3px dashed rgba(0, 0, 0, 0.12)', borderRadius: '4px', textAlign: 'right', padding: '10px 8px' }}>
                    <p style={style}>- يتكون هذا الملف من عدة حقول و يجب الألتزام بها لضمان عملية إضافة صحيحة:</p>
                    <ul style={{ marginRight:'20px' }}>
                      <li style={style}>courseNumber: رقم المادة يجب أن يكون رقم صحيح,وأن لا يتكرر مع مادة أخري.</li>
                      <li style={style}>courseCode: الرقم المرجعي يمكن ان يتكون من أرقام وحروف بشرط ان لا يتكرر مع مادة أخري.</li>
                      <li style={style}>courseNameArabic: أسم المادة (عربي) و يسمح بالتكرار عادي.</li>
                      <li style={style}>courseNameEnglish: أسم المادة (إنجليزي) و يسمح بالتكرار عادي.</li>
                      <li style={style}>courseInstructorArabic: أسم المحاضر (عربي) و يسمح بالتكرار عادي.</li>
                      <li style={style}>courseInstructorEnglish: أسم المحاضر (إنجليزي) و يسمح بالتكرار عادي.</li>
                    </ul>
                    <br />
                    <p><strong>ملاحظة</strong>: في حالة إدخال أكواد أو أرقام مكررة, و في حالة إدخال بيانات عربي مكان الأنجليزي و العكس, سيتم تجاهل هذه البيانات و سيتم إضافة باقي البيانات الصحيحة.</p>
                  </div>
                </Typography>

                <CustomInputLabel label={'ملف (csv):'} />
                
                <AddDropZoneCsvFile sizeInMB={15} fileslimit={1} onUploadChange={onUploadChange}/>
                <ErrParagragh errMsg={filesErr} />

                <Box sx={{ width: '100%' }}>
                  {progress > 0 && <LinearProgress variant="determinate" value={progress} />}
                </Box>

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<AddIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>أضف</span>
                </LoadingButton>

            </Stack>
          </Box>

      </AppModal>
      
    </>
  );
}
