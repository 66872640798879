import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import { Toolbar, IconButton, Stack } from '@mui/material';
import { ProfileList } from './ProfileList';
import { NotificationList } from './NotificationList';

const drawerWidth = 240;

const AppTopBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

export const AppBar = ({ openSideMenu, handleDrawerOpen }) => {
  return (
    <AppTopBar position="fixed" open={openSideMenu}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', direction: 'row'}}>

            <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerOpen}>
                <MenuIcon />
            </IconButton>

            <Stack component="div" direction="row" alignItems="center">
                {/* <NotificationList /> */}
                <ProfileList />
            </Stack>
          
        </Toolbar>
      </AppTopBar>
  )
}
