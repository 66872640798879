const Joi = require('joi');

const StudentEditSchema = Joi.object({
    _id: Joi.string().trim().required(),
    name: Joi.string().trim().required().messages({
        'any.required': 'الأسم مطلوب',
        'string.empty': 'الأسم لا يمكن أن يكون فارغًا',
    }),
    mobCode: Joi.string().trim().required().messages({
        'any.required': 'كود الجوال مطلوب',
        'string.empty': 'كود الجوال لا يمكن أن يكون فارغًا',
    }),
    mobile: Joi.string().trim().required().pattern(/^5\d{8}$/).messages({
      'string.base': 'يجب أن يكون رقم الجوال أرقام فقط',
      'string.empty': 'رقم الجوال مطلوب',
      'string.pattern.base': 'رقم الجوال يجب أن يبدأ بـ "5" ويحتوي على 9 أرقام',
    }),
    facultyCategory: Joi.string().trim().required().messages({
        'any.required': 'القسم مطلوب',
        'string.empty': 'القسم لا يمكن أن يكون فارغًا',
    }),
    email: Joi.string().trim().email({ tlds: { allow: false } }).lowercase().required().messages({
        'any.required': 'البريد الإلكتروني مطلوب',
        'string.empty': 'البريد الإلكتروني لا يمكن أن يكون فارغًا',
        'string.email': 'البريد الإلكتروني يجب أن يكون صالحًا',
    }),
    academic_number: Joi.number().greater(0).required().messages({
        'number.base': 'الرقم الأكاديمي يجب أن يكون رقمًا',
        'number.greater': 'الرقم الأكاديمي يجب أن يكون أكبر من 0',
        'any.required': 'الرقم الأكاديمي مطلوب',
    }),
    password: Joi.string().trim().min(8)
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~])[a-zA-Z0-9-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~]+$')).allow('')
    .messages({
        'string.pattern.base': 'كلمة المرور يجب أن تحتوي علي الأقل على حرف كبير، وحرف صغير، ورقم، وحرف خاص',
        'string.min': 'كلمة المرور يجب أن تحتوي على الأقل 8 أحرف',
        'any.required': 'كلمة المرور مطلوبة',
        'string.empty': 'كلمة المرور لا يمكن أن يكون فارغة'
    }),
    re_password: Joi.string().trim().valid(Joi.ref('password')).messages({
        'any.only': 'تأكيد كلمة المرور يجب أن تكون متطابقة'
    }),
    block: Joi.boolean().required().messages({
        'any.required': 'حالة الحظر مطلوبة',
    })
});

module.exports = { StudentEditSchema }