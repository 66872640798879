import { Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export const SideBarMenuItem = ({ text, icon }) => {
  return (
    <ListItem disablePadding>
        <ListItemButton>
            <ListItemIcon sx={{minWidth: '40px'}} >
                { icon }
            </ListItemIcon>
            <ListItemText primary={<Typography variant="subtitle2" component="div">{text}</Typography>} sx={{ textAlign: 'right' }} />
        </ListItemButton>
    </ListItem>
  )
}

