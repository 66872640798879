import { createAsyncThunk } from "@reduxjs/toolkit";

export const categoryTableThunk = createAsyncThunk('categoryTable/getData', async ({ api, data }, { rejectWithValue }) => {

    try {
        const res = await api.get('/admin/category', { params: data })
        console.log(res, 'categoryTableThunk succ');
        return res?.data

    } catch (error) {
        console.log(error, 'categoryTableThunk err');
        return rejectWithValue(error.response.data)
    }

})