import { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { AppSelect } from '../../AppSelect'
import { useDispatch } from 'react-redux';
import { changeAdminTableSearch } from '../../../store';
import { AddAdminTableModalBtn } from './AddAdminTableModalBtn';

export const AdminSearchBar = ({ roles }) => {

  const dispatch = useDispatch()

  const initSearchData = { AI_Id: '', email: '', block: 'all'}

  const initPaginationData = { page: 0, lastPage: 0 }


  const [search, setSearch] = useState('all');
  const searchItems = [
    { value: 'all', key: "الكل" },
    { value: 'AI_Id', key: "الرقم" },
    { value: 'email', key: "البريد الإلكتروني" }
  ]

  const [searchBlock, setSearchBlock] = useState('all');
  const searchBlockItems = [
    { value: 'all', key: "الكل" },
    { value: 'blocked', key: "محظور" },
    { value: 'notBlocked', key: "غير محظور" }
  ]
    
  const [inputSearch, setInputSearch] = useState('');

  const onSearchListChange = (e) => {
    setSearch(e.target.value)
    if(e.target.value === 'all') dispatch(changeAdminTableSearch({ search: initSearchData, pagination: initPaginationData }))
    setInputSearch('')
  }
  
  const onSearchBlockListChange = (e) => {
    setSearchBlock(e.target.value)
    dispatch(changeAdminTableSearch({ search: { ...initSearchData, block: e.target.value }, pagination: initPaginationData }))
    // reset searchInput & searchList
    setInputSearch('')
    setSearch('all')
  }

  const handleSubmit = e => {
    e.preventDefault()
    console.log('clicked');

    if(inputSearch && search !== 'all'){
      let value = '';

      if(search === 'AI_Id') {
        value = isNaN(parseInt(inputSearch)) ? 0 : parseInt(inputSearch)
      } else {
        value = inputSearch
      }
      
      const data = { ...initSearchData, [search]: value }
      console.log(data, 'data');
      dispatch(changeAdminTableSearch({ search: data, pagination: initPaginationData }))

      //reset other select lists
      setSearchBlock('all')
    }
  }
  

  return (
    <Stack direction={'row'} justifyContent={'flex-start'} justifyItems={'center'} className='overflowXScroll'>

      <Box component="form" onSubmit={handleSubmit} noValidate className='appSearchBox outlineForm'>

        <AppSelect value={search} onChange={onSearchListChange} items={searchItems} minWidth={130} label={'بحث بـ'} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <InputBase sx={{ ml: 1, flex: 1, p: '10px 0' }} fullWidth onChange={(e) => setInputSearch(e.target.value)} value={inputSearch} type="text" placeholder="بحث..." startAdornment={<InputAdornment position="start" sx={{ ml: 0.5 }}><SearchIcon /></InputAdornment>} />

        <Divider sx={{ height: 40, m: 0.5 }} orientation="vertical" />

        <AppSelect value={searchBlock} onChange={onSearchBlockListChange} items={searchBlockItems} minWidth={130} label={'بحث بالحظر'} />
        <Button sx={{display: 'none'}} type="submit">submit</Button>
      </Box>

      <Stack justifyContent={'center'} justifyItems={'center'} mx={2}>
        { roles.includes('Admin_add') ? <AddAdminTableModalBtn /> : '' }
      </Stack>

    </Stack>
  );
}