import { FacultyCategoryTable } from './FacultyCategoryTable'
import { FacultyCategorySearchBar } from './FacultyCategorySearchBar'
import { ServerErrComponent } from '../../ServerErrComponent';
import { useSelector } from 'react-redux';


export const FacultyCategoryIndex = () => {

  const { roles } = useSelector(state => state.payload)

  return roles.includes('FacultyCategory_show') ?
    <>
      <FacultyCategorySearchBar roles={roles} />
      <FacultyCategoryTable roles={roles} />
    </> :
  <ServerErrComponent title={'هذا الحساب لايملك صلاحية لمشاهدة هذه الصفحة'} src={'/401.svg'} HomeBtn={'hide'} />
}
